import React, { useState } from 'react';
import { Button, Navbar, Offcanvas, OffcanvasBody } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SidebarMenu } from '../SidebarLeft';
import FaIcon from '../../Global/FaIcon';

const TBBrand = ({ className }) => {
    const [show, setShow] = useState(false);
    const offCanvasHandle = () => {
        setShow(!show);
    }
    
    return (
        <>
        <Navbar.Brand as={Link} to="/" className={className}>
            <img
                src="https://static.446.hu/images/favicon.png"
                width={35}
                height={35}
                alt="Brand"
            />
        </Navbar.Brand>
        <SidebarOffCanvasButton className="d-block d-md-none" callback={offCanvasHandle} style={{marginRight:'15px'}}/>
        <SidebarOffcanvas show={show} handleClose={offCanvasHandle} />
        </>
    );
};


const SidebarOffCanvasButton = ({ className, callback, style }) => {
    return ( <Button className={className} type="button" variant="dark" onClick={callback} style={style}><FaIcon type="solid" icon="bars" /></Button> )
}
const SidebarOffcanvas = ({ show, handleClose }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} bg="dark" data-bs-theme="dark">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <img src="https://static.446.hu/images/446logo.png" width={200} alt="Brand" />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <SidebarMenu handleClose={handleClose}/>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default TBBrand;