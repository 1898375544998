import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../Contexts/UserContext';

const Logout = () => {
    const { user, logout } = useUsers();
    const navigate = useNavigate();
    
    useEffect(() => {
        logout(user.uid);
        navigate("/");
    },[]);

    return (
        <div>
            
        </div>
    );
};

export default Logout;