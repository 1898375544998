import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TopBar from '../../Widgets/Dashboard/TopBar';
import SidebarLeft from '../../Widgets/Dashboard/SidebarLeft';
import { useLocation } from 'react-router-dom';
import SidebarRight from '../../Widgets/Dashboard/SidebarRight';
import Routing from './__Routing';
import { useUsers } from '../../Contexts/UserContext';
import ChatApp from '../../Widgets/Dashboard/Chat/ChatApp';
import { ChatProvider } from '../../Contexts/ChatContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DashboardFrame = () => {
    const { saveActivity } = useUsers();
    const location = useLocation();
    const [template, setTemplate] = useState("ThreeColumn");

    useEffect(() => {
        const path = location.pathname;
        saveActivity();
        switch(true){
            case path === "/map":
            case path === "/messenger":
            case path === "/myprofile":
            case path === "/myprofile/map":
            case /^\/profile\/[^/]+$/.test(location.pathname):
            case /^\/profile\/[^/]+\/map$/.test(location.pathname):
                setTemplate("FullWidth"); break;
            
            case path === "/qso/add":
            case path === "/qso/list":
            case path === "/qso/overview":
            case path === "/freqs/gumiful":
            /*case path === "/calculators/yagi-calculator":*/
                setTemplate("TwoColumn"); break;

            default: setTemplate("ThreeColumn"); break;
        }      
    }, [location]);

    return (
        <>
            <ChatProvider>
                <TopBar />
                <Container fluid data-pathname={location.pathname}>
                    {templates[template]()}
                </Container>
                <ChatApp />
                <ToastContainer />
            </ChatProvider>
        </>
    );
};

const templates = {
    ThreeColumn: () => { return <ThreeColumn /> },
    TwoColumn: () => { return <TwoColumn /> },
    FullWidth: () => { return <FullWidth /> }
};

/*Templates*/
const ThreeColumn = () => {
    return (
        <Row>
            <Col lg={2} md={3} className="d-none d-md-block">
                <SidebarLeft />
            </Col>
            <Col lg={{span: 6, offset: 1}} md={6} sm={9} xs={12}>
                <Routing />
            </Col>
            <Col lg={{span: 3, offset: 0}} md={3} sm={3} className="d-none d-sm-block">
                <SidebarRight />
            </Col>
        </Row>
    )
}
const TwoColumn = () => {
    return (
        <Row>
            <Col md={3} className="d-none d-md-block">
                <SidebarLeft />
            </Col>
            <Col md={9} sm={9} xs={12}>
                <Routing />
            </Col>
        </Row>
    )
}
const FullWidth = () => {
    return (
        <Routing />
    )
}

export default DashboardFrame;        