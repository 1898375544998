import React, { useEffect, useRef, useState } from 'react';
import { useUsers } from '../../../Contexts/UserContext';
import { getUserByID } from '../../../Utils/FirebaseFuncs';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import TBUserPicture from '../TopBar/TBUserPicture';
import { __getTimeStamp, getDiff, getDiffInMinutes, getOnlineDiff } from '../../../Utils/TimeFuncs';
import { OnlineMarker } from '../Sidebar/OnlineUsers';
import FaIcon from '../../Global/FaIcon';
import { addDoc, collection, doc, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { Link } from 'react-router-dom';

const MessengerMainFrame = ({ chat }) => {
    const endOfMessagesRef = useRef();
    const { user } = useUsers();
    const [sender, setSender] = useState([]);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchSender = async () => { const senderID = chat.room.persons.filter(person => person !== user.uid)[0]; const _sender = await getUserByID(senderID); setSender(_sender); };
        fetchSender();
    }, [])
    useEffect(() => {
        if (!chat) return;
        const messagesRef = collection(db, 'chatRooms', chat.room.id, 'messages');
        const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"));
        const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
            const newMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setMessages(newMessages);
        });
        return () => unsubscribe();
    }, [chat]);
    useEffect(() => {if (endOfMessagesRef.current) {endOfMessagesRef.current.scrollIntoView({behavior: "instant", block: "end"});}}, [messages]);

    const sendMessage = async () => {
        const ts = __getTimeStamp();
        const msg = { sender: user.uid, message: message, timestamp: ts, unReaded: true}; 
        const msgRef = collection(db, "chatRooms", chat.room.id, "messages"); 
        await addDoc(msgRef, msg); 
        await updateDoc(doc(db,  "chatRooms", chat.room.id), { lastMessage: ts});
        setMessage('');
    }

    return (
        <div className="chatFrame">
            <Row className="chatTop">
                <Col md={1} xs={2} style={{ position: 'relative' }}>
                    <TBUserPicture user={sender} w={48} h={48} />
                    {(getDiffInMinutes(sender.lastAction) <= 5) && (<OnlineMarker state="online" />)}
                    {(getDiffInMinutes(sender.lastAction) > 6) && (getDiffInMinutes(sender.lastAction) <= 15) && (<OnlineMarker state="leaved" />)}
                    {(getDiffInMinutes(sender.lastAction) > 16) && (<OnlineMarker state="offline" />)}
                </Col>
                <Col md={8} xs={8} style={{ position: 'relative' }}>
                    <b>{sender.username}</b><br />
                    {getOnlineDiff(sender.lastAction)}
                </Col>
                <Col md={3} xs={2} className="text-right">
                    <Button variant="dark" as={Link} to={`/profile/${sender.uid}`}><FaIcon type="light" icon="info-circle" style={{ fontSize: '22px', position: 'relative', top: '6px' }} /></Button>
                </Col>
            </Row>
            <Row className="chat-messages">
                <Col md={12}>
                    {messages.sort((a, b) => (a.timestamp - b.timestamp)).map((message, index) => (<MessengerMessageItem room={chat.room.id} message={message} key={index} />))}
                    <div style={{ float: 'left', clear: 'both' }} ref={(el) => { endOfMessagesRef.current = el; }} />
                </Col>
            </Row>
            <Row className="chat-form">
                <Col md={12}>
                    <InputGroup>
                        <Form.Control type="text" className="dark" value={message} onChange={(e) => { setMessage(e.target.value); }} />
                        <Button variant='dark' onClick={sendMessage} ><FaIcon type="light" icon="paper-plane-top" /></Button>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};



const MessengerMessageItem = ({ room, message }) => {
    const { user } = useUsers();
    const [received, setReceived] = useState(false);
    const [readMark, setReadMark] = useState("");

    useEffect(() => {
        const setReaded = async (room, messageID) => { await updateDoc(doc(db, "chatRooms", room, "messages", messageID), { unReaded: false }); }
        setReceived((message.sender === user.uid) ? false : true);
        if (message.sender !== user.uid && message.unReaded === true) { setReaded(room, message.id); };
        if (message.sender === user.uid && message.unReaded) {
            setReadMark(<FaIcon type="light" icon="eye-slash" />);
            const watchMessage = async (room, messageID) => {
                const msgsRef = collection(db, "chatRooms", room, "messages");
                const msgsQ = query(msgsRef, where("sender", "==", user.uid));
                const unsubscribe = onSnapshot(msgsQ, (snapshot) => {
                    try {
                        const changes = snapshot.docChanges();
                        if (changes.length > 0) {
                            changes.forEach((change) => {
                                if (change.type === "modified" && change.doc.data().unReaded === false) {
                                    setReadMark(<FaIcon type="light" icon="eye" />);
                                };
                            })
                        };
                    } catch (e) { };
                });
                return () => unsubscribe();
            };
            watchMessage(room, message.id);
        };
        if (message.sender === user.uid && !message.unReaded) { setReadMark(<FaIcon type="light" icon="eye" />); };
    }, []);

    return (
        <div className={`messenger-message ${(received) ? "received" : "sended"}`}>
            <span>
                {message.message}<br/>
                <span className="small" style={{position:"relative",top:"0px"}}>
                    {getDiff(message.timestamp)} {readMark}
                </span>    
            </span>
            
        </div>
    );
}

export default MessengerMainFrame;