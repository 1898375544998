import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB5GYqy6hxy_rbbqAe0EqMceJuVqiz5e0g",
    authDomain: "hu-92304.firebaseapp.com",
    projectId: "hu-92304",
    storageBucket: "hu-92304.firebasestorage.app",
    messagingSenderId: "446701080466",
    appId: "1:446701080466:web:e8d679ab22f3ddd329bb4b",
    measurementId: "G-8BPDF7S9FD"
};

const app = initializeApp(firebaseConfig);
const gProvider = new GoogleAuthProvider();
gProvider.setCustomParameters({ prompt: "select_account" });

const fProvider = new FacebookAuthProvider();
fProvider.addScope("user_hometown");
fProvider.addScope("user_location");
fProvider.addScope("user_birthday");
fProvider.addScope("user_link");
fProvider.addScope("user_friends");

export const auth = getAuth();
export const analytics = getAnalytics(app);
export const signInWithGoogle = () => signInWithPopup(auth, gProvider);
export const signInWithFacebook = () => signInWithPopup(auth, fProvider);
export const db = getFirestore(app);
export const firebaseErrors = {
    "auth/email-already-in-use": "Az e-mail cím már használatban van!",
    "auth/invalid-action-code": "Az aktiváló kulcs lejárt, vagy nem létezik!",
};