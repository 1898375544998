import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FaIcon from '../../../Global/FaIcon';
import ProfileFeed from '../ProfileFeed';

const MPFeed = ({ state }) => {
    return (
        <Row>
            <Col xs={12}>
                <ProfileFeed state={state} />
            </Col>
        </Row>        
    );
};

export default MPFeed;