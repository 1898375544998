import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ProfileFeed from '../ProfileFeed';
import FaIcon from '../../../Global/FaIcon';

const MPAbout = ({ state }) => {
    return (
        <Row>
            <Col md={4}>
                <Card className="fb-styled-card details">
                    <Card.Body>
                        <h4 className="fb-style-title">Bemutatkozás</h4>
                        <span className="fb-style-text" dangerouslySetInnerHTML={{ __html: state.profile.aboutme }}></span>
                        <hr />
                        <b><FaIcon type="solid" icon="walkie-talkie" /> Rádiók</b><br />
                        {state.infos.radios.map((radio, index) => (<div className="infoItem" key={`radio-${index}`}>{radio}</div>))}
                        <hr />
                        <b><FaIcon type="solid" icon="tower-cell" /> Antennák</b><br />
                        {state.infos.antennas.map((ant, index) => (<div className="infoItem" key={`antenna-${index}`}>{ant}</div>))}
                        <hr />
                        <b><FaIcon type="solid" icon="wave-sine" /> Frekvenciák</b><br />
                        {state.infos.freqs.map((freq, index) => (<div className="infoItem" key={`freq-${index}`}>{freq}</div>))}
                        <hr />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default MPAbout;