import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { __getDateFromStamp, __getTimeStampFromDate } from '../../../Utils/TimeFuncs';
import { collection, getDoc, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import FaIcon from '../../Global/FaIcon';
import { Link } from 'react-router-dom';

const NextEvent = () => {
    const [nextEvent, setNextEvent] = useState(false);
    useEffect(() => {
        const fetchNextEvent = async () => {
            const eventsRef = collection(db, "events");
            const eventsQ = query(eventsRef, orderBy("date","desc"), limit(1));
            const nextE = await getDocs(eventsQ);
            setNextEvent({ id: nextE.docs[0].id, ...nextE.docs[0].data()});
        }; fetchNextEvent();
    },[]);


    return (
        <div className="feedItem nextEventFrame">
            <Card className="bg-dark text-white">
                <Card.Img src={nextEvent.image} alt="Next Event"/>
                <Card.ImgOverlay>
                    <Card.Title class="title" style={{position:"absolute",top:"5px", left:"10px"}}>Következő esemény:</Card.Title>
                    <Card.Title>{nextEvent.title}</Card.Title>
                    <Card.Text> <FaIcon type="light" icon="calendar-alt" /> { __getDateFromStamp(nextEvent.date) } </Card.Text>
                    <Card.Text>
                        <Link to={`/events/${nextEvent.id}`} className="stretched-link"></Link>
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
        </div>
    );
};

export default NextEvent;