import { Editor } from '@tinymce/tinymce-react';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { db } from '../../../../Utils/Firebase';
import { useUsers } from '../../../../Contexts/UserContext';

const DeskradioModal = ({ show, pos, callback, method = "new", params}) => {
    const { user } = useUsers();
    const editorRef = useRef(null);
    const [state, setState] = useState({
        fields: {
            markerTitle: {
                value: '',
                error: '',
                css: 'is-invalid'
            }
        }
    });

    useEffect(() => {
        if(method === "edit"){
            setState({
                fields: {
                    markerTitle: {
                        value: params.title,
                        error: '',
                        css: 'is-valid'
                    }
                }
            });
            if(editorRef.current){ editorRef.current.setContent(params.description); }
        }
    },[method, editorRef.current]);

    const handleChange = (e) => {
        const field = e.target.name;
        let fields = state.fields;
        fields[field]['value'] = e.target.value;
        fields[field]['css'] = (e.target.value.length >= 6) ? 'is-valid' : 'is-invalid';
        setState({...state, fields});
    }

    const eventSubmit = async (e) => {
        e.preventDefault();
        if(method === "new"){
            const fields = {
                type: 'deskradio',
                lat: pos.lat,
                lon: pos.lon,
                title: state.fields.markerTitle.value,
                description: editorRef.current.getContent(),
                isActive: true,
                owner: user.uid
            };
            await addDoc(collection(db, "markers"), fields);
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            setState({ ...state, f });
            callback('deskradio');
        }else if(method === "edit"){
            const fields = {
                title: state.fields.markerTitle.value,
                description: editorRef.current.getContent()
            };
            await updateDoc(doc(db, "markers", params?.id), fields);
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            setState({ ...state, f });
            callback('deskradio');
        }
    }

    return (
        <Modal show={show}>
            <Form onSubmit={(e) => { eventSubmit(e) }}>
                <Modal.Header>
                    <Modal.Title>Új fix állomás létrehozása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control className={`${state.fields.markerTitle.css} dark`} type="text" name="markerTitle" id="markerTitle" value={state.fields.markerTitle.value} onChange={handleChange} placeholder="Marker neve" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{skin: "oxide-dark", content_css: "dark", icon: "small", menubar: false, toolbar: 'undo redo | bold italic' }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { callback('deskradio'); }} variant="dark">Mégse</Button>
                    <Button type="submit" variant="dark">Mentés</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DeskradioModal;