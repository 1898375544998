import { Editor } from '@tinymce/tinymce-react';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { db } from '../../../../Utils/Firebase';
import { __getTimeStamp } from '../../../../Utils/TimeFuncs';
import { useUsers } from '../../../../Contexts/UserContext';

const WEditProfile = ({ user }) => {
    const { fetchUser } = useUsers();
    const [state, setState] = useState({
        fields: {
            username: '',
            aboutme: '',
            radios: [],
            antennas: [],
            freqs: []
        },
        errors: {},
    });
    const editorRef = useRef();
    const usernameRef = useRef(null);

    const handleChange = (event) => {
        let fields = state.fields;
        let errors = {};
        let field = event.target.name;
        let value = event.target.value;
        fields[field] = value;
        setState({...state, fields });
        setState({...state, errors });
    }

    const initFields = async () => {
        await fetchUser(user.uid);
        let field = { ...state.fields };
        field['username'] = user?.username;
        field['aboutme'] = user?.aboutme;
        setState((prev) => ({...prev, fields: field}));
    }

    const saveProfile = async (event) => {
        event.preventDefault();
        const userRef = doc(db,"users",user.uid);
        await updateDoc(userRef, {
            username: state.fields.username,
            aboutme: editorRef.current.getContent(),
            lastAction: __getTimeStamp()
        });

    }

    useEffect(() => {
        initFields();
    },[]);
    useEffect(() => {
        if(editorRef.current && user?.aboutme) editorRef.current.setContent(user.aboutme);
    },[editorRef.current]);

    return (
        <Form onSubmit={saveProfile} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            {(state.errors?.personaMessage?.text) && (<Alert variant={state.errors?.personaMessage?.variant} onClick={() => setState({ ...state, errors: {} })}>{state.errors?.personaMessage.text}</Alert>)}
            <InputGroup className="mb-2">
                <Form.Control className={`${state.errors?.username ? "is-invalid" : ""} dark`} ref={usernameRef} type="text" placeholder="Hívójel" onChange={handleChange} value={state.fields.username} name="username" />
            </InputGroup>
            <Editor
                apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                onInit={(_evt, editor) => editorRef.current = editor}
                init={{
                    skin: "oxide-dark",
                    content_css: "dark",
                    menubar: false,
                    toolbar: 'undo redo | bold italic'
                }}>
            </Editor>
            <ButtonGroup style={{ marginTop: '7px' }} className="mb-2">
                <Button type="submit" variant="success">Mentés</Button>
            </ButtonGroup>
        </Form>
    );
};

export default WEditProfile;