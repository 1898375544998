import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Col, InputGroup, Row } from 'react-bootstrap';
import FaIcon from '../../Global/FaIcon';
import { formatDateForInput } from '../../../Utils/Formatter';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import MarkerCombined from '../Map/MarkerCombined';
import { getCoordsByMaidenhead } from '../../../Utils/LocationService';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { __getDateFromStamp, __getDateStamp, __getTimeStamp, __getTimeStampFromDate } from '../../../Utils/TimeFuncs';

const QSOMap = ({ state }) => {
    const mapRef = useRef(false);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [hasFilter, setHasFilter] = useState(false);
    const [qso, setQSO] = useState([]);

    const setDefaultFilter = () => {
        let end = new Date();
        let start = new Date();        
        setTo(prev => end.toLocaleDateString());
        start.setMonth(end.getMonth() - 1);
        setFrom(prev => start.toLocaleDateString());
    }

    const setPredefinedPresets = (selected) => {
        let args = selected.split(':');
        let end = new Date();
        let start = new Date();
        setTo(end.toLocaleDateString());
        switch (args[0]) {
            case 'day': start.setDate(end.getDate() - args[1]); break;
            case 'month': start.setMonth(end.getMonth() - args[1]); break;
            case 'custom': document.getElementById('date_from').focus(); break;
            default: break;
        };
        setFrom(start.toLocaleDateString());
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "predefined":
                setPredefinedPresets(event.target.value);
                break;
            case "from":
                setFrom(event.target.value);
                break;
            case "to":
                setTo(event.target.value);
                break;
            default: break;
        }
    }
    const filterDate = (event) => {
        event.preventDefault();
        getQSOInInterval();
    }

    const getQSOInInterval = async () => {
        if (!state.uid) return;
        
        setQSO([]);
        const _from = __getDateStamp(from);
        const _to = (__getDateStamp(to) + (24 * 60* 60));        
        /*I sended*/
        const myQsoRef = collection(db, "qsos");
        const myQsoQ = query(myQsoRef, where("uid", "==", state.uid));
        const myQsos = await getDocs(myQsoQ);
        let rows = [];
        for (const myq of myQsos.docs) {            
            const _id = myq.id;
            const recordsRef = collection(db, "qsos", _id, "list");
            const recordsQ = query(recordsRef, where("date",">=", _from), where("date","<=",_to));
            const recordsDocs = await getDocs(recordsQ);
            for (const item of recordsDocs.docs) {
                const _row = {
                    id: item.id,
                    qid: myq.id,
                    type: "sended",
                    sender: {
                        callsign: myq.data().callsign,
                        qth: myq.data().qth,
                        gps: getCoordsByMaidenhead(myq.data().qth)
                    },
                    target: {
                        callsign: item.data().callsign,
                        qth: item.data().callsign,
                        gps: getCoordsByMaidenhead(item.data().qth)
                    }
                }
                rows.push(_row);
            };
        };
        /*IAccepted*/
        const qsos = await getDocs(collection(db,"qsos"));
        for(const myq of qsos.docs){
            const itemRef = collection(db,"qsos", myq.id, "list");
            const itemQ = query(itemRef, where("callsign","==", state.displayName), where("validated","==",true), where("handled","==",true), where("date",">=", _from), where("date","<=",_to));
            const items = await getDocs(itemQ);
            if(items.docs.length > 0){
                for (const item of items.docs) {
                    const _row = {
                        id: item.id,
                        qid: myq.id,
                        type: "received",
                        sender: {
                            callsign: myq.data().callsign,
                            qth: myq.data().qth,
                            gps: getCoordsByMaidenhead(myq.data().qth)
                        },
                        target: {
                            callsign: item.data().callsign,
                            qth: item.data().callsign,
                            gps: getCoordsByMaidenhead(item.data().qth)
                        }
                    };
                    rows.push(_row);
                };
            };
        }

        setQSO(rows);
    }

    useEffect(() => {
        setDefaultFilter(); 
        getQSOInInterval(); 
    },[]);

    return (
        <div className="feedItem mb-3">
            <Row>
                <Col md={12}>
                    <Form onSubmit={filterDate}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1" className="dark"><FaIcon type="solid" icon="search" /></InputGroup.Text>
                            <Form.Control className="dark" type="date" id="date_from" value={formatDateForInput(from)} max={formatDateForInput(new Date())} onChange={handleChange} placeholder="Mettől" name="from" />
                            <Form.Control className="dark" type="date" id="date_to" value={formatDateForInput(new Date(to).toLocaleDateString())} max={formatDateForInput(new Date())} onChange={handleChange} placeholder="Meddig" name="to" />
                            <Form.Select className="dark" name="predefined" onChange={handleChange}>
                                <option value='day:0'>Ma</option>
                                <option value='day:1'>Tegnap</option>
                                <option value='day:2'>2 nap</option>
                                <option value='day:5'>5 nap</option>
                                <option value='day:7'>1 hét</option>
                                <option value='day:14'>2 hét</option>
                                <option selected value='month:1'>1 hónap</option>
                                <option value='month:3'>3 hónap</option>
                                <option value='month:6'>6 hónap</option>
                                <option value='month:12'>12 hónap</option>
                                <option value='custom:0'>Egyedi</option>
                            </Form.Select>
                            <Button type="submit" variant="dark"><FaIcon type="solid" icon="search" /></Button>
                            {(hasFilter && (<Button type="submit" variant="dark"><FaIcon type="solid" icon="times" /></Button>))}
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
            <Row >
                <Col md={12} style={{ height: "600px" }} className="mb-3">
                    <MapContainer
                        ref={mapRef}
                        center={[47.1628, 19.5036]}
                        zoom={7}
                        scrollWheelZoom={true}
                        dragging={true}
                        doubleClickZoom={true}
                        zoomControl={true}
                        style={{
                            height: "clamp(600px, 50%, 725px)"
                        }}
                    >
                        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <QSOOverview items={qso} />
                    </MapContainer>
                </Col>
            </Row>
        </div>
    );
};
export default QSOMap;

const QSOOverview = ({ items }) => {
    const [senderCoords, setSenderCoords] = useState([]);
    const [pathColor, setPathColor] = useState("red");
    
    useEffect(() => {
        setSenderCoords([]);
        const existingCoords = new Set(senderCoords.map((coord) => `${coord.lat},${coord.lon}`));
        const uniqueCoords = new Set();
        const newCoords = items.map((item) => ({ lat: item.sender.gps.lat, lon: item.sender.gps.lon })).filter((coord) => { const key = `${coord.lat},${coord.lon}`; if (existingCoords.has(key) || uniqueCoords.has(key)) { return false; }; uniqueCoords.add(key); return true; });
        if (newCoords.length > 0) { setSenderCoords((prev) => [...prev, ...newCoords]) };
    }, [items]);

    return (
        <>
            {(items.map((item, index) => (
                <>
                    <Polyline
                        pathOptions={{ color: (item.type === "sended") ? "red" : "green" }}
                        positions={[
                            [item.sender.gps.lat, item.sender.gps.lon],
                            ...(item.middle ? [[item.middle.lat, item.middle.lon]] : []),
                            [item.target.gps.lat, item.target.gps.lon]
                        ]}
                    >
                    </Polyline>
                    <MarkerCombined
                        key={`sender-${index}`}
                        args={{
                            id: index,
                            type: "handradio",
                            lat: item.target.gps.lat,
                            lon: item.target.gps.lon,
                            title: "",
                            description: '<i>Ez a funkció itt nem érhető el!</i>'
                        }}
                        eventAction={() => { }}
                        callback={() => { }}
                    />
                </>
            )))}
            {(senderCoords.map((sender, index) => (
                <MarkerCombined
                    key={`sender-${index}`}
                    args={{
                        id: index,
                        type: "handradio",
                        lat: sender.lat,
                        lon: sender.lon,
                        title: "",
                        description: '<i>Ez a funkció itt nem érhető el!</i>'
                    }}
                    eventAction={() => { }}
                    callback={() => { }}
                />
            )))}
        </>
    )
}