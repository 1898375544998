import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../Utils/Firebase';
import FaIcon from '../../Widgets/Global/FaIcon';
import { __getDateFromStamp, __getTimeStamp, __getTimeStampFromDate } from '../../Utils/TimeFuncs';
import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { useUsers } from '../../Contexts/UserContext';
import { Editor } from '@tinymce/tinymce-react';

const Event = () => {
    const { user } = useUsers();
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [hasJoined, setHasJoined] = useState(false);
    const [joins, setJoins] = useState(0);
    
    const fetchEvent = async () => { const EventDoc = await getDoc(doc(db, "events", id)); setDetails({ id: EventDoc.id, ...EventDoc.data() }); };
    const checkJoined = async () => {
        const joinDoc = await getDoc(doc(db, "events", id, "joins", user.uid));
        setHasJoined(joinDoc.exists());

        const joinDocs = await getDocs(collection(db, "events", id, "joins"));
        setJoins(joinDocs.docs.length);
    }
    useEffect(() => { fetchEvent(); checkJoined(); }, [id]);

    const join = async () => { await setDoc(doc(db, "events", id, "joins", user.uid), { time: __getTimeStamp() }); checkJoined(); }
    const join_cancel = async () => { await deleteDoc(doc(db, "events", id, "joins", user.uid)); checkJoined(); }

    /*Admin*/
    const editorRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [fields, setFields] = useState({ title: '', date: ''});
    useEffect(() => {
        if(modal){
            setFields({
                title: details?.title,
                date: new Date(__getDateFromStamp(details?.date)).toISOString().slice(0,16)
            });
            if(editorRef.current){
                editorRef.current.setContent(details?.content);
            };
        }else return;
    },[modal, editorRef.current])
    const eventChange = (event) => { const { name, value } = event.target; setFields((prev) => ({ ...prev, [name]: value})); }
    const eventSave = async () => {
        await updateDoc(doc(db, "events", id), {
            title: fields.title,
            date: __getTimeStampFromDate(fields.date),
            content: editorRef.current.getContent()
        });
        setFields({ title: '', date: ''});
        editorRef.current.setContent('');
        await fetchEvent();
        setModal(!modal);
    }
    return (
        <>
            <div className="feedItem" style={{ position: 'relative' }}>
                <h2 className='title'>{details?.title}</h2>
                <ButtonGroup style={{ position: "absolute", top: "0px", right: "0px" }}>
                    {(!hasJoined) && (<Button variant="dark" onClick={join}><FaIcon type="light" icon="user-plus" /> Jelentkezem </Button>)}
                    {(hasJoined) && (<Button variant="dark" onClick={join_cancel}><FaIcon type="light" icon="user-times" /> Lemondom </Button>)}
                {(user.perm >= 97) && (
                    <Button variant="dark" onClick={() => setModal(!modal)}><FaIcon type="light" icon="pencil" /></Button>
                )}
                </ButtonGroup>
                <span classname="text-meta">
                    <FaIcon type="light" icon="calendar-alt" /> {__getDateFromStamp(details?.date)} · <FaIcon type="light" icon="users" /> {joins} jelentkező
                </span>
                <hr />
                <Row>
                    <Col md={12}>
                        <img src={details?.image} className="img-responsive img-float-left max-width-200" alt={details?.title} />
                        <span style={{textAlign:"justify"}} dangerouslySetInnerHTML={{ __html: details?.content}}></span>
                    </Col>
                </Row>
            </div>
            <Modal show={modal}>
                <Modal.Body>
                    <b>Esemény szerkesztése</b>
                    <hr />
                    <Row>
                        <Col md={12}>
                            <FloatingLabel label="Esemény neve" className="dark mb-3">
                                <Form.Control type="text" placeholder="Esemény neve" name="title" className="dark" value={fields.title} onChange={eventChange}/>
                            </FloatingLabel>
                            <FloatingLabel label="Esemény kezdete" className="dark mb-3">
                                <input aria-label="Date and time" type="datetime-local" name="date" className="form-control dark" onChange={eventChange} value={fields?.date}/>
                            </FloatingLabel>
                            <Editor
                                apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                                onInit={(_evt, editor) => editorRef.current = editor}
                                init={{
                                    skin: "oxide-dark",
                                    content_css: "dark",
                                    icon: "small",
                                    menubar: false,
                                    toolbar: 'bold italic'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button variant="dark" onClick={eventSave}>Mentés</Button>
                            <Button variant="dark" onClick={() => setModal(!modal)}>Mégse</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Event;