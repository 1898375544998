import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { getCoordsByMaidenhead, getDistanceByQTH } from '../Utils/LocationService';
import FaIcon from '../Widgets/Global/FaIcon';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../Utils/Formatter';
import MiniMap from '../Widgets/QSO/MiniMap';
import { useUsers } from '../Contexts/UserContext';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../Utils/Firebase';
import LoadingIndicator from '../Widgets/Global/LoadingIndicator';

const QSOList = () => {
    const { user } = useUsers();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ list: [] })

    useEffect(() => {
        const fetchQSO = async () => {
            try {
                setState({list: []});
                setLoading(true);
                const qsoRef = collection(db, "qsos");
                const qsoQuery = query(qsoRef, where("uid", "==", user.uid), orderBy("time", "desc"));
                const qsoSnapshot = await getDocs(qsoQuery);
                const my = qsoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                my.map(async (item, index) => {
                    const listRef = collection(db, "qsos", item.id, "list");
                    const listQuery = query(listRef, orderBy("date", "desc"));
                    const listSnap = await getDocs(listQuery);
                    const qso = listSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                    qso.map(async (log) => {
                        console.log(item, log);
                        let _item = {
                            id: item.id,
                            qid: log.id,
                            myCallsign: item.callsign,
                            myQth: item.qth,
                            myPos: getCoordsByMaidenhead(item.qth),
                            remCallsign: log.callsign,
                            remQth: log.qth,
                            remPos: getCoordsByMaidenhead(log.qth),
                            remName: log.opName,
                            date: log.date,
                            freq: log.freq,
                            ctcss: log.ctcs,
                            type: log.type,
                            mode: log.mode,
                            handled: log.handled,
                            validated: log.validated
                        };
                        setState(prevState => ({...prevState,list: [...prevState.list, _item]}));
                    })
                });
            } catch (e) { }
            finally {
                setLoading(false);
            };
        };

        fetchQSO();
    }, []);

    if (loading) return <LoadingIndicator />;

    return (
        <Container className="mt-base">
            <Row className='fb-styled-card' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                {state.list.map((item, index) => (
                    <Col className="mb-3" xs={12} sm={6} md={4} key={index}>
                        <Card>
                            {(item.handled && item.validated) && (
                                <Badge color='success' className='position-absolute' style={{ borderRadius: '0px', top: '0px', right: '0px', fontSize: '16px', zIndex: '999' }}>
                                    <FaIcon type="solid" icon="check" /> Hitelesítve
                                </Badge>
                            )}
                            <MiniMap
                                posA={[getCoordsByMaidenhead(item.myQth).lat, getCoordsByMaidenhead(item.myQth).lon]}
                                posB={[getCoordsByMaidenhead(item.remQth).lat, getCoordsByMaidenhead(item.remQth).lon]}
                                posC={(item.pID != null) ? [item.lat, item.lon] : null}
                                my={{
                                    title: item.my_callsign,
                                    description: ''
                                }}
                                rem={{
                                    title: item.rem_callsign,
                                    description: ''
                                }}
                                args={item}
                            />
                            <Card.Body>
                                <Card.Title className="text-center">{item.rem_callsign}</Card.Title>
                                <Card.Text>
                                    <Row>
                                        <Col md={7} xs={7}>
                                            <FaIcon type="solid" icon="calendar-alt" /> {formatDate(new Date(item.date), ".", "short")}
                                        </Col>
                                        <Col md={5} xs={5} className="text-right">
                                            <FaIcon type="solid" icon="ruler" /> {getDistanceByQTH(item.myQth, item.remQth)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={4}>
                                            <FaIcon type="solid" icon="globe" /> {formatQSOType(item.type)}
                                        </Col>
                                        <Col md={8} xs={8} className="text-right">
                                            <FaIcon type="solid" icon="signal-stream" /> {formatFrequency(item.freq)}                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} xs={6}>
                                            <FaIcon type="solid" icon="globe" /> {formatQSOMode(item.mode)}
                                        </Col>
                                        <Col md={6} xs={6} className="text-right">
                                            <FaIcon type="solid" icon="key" /> {formatCTCSSorDCSCode(item?.ctcs || null)}
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                            <Link to={`/qso/item/${item.id}?q=${item.qid}`} style={{ cursor: 'pointer', zIndex: '1030' }} className="stretched-link"></Link>
                        </Card>
                    </Col>
                ))
                }
            </Row>
        </Container>
    );
};

export default QSOList;