export const __getTimeStamp = () => {
    const now = new Date();
    return Math.floor(now.getTime() / 1000);
}
export const __getTimeStampFromDate = (date) => {
    const now = new Date(date);
    return Math.floor(now.getTime() / 1000);
}

export const __getDateStamp = (dateString = null) => {
    const date = dateString ? new Date(dateString) : new Date();
    if(isNaN(date.getTime())){ throw new Error ("Invalid date format"); };
    return Math.floor(date.getTime() / 1000);
}
export const __getDateFromStamp = (timestamp) => {
    if(timestamp === undefined) return;
    if (!Number.isInteger(timestamp)) { throw new Error(`Invalid timestamp. Please provide an integer value: ${timestamp}`); }
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
}

export const getOnlineDiff = (time = 0) => {
    const now = new Date();
    const diff = Math.floor((now - new Date(time * 1000)) / 1000);
    const minutes = Math.floor(diff / 60);
    const hours = Math.floor(diff / 3600);
    const days = Math.floor(diff / 86400);
    const weeks = Math.floor(diff / 604800);
    if(minutes <= 5){ return "Online"; }
    else if(minutes > 6 && minutes < 60){ return `${minutes} perce`}
    else if (hours < 24) { return `${hours} órája`; } 
    else if (days < 2) { return "tegnap"; } 
    else if (days < 7) { return `${days} napja`; } 
    else if (weeks < 4) { return `${weeks} hete`; } 
    else { const date = new Date(time * 1000); return date.toISOString().split('T')[0]; }
}
export const getDiffInMinutes = (time = 0) => {
    const now = new Date();
    const diff = Math.floor((now - new Date(time * 1000)) / 1000);
    const minutes = Math.floor(diff / 60);
    return minutes;
}

export const getDiff = (time = 0) => {
    const now = new Date();
    const diff = Math.floor((now - new Date(time * 1000)) / 1000);
    const minutes = Math.floor(diff / 60);
    const hours = Math.floor(diff / 3600);
    const days = Math.floor(diff / 86400);
    const weeks = Math.floor(diff / 604800);
    if (minutes < 60) {
        return `${minutes} perce`;
    } else if (hours < 24) {
        return `${hours} órája`;
    } else if (days < 2) {
        return "tegnap";
    } else if (days < 7) {
        return `${days} napja`;
    } else if (weeks < 4) {
        return `${weeks} hete`;
    } else {
        const date = new Date(time * 1000);
        return date.toISOString().split('T')[0];
    }
}

export const formatDate = (date, sep = "-", format = "full") => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    if(format === "full") { return `${year}${sep}${month}${sep}${day} ${hours}:${minutes}:${seconds}`; };
    if(format === "short") { return `${month}${sep}${day} ${hours}:${minutes}`};
}