import React, { useState } from 'react';
import { Button, ListGroup, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/FaIcon';

import TBUserPicture from './TBUserPicture';
import { useUsers } from '../../../Contexts/UserContext';

const TBUserDropdown = () => {
    const { user } = useUsers();
    const [show, setShow] = useState(false);
    const handleCanvas = () => { setShow(!show); };

    const menuThree = [
        {type: "solid", icon: "sign-out-alt", path: "/logout", text: "Kijelentkezés"}
    ];

    return (
        <>
        <NavDropdown className="d-none d-sm-none d-md-block user-dd" title={<TBUserPicture user={user} showName={false} />} id="navbarScrollingDropdown">
            <NavDropdown.Item as={Link} to={`/myprofile`}>
                <TBUserPicture user={user} showName={true} />
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {(menuThree.map((item,index) => (
                <NavDropdown.Item as={Link} to={item.path} key={index}>
                    <FaIcon type={item.type} icon={item.icon} /> {item.text}
                </NavDropdown.Item>
            )))}            
        </NavDropdown>
        <Button className="d-block d-md-none" variant="dark" onClick={handleCanvas}><TBUserPicture user={user} showName={false} /></Button>
        <Offcanvas placement="end" show={show} onHide={handleCanvas} bg="dark" data-bs-theme="dark">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><TBUserPicture user={user} showName={true} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ListGroup className={`sl_listGroup`}>
            {(menuThree.map((item,index) => (                
                <ListGroup.Item onClick={handleCanvas} as={Link} to={item.path} key={index}><FaIcon type={item.type} icon={item.icon} /> {item.text} </ListGroup.Item>
            )))}
            </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
        </>
    );
};

export default TBUserDropdown;