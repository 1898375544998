import React, { useEffect } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import IconSet from './Icons';

const ISSMarker = ({ pos, path }) => {
    useEffect(() => {
        console.log(pos);
    },[]);
    
    return (
        <>
            <Marker key="iss" icon={IconSet.ISS} position={[pos.lat, pos.lon]}></Marker>
            <Polyline positions={path} color='red' />
        </>
    );
};

export default ISSMarker;