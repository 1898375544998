import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, InputGroup } from 'react-bootstrap';
import FaIcon from '../../../Global/FaIcon';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../Utils/Firebase';
import { useUsers } from '../../../../Contexts/UserContext';

const TowerModal = ({ show, pos, callback, method = "new", params }) => {
    const { user } = useUsers();
    const editorRef = useRef(null);
    const [state, setState] = useState({
        fields: {
            markerTitle: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            upstream_freq: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            downstream_freq: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            ctcs: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            dcs: {
                value: '',
                error: '',
                css: 'is-invalid'
            }
        }
    });

    useEffect(() => {
        if (method === "edit") {
            setState({
                fields: {
                    markerTitle: { value: params.title, error: '', css: 'is-valid'},
                    upstream_freq: { value: params.freq.up, error: '', css: 'is-valid'},
                    downstream_freq: { value: params.freq.down, error: '', css: 'is-valid'},
                    ctcs: { value: params.code.ctcs, error: '', css: 'is-valid'},
                    dcs: { value: params.code.dcs, error: '', css: 'is-valid'}
                }
            });
            if(editorRef.current){ editorRef.current.setContent(params.description); }
        };
    }, [method, editorRef.current])

    const handleChange = (e) => {
        const field = e.target.name;
        let val = e.target.value;
        let fields = state.fields;
        if (field === "upstream_freq" || field === "downstream_freq") {
            if (val.length === 3 && !val.includes(".")) { val += "."; };
            fields[field]['css'] = (val >= 7) ? 'is-valid' : 'is-invalid';
        } else if (field === "ctcs") {
            if (val.length === 3 && !val.includes(".")) { val += "."; };
            fields[field]['css'] = (val >= 5) ? 'is-valid' : 'is-invalid';
        } else if (field === "markerTitle") {
            fields[field]['css'] = (val >= 6) ? 'is-valid' : 'is-invalid';
        };
        fields[field]['value'] = val;
        setState({ ...state, fields });
    }

    const eventSubmit = async (e) => {
        e.preventDefault();
        if (method === "new") {
            const fields = {
                type: 'tower',
                lat: pos.lat,
                lon: pos.lon,
                title: state.fields.markerTitle.value,
                upstream_freq: state.fields.upstream_freq.value,
                downstream_freq: state.fields.downstream_freq.value,
                ctcs: state.fields.ctcs.value,
                dcs: state.fields.dcs.value,
                description: editorRef.current.getContent(),
                isActive: true,
                isWorking: true,
                owner: user.uid
            };
            await addDoc(collection(db, "markers"), fields);
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['upstream_freq']['value'] = '';
            f['downstream_freq']['value'] = '';
            f['ctcs']['value'] = '';
            f['dcs']['value'] = '';

            f['markerTitle']['css'] = 'is-invalid';
            f['upstream_freq']['css'] = 'is-invalid';
            f['downstream_freq']['css'] = 'is-invalid';
            f['ctcs']['css'] = 'is-invalid';
            f['dcs']['css'] = 'is-invalid';

            editorRef.current.setContent('');
            setState({ ...state, f });
            callback('tower');
        } else if (method === "edit") {
            const fields = {
                title: state.fields.markerTitle.value,
                upstream_freq: state.fields.upstream_freq.value,
                downstream_freq: state.fields.downstream_freq.value,
                ctcs: state.fields.ctcs.value,
                dcs: state.fields.dcs.value,
                description: editorRef.current.getContent()
            };
            await updateDoc(doc(db, "markers", params.id), fields);
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['upstream_freq']['value'] = '';
            f['downstream_freq']['value'] = '';
            f['ctcs']['value'] = '';
            f['dcs']['value'] = '';

            f['markerTitle']['css'] = 'is-invalid';
            f['upstream_freq']['css'] = 'is-invalid';
            f['downstream_freq']['css'] = 'is-invalid';
            f['ctcs']['css'] = 'is-invalid';
            f['dcs']['css'] = 'is-invalid';

            editorRef.current.setContent('');
            setState({ ...state, f });
            callback('tower');
        }
    }

    return (
        <Modal show={show}>
            <Form onSubmit={(e) => { eventSubmit(e) }}>
                <Modal.Header>
                    <Modal.Title>Új átjátszó létrehozása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control className={`dark ${state.fields.markerTitle.css}`} type="text" name="markerTitle" id="markerTitle" value={state.fields.markerTitle.value} onChange={handleChange} placeholder="Átjátszó neve vagy hívójele" />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="freq" className="dark"><FaIcon type="solid" icon="down" /></InputGroup.Text>
                        <Form.Control className={`dark ${state.fields.downstream_freq.css}`} type="text" name="downstream_freq" id="downstream_freq" value={state.fields.downstream_freq.value} onChange={handleChange} placeholder="Lejövő frekvencia" />
                        <InputGroup.Text id="ctcs" className="dark">MHz</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="freq" className="dark"><FaIcon type="solid" icon="up" /></InputGroup.Text>
                        <Form.Control className={`dark ${state.fields.upstream_freq.css}`} type="text" name="upstream_freq" id="upstream_freq" value={state.fields.upstream_freq.value} onChange={handleChange} placeholder="Felmenő frekvencia" />
                        <InputGroup.Text id="freq" className="dark">MHz</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="freq" className="dark"><FaIcon type="solid" icon="key" /></InputGroup.Text>
                        <Form.Control className={`dark ${state.fields.ctcs.css}`} type="text" name="ctcs" id="ctcs" value={state.fields.ctcs.value} onChange={handleChange} placeholder="CTCSS" />
                        <InputGroup.Text id="freq" className="dark">Hz</InputGroup.Text>
                        <InputGroup.Text id="freq" className="dark"><FaIcon type="solid" icon="key" /></InputGroup.Text>
                        <Form.Control className={`dark ${state.fields.dcs.css}`} type="text" name="dcs" id="dcs" value={state.fields.dcs.value} onChange={handleChange} placeholder="DCS" />
                    </InputGroup>
                    <Form.Group className="mb-3">
                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{ skin: "oxide-dark", content_css: "dark", icon: "small", menubar: false, toolbar: 'undo redo | bold italic' }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { callback('tower'); }} variant="dark">Mégse</Button>
                    <Button type="submit" variant="dark">Mentés</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TowerModal;