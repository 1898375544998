import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getCoordsByMaidenhead, getDistanceByQTH, totalDistance } from '../Utils/LocationService';
import { Badge, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import FaIcon from '../Widgets/Global/FaIcon';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Utils/Firebase';
import { MapContainer, Polyline, TileLayer, Tooltip, useMap } from 'react-leaflet';
import MarkerCombined from '../Widgets/Dashboard/Map/MarkerCombined';
import LoadingIndicator from '../Widgets/Global/LoadingIndicator';
import { formatCTCSSorDCSCode, formatDate, formatFrequency, formatQSOMode, formatQSOType } from '../Utils/Formatter';
const FitMapToBounds = ({ pointA, pointB }) => { const map = useMap(); useEffect(() => { const bounds = [pointA, pointB]; map.fitBounds(bounds, { padding: [0, 0] }); }, [pointA, pointB, map]); }

const QSOitem = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const q = queryParams.get('q');

    const mapRef = useRef();
    const [result, setResult] = useState(false);
    const [center] = useState([47.1628, 19.5036]);
    const [zoom] = useState(6);

    useEffect(() => {
        const fetch = async () => {
            const logRef = doc(db, "qsos", id, "list", q);
            const logData = await getDoc(logRef); //Maga a qso riport
            const log = logData.data();
            const senderRef = doc(db, "qsos", id);
            const senderData = await getDoc(senderRef);
            const sender = senderData.data();
            let res = {
                log: {
                    freq: log.freq,
                    ct: log.ctcs,
                    type: log.type,
                    mode: log.mode,
                    date: new Date(log.date),
                    distance: getDistanceByQTH(sender.qth, log.qth)
                },
                sender: {
                    callsign: sender.callsign,
                    opName: sender.callsign,
                    qth: sender.qth
                },
                target: {
                    callsign: log.callsign,
                    opName: log.opName,
                    qth: log.qth
                },
                validated: log.validated
            };
            let _result = { success: true, item: res };
            setResult(_result);
        }; fetch();
    }, []);

    if (!result) { return <LoadingIndicator /> };

    return (
        <>
            <Container className="feedItem mb-3" style={{ position: "relative" }}>
                <MapContainer
                    ref={mapRef}
                    center={center}
                    zoom={zoom}
                    scrollWheelZoom={false}
                    dragging={false}
                    doubleClickZoom={false}
                    zoomControl={false}
                    id='mapPicture'
                    style={{
                        width: 'clamp(200px, 100%, 1265px)',
                        height: 'clamp(200px, 100vh, 480px)'
                    }}
                ><div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 1000, }}></div>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerCombined key={0} args={{
                        id: 0,
                        type: 'temp',
                        lat: getCoordsByMaidenhead(result?.item.sender.qth).lat,
                        lon: getCoordsByMaidenhead(result?.item.sender.qth).lon,
                        title: result?.item.sender.callsign,
                        description: ''
                    }} eventAction={() => { }} callback={() => { }}
                    ><Tooltip permanent direction="top" offset={[0, -10]} opacity={1} > {result?.item.sender.callsign} </Tooltip></MarkerCombined>
                    <MarkerCombined key={1} args={{
                        id: 1,
                        type: 'handradio',
                        lat: getCoordsByMaidenhead(result?.item.target.qth).lat,
                        lon: getCoordsByMaidenhead(result?.item.target.qth).lon,
                        title: result?.item.target.callsign,
                        description: ''
                    }} eventAction={() => { }} callback={() => { }}
                    ><Tooltip permanent direction="top" offset={[0, -10]} opacity={1} > {result?.item.target.callsign} </Tooltip></MarkerCombined>
                    <Polyline
                        pathOptions={{ color: 'red' }}
                        positions={[
                            [getCoordsByMaidenhead(result?.item.sender.qth).lat, getCoordsByMaidenhead(result?.item.sender.qth).lon],
                            ...(result?.item.pID ? [[result?.item.lat, result?.item.lon]] : []),
                            [getCoordsByMaidenhead(result?.item.target.qth).lat, getCoordsByMaidenhead(result?.item.target.qth).lon]
                        ]}
                    > <Tooltip permanent direction="top" offset={[0, 0]} opacity={1} > {result?.item.log.distance} </Tooltip>
                    </Polyline>
                    <FitMapToBounds
                        pointA={[
                            getCoordsByMaidenhead(result?.item.sender.qth).lat,
                            getCoordsByMaidenhead(result?.item.sender.qth).lon
                        ]}
                        pointB={[
                            getCoordsByMaidenhead(result?.item.target.qth).lat,
                            getCoordsByMaidenhead(result?.item.target.qth).lon
                        ]}
                    />
                    {(result.item.validated) && (
                        <Badge bg="success" style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '1001', borderRadius: '0px', fontSize: '16px' }}>
                            <FaIcon type="solid" icon="ckeck" /> Visszaigazolva
                        </Badge>
                    )}
                </MapContainer>

            </Container>
            <Container className="feedItem">
                <Row>
                    <Col md={6} xs={6}>
                        Hívójel: <b>{result?.item.sender.callsign}</b><br />
                        Operátor <b>{result?.item.sender.opName}</b><br />
                    </Col>
                    <Col md={6} xs={6} className="text-right">
                        Hívójel: <b>{result?.item.target.callsign}</b><br />
                        Operátor <b>{result?.item.target.opName}</b><br />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={6}>
                        Típusa: <b>{formatQSOType(result?.item.log.type)}</b>, <b>{formatQSOMode(result?.item.log.mode)}</b><br />
                        {(result.item.pID) && (<><b>({result.item.title})</b><br /></>)}
                        Dátum: <b>{formatDate(result.item.log.date, ".")}</b>
                    </Col>
                    <Col md={6} xs={6} className="text-right">
                        Frekvencia: <b>{formatFrequency(result?.item.log.freq)}</b><br />
                        {(result?.item.log.ct !== "") && (<><b>{formatCTCSSorDCSCode(result?.item.log.ct)}</b><br /></>)}
                        Távolság: <b>{result?.item.log.distance}</b>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default QSOitem;