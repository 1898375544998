import React, { useEffect, useState } from 'react';
import { Form, Col, Container, Modal, Row, InputGroup, Button, Alert } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Activate from './Activate';
import ForgotPW from './ForgotPW';

const AuthFrame = () => {
    const [ pinValid, setPinValid] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ pinCode, setPinCode ] = useState('');
    const onChange = (event) => {
        setPinCode(event.target.value);
    }
    const validate = () => {
        if(pinCode === "920910#446.hu"){
            setPinValid(true);
            setModal(false);
        }
    }
    useEffect(() => {
        if(!pinValid) setModal(true);
    },[]);
    return (
        <Container fluid className="auth d-flex align-items-center justify-content-center">
            <Container>
                {/*<Modal show={modal}>
                    <Modal.Body>
                        <Alert variant='warning'>
                            Kedves Látogató!<br/>
                            Az oldalt teszteljük, így csak előre meghatározott PIN kóddal érhető el. Amennyiben rendelkezel a PIN kóddal, add meg a lenti formban.
                        </Alert>
                        <InputGroup>
                            <Form.Control type="text" name="pinCode" value={pinCode} onChange={onChange} className="dark"/>
                            <Button onClick={validate} variant="dark">Hitelesítés</Button>
                        </InputGroup>
                    </Modal.Body>
                </Modal>*/}
                <Row className="w-100 h-100 align-items-center">
                    <Col>
                        <Row>
                            <Col md={{ order: 1, span: 6 }}>
                                <img src="https://static.446.hu/images/446logo.png" alt="logo" />
                                <div className="loginText">
                                    A 446.hu-n megtalálsz mindent, ami a PMR-el kapcsolatos!
                                </div>
                            </Col>
                            <Col md={{ order: 1, span: 6 }} className="text-center">
                                <div className="loginBox">
                                    <Routes>
                                        <Route path="/" element={<Login />} />
                                        <Route path="/register" element={<Register />} />
                                        <Route path="/activate" element={<Activate />} />
                                        <Route path="/fpw" element={<ForgotPW />} />
                                    </Routes>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default AuthFrame;