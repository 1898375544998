import { addDoc, collection, doc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../Utils/Firebase';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUsers } from '../../../Contexts/UserContext';
import FaIcon from '../../../Widgets/Global/FaIcon';
import { Bounce, toast } from 'react-toastify';

const DLLIstCategory = () => {
    const { user } = useUsers();
    const [cat, setCat] = useState([]);
    
    const fetchCat = async () => {
        setCat([]);
        const catRef = collection(db, "downloads");
        const catDocs = await getDocs(catRef);
        for(const cat of catDocs.docs){
            setCat((prev) => [ ...prev, { id: cat.id, ...cat.data()}])
        };
    };

    useEffect(() => {
        fetchCat();
    },[]);  
    
    /*Admin segment*/
    const [ cname, setCName ] = useState('');
    const [ cdesc, setCDesc ] = useState('');
    const onChange = (event) => {
        const { name, value } = event.target;
        if(name === "name") { setCName(value); };
        if(name === "desc") { setCDesc(value); };
    }
    const create = async () => {
        await addDoc(collection(db,"downloads"), { name: cname, description: cdesc });
        setCName('');
        setCDesc('');
        toast.success('Kategória létrehozva', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce
        });
        fetchCat();
    }

    return (
        <>
        <Row className="mb-3">
            {(cat.map((item,index) => (
            <Col md={6}>
                <Card>
                    <Card.Body className="text-center">
                        <b>{item.name}</b><br/>
                        <span className="small" dangerouslySetInnerHTML={{__html: item.description}}></span>
                        <Link to={`/downloads/${item.id}`} className="stretched-link"></Link>
                    </Card.Body>
                </Card>
            </Col>
            )))}
        </Row>
        {(user?.perm >= 97) && (
            <Row>
                <h3 className='title'>Új kategória</h3>
                <Col md={12}>
                    <InputGroup>
                        <Form.Control name="name" className="dark" value={cname} onChange={onChange} placeholder='Kategória neve'/>
                        <Form.Control name="desc" className="dark" value={cdesc} onChange={onChange} placeholder='Rövid leírás'/>
                        <Button variant="dark" onClick={create}><FaIcon type="light" icon="save" /></Button>
                    </InputGroup>
                </Col>
            </Row>
        )}
        </>
    );
};

export default DLLIstCategory;