import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, NavDropdown, NavItem, Row } from 'react-bootstrap';
import FaIcon from '../../Global/FaIcon';
import { useChat } from '../../../Contexts/ChatContext';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { useUsers } from '../../../Contexts/UserContext';
import { getUserByID } from '../../../Utils/FirebaseFuncs';
import TBUserPicture from './TBUserPicture';
import { Link } from 'react-router-dom';
import { getDiff } from '../../../Utils/TimeFuncs';
import { playSound } from '../../../Utils/Audio';

const TBMessageDropdown = () => {
    const { messageCount, chats } = useChat();

    useEffect(() => {
        if(messageCount > 0){
            playSound("message", 1.0);
        }
    },[messageCount]);

    return (
        <>
        <NavItem as={Link} to="/messenger" className="d-block d-sm-block d-md-none" style={{position:"relative",top:"8px"}}><TBMessageIcon count={messageCount}/></NavItem>
        <NavDropdown className="message-dd d-none d-sm-none d-md-block" title={<TBMessageIcon count={messageCount}/>} id="navbarScrollingDropdown">
        <NavDropdown.Item href="#" onClick={(e) => { e.preventDefault(); }}>
            <Row>
                <Col><b>Chatek</b></Col>
                <Col className="text-right">
                    <Button variant="link" size='sm' as={Link} to="/messenger"><FaIcon type="light" icon="maximize" /></Button>
                </Col>
            </Row>
        </NavDropdown.Item>
        {(chats.map((item,index) => ( <TBMessageItem item={item} key={index} /> )))}
        </NavDropdown>
        </>
    );
};

const TBMessageIcon = ({ count = 0}) => {
    return (
        <>
            <FaIcon type="solid" icon="envelope-open" style={{fontSize: "26px"}}/>
            {(count > 0) && ( <Badge bg="danger" style={{position:'absolute',top:"0px",right:"5px"}}>{count}</Badge>)}
        </>
    )
}

const TBMessageItem = ({ item }) => {
    const { user } = useUsers();
    const { openChat } = useChat();
    const [ sender, setSender] = useState([]);

    useEffect(() => {
        const fetchMessageMeta = async () => {
            const senderID = item.room.persons.filter(person => person !== user.uid)[0];
            const _sender = await getUserByID(senderID);
            setSender({
                __id: _sender.uid,
                avatar: _sender.avatar,
                username: _sender.username,
                lastAction: _sender.lastAction
            });
        };
        fetchMessageMeta();
    },[]);

    return (
        <NavDropdown.Item onClick={() => {openChat(sender.__id); }}>
            <Row>
                <Col md={3} className="text-center">
                    <TBUserPicture user={sender} w={48} h={48} />
                </Col>
                <Col md={7}>
                    <b>{sender.username}</b><br/>
                    {item.last.message} · <span className="small">{getDiff(item.last.timestamp)}</span>
                </Col>
                <Col md={2} className="text-right align-self-center">
                    {(item.last.unReaded) && (<div className="unreaded"></div>)}
                </Col>
            </Row>
        </NavDropdown.Item>
    )
}

export default TBMessageDropdown;
export { TBMessageIcon };