import React, { useEffect, useState } from 'react';
import { drawer, yagi_calculator } from '../../Utils/Calculators/YagiCalc';
import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { Canvas, useThree } from '@react-three/fiber';
import { Yagi3D } from '../../Utils/Calculators/3DYagi';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import FaIcon from '../../Widgets/Global/FaIcon';

const Yagi = () => {
    const [field, setField] = useState({ isolated: false, freq: 446, elements: 5, elements_diameter: 4, boom_diameter: 15 });
    const [result, setResult] = useState(null);
    const [svg, setSVG] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setField((prev) => ({ ...prev, [name]: value }));
    }

    const [viewer, setViewer] = useState(false);
    const submitForm = (event) => {
        event.preventDefault();
        const r = yagi_calculator({
            freq: field.freq,
            elements: field.elements,
            diameter_of_parasitic_elements: field.elements_diameter,
            diameter_of_boom: field.boom_diameter,
            boom_isolated: field.isolated
        });
        setResult(r);
        console.log(r);
        const svgOutput = drawer(r);
        setSVG(svgOutput);
        
        if(viewer === false) setViewer("details");
    }   

    return (
        <>
            <div className='feedItem mb-3'>
                <Form onSubmit={submitForm}>
                    <InputGroup>
                        <InputGroup.Text className="dark" style={{ borderBottomLeftRadius: '0px' }}>Szigetelt elemek?</InputGroup.Text>
                        <Form.Select className="dark" name="isolated" value={field.isolated} onChange={handleChange}>
                            <option value={true}>Igen</option>
                            <option value={false}>Nem</option>
                        </Form.Select>
                        <InputGroup.Text className="dark">Frekvencia:</InputGroup.Text>
                        <Form.Control type="text" className="dark" name="freq" value={field.freq} onChange={handleChange} />
                        <InputGroup.Text className="dark" style={{ borderBottomRightRadius: '0px' }}>MHz</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className='mb-3'>
                        <InputGroup.Text className="dark" style={{ borderTopLeftRadius: '0px' }}>Elemek:</InputGroup.Text>
                        <Form.Control type="number" min={0} max={9} className="dark" name="elements" value={field.elements} onChange={handleChange} />
                        <InputGroup.Text className="dark">Átmérő:</InputGroup.Text>
                        <Form.Control type="number" min={0} max={60} className="dark" name="elements_diameter" value={field.elements_diameter} onChange={handleChange} />
                        <InputGroup.Text className="dark">mm</InputGroup.Text>
                        <InputGroup.Text className="dark">Kar átmérő:</InputGroup.Text>
                        <Form.Control type="number" min={0} max={60} className="dark" name="boom_diameter" value={field.boom_diameter} style={{ borderTopRightRadius: '0px' }} onChange={handleChange} />
                        <InputGroup.Text className="dark">mm</InputGroup.Text>
                    </InputGroup>
                    <Button type="submit" variant="dark">Számol</Button>
                </Form>
            </div>
            {(result) && (
                <div className='feedItem' style={{ position: "relative" }}>
                    <ButtonGroup style={{position:'absolute',top:'0px',right:'0px'}}>
                        <Button onClick={() => {setViewer("details")}} variant="dark" style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}}><FaIcon type="light" icon="info-circle"/></Button>
                        {/*<Button onClick={() => {setViewer("svg")}} variant="dark"><FaIcon type="light" icon="pencil"/></Button>*/}
                        <Button onClick={() => {setViewer("3d")}} variant="dark" style={{borderBottomRightRadius: 0}}><FaIcon type="light" icon="vr-cardboard"/></Button>
                    </ButtonGroup>
                    {(viewer === "details") && (
                        <>
                            Frekvencia: <b>{result.frequency} Mhz</b>, Hullámhossz: <b>{result.wavelength} mm</b><br/>
                            Elem átmérő: <b>{result.rodDiameter} mm</b>, Gém átmérő: <b>{result.boomDiameter} mm</b><br/>
                            Elemek száma: <b>{result.elements}</b>, Gém hossz: <b>{result.boomLength} mm</b><br/>
                            Várható nyereség: <b>{result.gain} dBi</b>
                            <hr/>
                            {(result.details.map((item,index) => {
                                let name = "";
                                if(item.type === "Reflector"){ name = "Reflektor"; };
                                if(item.type === "Dipole"){ name = "Dipól"; };
                                if(item.type.includes("Director")){ let _n = item.type.split('#'); name = `Irányító ${_n[1]}`; };

                                return (
                                    <span key={index}>
                                        {name} hossza: <b>{item.length} mm</b>, pozíciója: <b>{item.position} mm</b>
                                        {(item.distanceToPrevious) && (<>, előző elem távolsága: <b>{item.distanceToPrevious} mm</b></>)}<br/>
                                    </span>
                                )
                            }))}
                            <hr/>
                            Dipól forma: <b>Egyenes</b>, <b>Kerek</b> keresztmetszetű <b>gém</b>.<br/>
                            
                        </>
                    )}
                    {(viewer === "svg") && (
                        <img src={svg} className="yagi-image" alt="Yagi antenna"/>
                    )}
                    {(viewer === "3d") && (
                        <Canvas style={{ width: '100%', height: '420px' }} camera={{ zoom: 10, position: [1, -2.5, 10]}} >
                            <CameraControls />
                            <ambientLight />
                            <pointLight position={[10, 10, 10]} />
                            <directionalLight intensity={4.16} />
                            <Yagi3D antennaData={result} />
                            <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} />
                        </Canvas>
                    )}
                </div >
            )}
        </>
    );
};
const CameraControls = () => {
    useThree(({ camera }) => {
        camera.rotation.set(THREE.MathUtils.degToRad(30), 0, 0);
    });

    return null
}
export default Yagi;