import React from 'react';
import Ads from '../Global/Ads';
import OnlineUsers from './Sidebar/OnlineUsers';

const SidebarRight = () => {
    return (
        <>
            <OnlineUsers />
            <Ads />
        </>
    );
};

export default SidebarRight;