const sounds = {
    message: new Audio("../Sounds/message.mp3"),
    notification: new Audio("../Sounds/notification.mp3"),
};

export const playSound = (name, volume = 1.0) => {
    const sound = sounds[name];
    if(sound){
        try{
            sound.volume = parseFloat(1.0) * parseFloat(volume);
            sound.currentTime = 0;
            sound.play();
        }catch(err){};
    }else{
        console.warn(`A(z) ${name} hang nem található!`)
    };
}