import React, { useState } from 'react';
import { Accordion, Button, Col, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../Global/FaIcon';
import Ads from '../Global/Ads';
import { TBMessageIcon } from './TopBar/TBMessageDropdown';
import NextEvent from './Sidebar/NextEvent';

const SidebarLeft = () => {
    return (
        <SidebarMenu className="d-none d-md-block " />
    )
};
const SidebarMenu = ({ className, handleClose = () => { } }) => {
    return (
        <>
            <Row className="d-block d-sm-block d-md-none">
                <Col xs={12} style={{paddingLeft: "26px"}} className="mb-3">
                    <Link to="/myprofile" className="d-block d-sm-block d-md-none" onClick={handleClose}><FaIcon type="light" icon="user" size="2x"/></Link>
                </Col>
                <hr/>
            </Row>
            <ListGroup className={`sl_listGroup ${className}`}>
                <ListGroup.Item onClick={handleClose} as={Link} to="/"><FaIcon type="light" icon="home" /> Főoldal </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Link} to="/map"><FaIcon type="light" icon="map" /> Térkép </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Link} to="/qso/add"><FaIcon type="light" icon="plus" /> Új QSO log </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Link} to="/qso/list"><FaIcon type="light" icon="list" /> QSO logok </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Link} to="/qso/overview"><FaIcon type="light" icon="list" /> Kapott QSO-k listája </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Accordion}>
                    <Accordion.Header><FaIcon type="light" icon="calculator-simple" />&nbsp;Kalkulátorok</Accordion.Header>
                    <Accordion.Body as={ListGroup}>
                        <ListGroup.Item onClick={handleClose} as={Link} to="/calculators/yagi-calculator"><FaIcon type="light" icon="tower-cell" /> Yagi antenna</ListGroup.Item>
                    </Accordion.Body>
                </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Accordion}>
                    <Accordion.Header><FaIcon type="light" icon="wave-sine" />&nbsp;Frekvenciák</Accordion.Header>
                    <Accordion.Body as={ListGroup}>
                        <ListGroup.Item onClick={handleClose} as={Link} to="/freqs/pmr">PMR</ListGroup.Item>
                        <ListGroup.Item onClick={handleClose} as={Link} to="/freqs/gumiful">Gumifül</ListGroup.Item>
                    </Accordion.Body>
                </ListGroup.Item>
                <ListGroup.Item onClick={handleClose} as={Link} to="/downloads"><FaIcon type="light" icon="download" /> Letöltések </ListGroup.Item>
            </ListGroup>
            <hr/>
            <NextEvent />
            <hr/>
            <Ads />
        </>
    );
}
export { SidebarMenu };
export default SidebarLeft;