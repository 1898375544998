import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, ListGroup, ListGroupItem, Modal, ProgressBar, Row } from 'react-bootstrap';
import { db } from '../../Utils/Firebase';
import DonateModal from '../../Widgets/Dashboard/DonateModal';
import { toast } from 'react-toastify';
import { __getDateFromStamp } from '../../Utils/TimeFuncs';
import Ads from '../../Widgets/Global/Ads';

const Donate = () => {
    const [d, setD] = useState([]);
    const [donators, setDonators] = useState([]);
    const [modal, setModal] = useState(false);

    const fetchDonate = async () => {
        const donateDoc = await getDoc(doc(db, "donate", "donate"));
        setD(donateDoc.data());
    };
    const fetchDonators = async () => {
        setDonators([]);
        const donatorsColl = await getDocs(collection(db, "donate", "donate", "donators"));
        if (donatorsColl.docs.length > 0) {
            for (const donator of donatorsColl.docs) {
                setDonators((prev) => [...prev, donator.data()])
            };
        } else {
            setDonators((prev) => [...prev, { caption: "Még nem támogatta az oldalt senki!" }]);
        };
    }
    useEffect(() => {
        fetchDonate();
        fetchDonators();
    }, []);

    const donateCallback = (event) => {
        if (event === "cancel") { setModal(false); };
        if (event === "success") {
            toast.success("Köszönjük a támogatást!");
            setModal(false);
            fetchDonate();
            fetchDonators();
        };
    }

    return (
        <>
            <Container className="feedItem mb-3">
                <Row className="mb-2">
                    <Col md={12} style={{ position: 'relative' }}>
                        <h2 class="title">Támogatás</h2>
                        <Button style={{ position: 'absolute', top: '0px', right: '0px' }} variant="dark" onClick={() => setModal(true)}>
                            Támogatás
                        </Button>
                        <DonateModal show={modal} callback={donateCallback} />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={12} className="text-center">
                        <b>{(d.currentAmount + d.adsAmount)} Ft / {d.goalAmount} Ft</b>
                        <ProgressBar className="mb-2">
                            <ProgressBar key="donation" min={0} max={d.goalAmount} now={d.currentAmount}></ProgressBar>
                            <ProgressBar variant="warning" key="donation" min={0} max={d.goalAmount} now={d.adsAmount}></ProgressBar>
                        </ProgressBar>
                        <Row>
                            <Col md={6} style={{ textAlign: "left" }}>
                                <div className="bg-primary" style={{ width: "16px", height: "16px", borderRadius: "8px", float: "left", position: "relative", top: "3px", left: "0px", marginRight: "5px" }}></div>
                                Támogatás: <b>{d.currentAmount} Ft</b>
                            </Col>
                            <Col md={6} style={{ textAlign: "left" }}>
                                <div className="bg-warning" style={{ width: "16px", height: "16px", borderRadius: "8px", float: "left", position: "relative", top: "3px", left: "0px", marginRight: "5px" }}></div>
                                Reklámbevétel: <b>{d.adsAmount}<sup>*</sup> Ft</b>
                            </Col>
                        </Row>
                        <hr />
                        <b>{d.goalTitle}</b><br />
                        <p dangerouslySetInnerHTML={{ __html: d.goalDescription }}></p>
                        <hr />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={12}>
                        <h5 class="title">Támogatók</h5>
                        <ListGroup className="dark">
                            {(donators.sort((a, b) => b.amount - a.amount).map((item, index) => {
                                return (
                                    <ListGroup.Item>
                                        {(item.caption) && (<center>{item.caption}</center>)}
                                        {(!item.caption) && (
                                            <Row>
                                                <Col md={4}>{(item.isPublic) ? item.username : "****"}</Col>
                                                <Col md={4}>{item.amount} Ft</Col>
                                                <Col md={4}>{__getDateFromStamp(parseInt(item.time))}</Col>
                                            </Row>
                                        )}
                                    </ListGroup.Item>
                                );
                            }))}
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <hr />
                        <sup>*</sup>: Havonta frissül.
                    </Col>
                </Row>
            </Container>
            <Container className="feedItem">
                <Row>
                    <Col md={12}>
                        <Ads />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Donate;