export const validateLoginForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az e-mail cím mező kitöltése kötelező";
    }

    if (!payload || typeof payload.password !== "string" || payload.password.trim().length < 8) {
        isFormValid = false;
        if(payload.password.trim().length === 0){
            errors.password = "A jelszó mező kitöltése kötelező";
        }else{
            errors.password = "A jelszónak legalább 8 karakterből kell állnia";
        };
    }

    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
}

export const validateRegisterForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az e-mail cím mező kitöltése kötelező";
    }

    if (!payload || typeof payload.password !== "string" || payload.password.trim().length < 8) {
        isFormValid = false;
        if(payload.password.trim().length === 0){
            errors.password = "A jelszó mező kitöltése kötelező";
        }else{
            errors.password = "A jelszónak legalább 8 karakterből kell állnia";
        };
    }

    if (!payload || typeof payload.password_rep !== "string" || payload.password_rep.trim().length < 8) {
        isFormValid = false;
        if(payload.password_rep.trim().length === 0){
            errors.password_rep = "A jelszó mező kitöltése kötelező";
        }else{
            errors.password_rep = "A jelszónak legalább 8 karakterből kell állnia";
        };
    }

    if(!payload || payload.password !== payload.password_rep){
        isFormValid = false;
        errors.password = "A jelszavak nem egyeznek!";
        errors.password_rep = "A jelszavak nem egyeznek!";
    }

    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
}