import React, { useRef, useState } from 'react';
import { Alert, Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import FormErrorMessage from '../../Widgets/Global/FormErrorMessage';
import { auth, db, firebaseErrors } from '../../Utils/Firebase';
import { validateLoginForm } from '../../Utils/FormValidation';
import { useUsers } from '../../Contexts/UserContext';
import { __getTimeStamp } from '../../Utils/TimeFuncs';

const Register = () => {
    const { createUser } = useUsers();
    const navigate = useNavigate(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordRepRef = useRef(null);
    const [fields, setFields] = useState({ email: '', password: '', password_rep: '' });
    const [errors, setErrors] = useState({ email: false, password: false, message: false, variant: 'danger' });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors((prev) => ({...prev, [name]: false}));
        setFields((prev) => ({...prev, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var payload = validateLoginForm(fields);
        if(payload.success){
            createUserWithEmailAndPassword(auth, fields.email, fields.password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    await sendEmailVerification(user);
                    createUser(user.uid, {
                        avatar: "/images/noavatar.png",
                        email: fields.email,
                        enabled2fa: false,
                        lastAction: __getTimeStamp(),
                        perm: 1,
                        uid: user.uid,
                        username: fields.email.split('@')[0],
                        provider: "Email"
                    });
                    setErrors({message: "Sikeres regisztráció. Hamarosan kiküldünk egy aktiváló levelet!", variant: "success"});
                    setFields({email: "",password: "", password_rep: ""});
                    auth.signOut();
                }).catch((err) => {
                    console.log(err);
                    setErrors({message: firebaseErrors[err.code], variant:"danger"});
                })
        }else{
            setErrors(payload.errors);
            setErrors((prev) => ({ ...prev, message: payload.message, variant: "danger" }));
        }
    }

    return (
        <Row className="justify-content-center">
            <Col md={{ span: 6, offset: 0 }}>
                <Form onSubmit={handleSubmit}>
                    {(errors?.message) && ( <Alert variant={errors?.variant}>{errors?.message}</Alert> )}
                    <FloatingLabel controlId='email' label="E-mail cím" className="mb-3">
                        <Form.Control ref={emailRef} className={(errors?.email) ? `is-invalid` : ``} type="email" placeholder="E-mail cím" name="email" value={fields.email} onChange={handleChange}/>
                        <FormErrorMessage error={errors?.email} target={emailRef}/>
                    </FloatingLabel>
                    <FloatingLabel controlId='password' label="Jelszó" className="mb-3">
                        <Form.Control ref={passwordRef} className={(errors?.password) ? `is-invalid` : ``} type="password" placeholder="Jelszó" name="password" value={fields.password} onChange={handleChange}/>
                        <FormErrorMessage error={errors?.password} target={passwordRef}/>
                    </FloatingLabel>
                    <FloatingLabel controlId='password_rep' label="Jelszó megerősítése" className="mb-3">
                        <Form.Control ref={passwordRepRef} className={(errors?.password_rep) ? `is-invalid` : ``} type="password" placeholder="Jelszó megerősítése" name="password_rep" value={fields.password_rep} onChange={handleChange}/>
                        <FormErrorMessage error={errors?.password_rep} target={passwordRepRef}/>
                    </FloatingLabel>
                    <Button variant="success" type="submit" className="mb-3 d-block w-100"> Fiók létrehozása </Button>
                    <hr />
                    <Button variant="dark" style={{ marginRight: '5px' }} as={Link} to="/auth"> Belépés </Button>
                </Form>
            </Col>
        </Row>
    );
};

export default Register;