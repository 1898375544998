import React, { useEffect, useState } from "react";
import { Card, Placeholder } from "react-bootstrap";

const isYouTubeVideo = (url) => {
    url = url.replace("</p>", "");
    const regex = /(https?:\/\/)?(www\.)?(youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([A-Za-z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[4] : null
};

const fetchURLMeta = async (url) => {
    const response = await fetch(`https://api.mailxto.com/api/get-meta-tags?url=${url}`);
    const data = await response.json();
    const domain = url.split('/');
    const card = `
        <div class="card">
            <div class="row">
                <div class="col-lg-4 col-md-4 d-none d-sm-none d-md-block">
                    <img src="${domain[0]}//${domain[2]}/${data.favicon}" class="w-100 h-100 img-fluid rounded-start" alt="Favicon">
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">${data.title}</h5>
                        <p class="card-text">${data.meta_tags.description}</p>
                        <p class="card-text"><a href="${data.url}" target="_blank" class="text-muted stretched-link">${data.url}</a></p>
                    </div>
                </div>
            </div>
        </div>
        `;
    return card;
}

const formatText = async (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    const processedParts = await Promise.all(parts.map(async (part) => {
        if (isYouTubeVideo(part)) {
            const videoId = part.split("v=")[1].split("&")[0].replace("</p>", "");
            return `<iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/${videoId}"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>`;
        } else if (part.match(/https?:\/\/[^\s]+/)) {
            const data = fetchURLMeta(part.replace("</p>", ""));
            return data;
        } else {
            return part;
        }
    }));

    return processedParts.join('');
};

const TextWithLinks = ({ text }) => {
    const [content, setContent] = useState(false);
    useEffect(() => { 
        const fetchContent = async () => { 
            const content = await formatText(text);
            setContent(content); 
        };
        fetchContent();    
    }, [text]);
    
    return (
        <>
        {(!content) && (
            <>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /><Placeholder xs={4} /><Placeholder xs={4} />
                </Placeholder>
            </>
        )}
        {(content) && ( <div dangerouslySetInnerHTML={{ __html: content }}></div> )}
        </>
    );
}
export default TextWithLinks;