import React, { useRef, useState } from 'react';
import { useUsers } from '../../../Contexts/UserContext';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { __getTimeStamp } from '../../../Utils/TimeFuncs';
import { Editor } from '@tinymce/tinymce-react';
import FaIcon from '../../Global/FaIcon';
import { Col, Form, Row, Button, Modal, Dropdown } from 'react-bootstrap';
import TBUserPicture from '../TopBar/TBUserPicture';
import { checkImageIsAllowed } from '../../../Utils/Utils';

const CreateFeedItem = ({ target = null }) => {
    const { user } = useUsers();
    const editorRef = useRef(null);
    const [show, setShow] = useState(false);
    const [postMode, setPostMode] = useState("private");
    const [disabled, setDisabled] = useState(true);
    const [attVisible, setAttVisible] = useState(false);
    const [atts, setAtts] = useState([]);

    const handleKeyUp = () => {
        let length = editorRef.current.getContent().length;
        setDisabled((length === 0) ? true : false);
    }
    const handleModeChange = (target) => {
        setPostMode(target);
    }
    const submitPost = async () => {
        const docRef = collection(db, "posts");
        await addDoc(docRef, {
            uid: user.uid,
            target_uid: target,
            visibility: postMode,
            content: editorRef.current.getContent(),
            timestamp: __getTimeStamp(),
            atts: (atts.length > 0) ? atts : null
        });
        editorRef.current.setContent("");
        setPostMode("private");
        setShow(false);
    }

    const setAttachment = () => {
        const input = document.getElementById('post_att');
        input.click();
    }
    const addAttachment = (event) => {
        const file = event.target.files[0];
        if(file && checkImageIsAllowed(file)){
            const reader = new FileReader();
            reader.onloadend = () => { setAtts((prev) => [...prev, reader.result]) };
            reader.readAsDataURL(file);
        };
    }
    const deleteAtt = (content) => { setAtts((prev) => prev.filter((image) => image !== content)); }

    return (
        <>
            <Row className='mb-3'>
                <Col md={12} className="feedItem">
                    <Row>
                        <Col md={1} xs={2}><TBUserPicture user={user} showName={false} w={38} h={38} /> </Col>
                        <Col md={11} xs={10}><Form.Control className="dark" type="text" name="post" onClick={() => { setShow(!show); }} placeholder="Bejegyzés létrehozása" /></Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={show}>
                <Modal.Body>
                    <Button onClick={() => { setShow(!show); }} variant="dark" style={{ position: 'absolute', top: '23px', right: '15px', zIndex: 9 }}>
                        <FaIcon type="light" icon="times" />
                    </Button>
                    <Dropdown className="postDropDown" style={{ position: 'absolute', top: '23px', right: '50px', zIndex: 9 }}>
                        <Dropdown.Toggle variant="dark" id="postMode"><span dangerouslySetInnerHTML={{ __html: postModes[postMode] }}></span></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item variant="dark" as={Button} onClick={() => { handleModeChange('public') }}><FaIcon type="light" icon="globe" /> Publikus</Dropdown.Item>
                            <Dropdown.Item variant="dark" as={Button} onClick={() => { handleModeChange('private') }}><FaIcon type="light" icon="lock" /> Privát</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button onClick={() => { setAttachment(); }} variant="dark" style={{ position: 'absolute', top: '23px', left: '90px', zIndex: 9 }}>
                        <FaIcon type="light" icon="image" />
                    </Button>  
                    <Form.Control type="file" id="post_att" className="d-none dark" onChange={addAttachment}/>                  
                    <Editor
                        apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                        onInit={(_evt, editor) => editorRef.current = editor}
                        onKeyUp={handleKeyUp}
                        init={{
                            skin: "oxide-dark",
                            content_css: "dark",
                            icon: "small",
                            menubar: false,
                            toolbar: 'bold italic'
                        }}
                    />
                    <Row className="mb-3">
                        <Col md={12} style={{display:"flex"}}>
                            {(atts?.map((content, index) => {
                                return (
                                    <div className="attImageFrame" style={{position:"relative"}}>
                                        <img className="attImage" src={content} style={{width:"64px",height:"64px",borderRadius:"5px",marginLeft:"2px",marginRight:"2px"}} alt={`att-${index}`} />
                                        <div className="attDeleteButton" style={{position:"absolute",top:"0px",right:"10px",zIndex:99,cursor:"pointer"}} onClick={() => deleteAtt(content)}>
                                            <FaIcon type="light" icon="times" />
                                        </div>
                                    </div>
                                )
                            }))}
                        </Col>
                    </Row>
                    
                    <Button variant="dark-2" className="d-block w-100" disabled={disabled} onClick={submitPost}>Tovább</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

const postModes = {
    "private": '<i class="fa-light fa-lock"></i>',
    "public": '<i class="fa-light fa-globe"></i>',
}

export default CreateFeedItem;