import { addDoc, collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../../../Utils/Firebase';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import FaIcon from '../../../Widgets/Global/FaIcon';
import { useUsers } from '../../../Contexts/UserContext';
import { Editor } from '@tinymce/tinymce-react';
import { Bounce, toast } from 'react-toastify';

const DLListFiles = () => {
    const { user } = useUsers();
    const { cat } = useParams();
    const [category, setCategory] = useState([]);
    const [files, setFiles] = useState([]);
    
    const fetchFiles = async () => {
        setFiles([]);
        setCategory([]);
        //Fetch category
        const catDoc = await getDoc(doc(db, "downloads", cat));
        setCategory({ id: catDoc.id, ...catDoc.data() });
        //Fetch files
        const filesRef = collection(db, "downloads", cat, "files");
        const fileDocs = await getDocs(filesRef);
        for (const file of fileDocs.docs) {
            setFiles((prev) => [...prev, { id: file.id, ...file.data() }])
        };
    };
    useEffect(() => {
        fetchFiles();
    }, []);

    /*Admin*/
    const editorRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [fields, setFields] = useState({ name: '', path: '' });
    const onChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    }
    const create = async () => {
        const args = {
            name: fields.name,
            description: editorRef.current.getContent(),
            path: fields.path,
            views: 0,
            downloads: 0
        };
        await addDoc(collection(db, "downloads", cat, "files"), args);
        toast.success('Letöltés létrehozva', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce
        });
        setFields({name:'',path:''});
        editorRef.current.setContent('');
        setModal(false);
        fetchFiles();
    };
    return (
        <>
            <Row className="mb-3">
                <Col md={3}>
                    <Button as={Link} to="/downloads" variant="dark"><FaIcon type="light" icon="download" /> Vissza </Button>
                </Col>
                <Col md={6}>
                    <h3 class="title">{category?.name}</h3>
                </Col>
                {(user?.perm >= 97) && (<Col md={3} className="text-right">
                    <Button variant="dark" onClick={() => setModal(!modal)}><FaIcon type="light" icon="plus" /> Új </Button>
                    <Modal show={modal} onHide={() => setModal(!modal)}>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" className="dark" name="name" value={fields.name} onChange={onChange} placeholder="Letöltés neve" />
                            </InputGroup>
                            <Editor
                                apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz"
                                onInit={(_evt, editor) => editorRef.current = editor}
                                init={{
                                    skin: "oxide-dark",
                                    content_css: "dark",
                                    icon: "small",
                                    menubar: false,
                                    toolbar: 'bold italic'
                                }}
                            />
                            <InputGroup className="mb-3">
                                <Form.Control type="text" className="dark" name="path" value={fields.path} onChange={onChange} placeholder="URL" />
                            </InputGroup>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setModal(!modal)}>
                                <FaIcon type="light" icon="times" /> Mégse
                            </Button>
                            <Button variant="success" onClick={create}>
                                <FaIcon type="light" icon="save" /> Mentés
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>)}
            </Row>
            <Row>
                {(files.map((item, index) => (
                    <Col md={6}>
                        <Card>
                            <Card.Body className="text-center">
                                <b>{item.name}</b><br />
                                <span className="small" dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                <Link to={`/downloads/${category.id}/${item.id}`} className="stretched-link"></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                )))}
            </Row>
        </>
    );
};

export default DLListFiles;
