import React, { useState, createContext, useContext, useEffect } from 'react';
import { useUsers } from './UserContext';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../Utils/Firebase';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const { user, saveActivity } = useUsers();
    const [openedChats, setOpenedChats] = useState([]);
    const [messageCount, setMessageCount] = useState(0);
    const [chats, setChats] = useState([]);

    const openChat = (user) => {
        if (!openedChats.includes(user)) {
            saveActivity();
            setOpenedChats([...openedChats, user]);
        };
    };
    const closeChat = (user) => {
        saveActivity();
        setOpenedChats(openedChats.filter((chat) => chat !== user)); // Bezárjuk a chatablakot
    };

    useEffect(() => {
        if(!user.uid) { return };
        setMessageCount(0);
        const chatroomsRef = collection(db, 'chatRooms');
        const q = query(chatroomsRef, where('persons', 'array-contains', user.uid));
        const unsubscribe = onSnapshot(q, (snapshot) => { 
            snapshot.forEach((doc) => {
                handleChatroom(doc); 
            });
        });   
        
        const handleChatroom = (doc) => {
            const chatroomID = doc.id;
            const messagesRef = collection(db, `chatRooms/${chatroomID}/messages`);
            const msgQ = query(messagesRef, orderBy("timestamp","desc"));
            const unsubscribeMessages = onSnapshot(msgQ, (messagesSnapshot) => {
                let newMessageCount = 0;
                messagesSnapshot.docChanges().forEach((change) => {
                    const messageData = change.doc.data();
                    if (change.type === 'added' && messageData.unReaded === true && messageData.sender !== user.uid) { newMessageCount++; }
                });
                setMessageCount(newMessageCount);

                if(messagesSnapshot.docs.length > 0){
                    messagesSnapshot.docs.sort((a,b) => (b.timestamp - a.timestamp)).slice(0,1).map((msg) => {
                        let item = { room: {id: doc.id, ...doc.data()}, last: {id: msg.id, ...msg.data()}}
                        setChats((prevChats) => {
                            const existingIndex = prevChats.findIndex(chat => chat.room.id === item.room.id);
                            if(existingIndex !== -1){
                                const updatedChats = [...prevChats];
                                updatedChats[existingIndex].last = item.last;
                                return updatedChats;
                            }else{
                                return [...prevChats, item];
                            };
                        });
                    });
                };
            });
            return () => unsubscribeMessages();
        };

        return () => { unsubscribe(); }
    },[user.uid]);

    return (
        <ChatContext.Provider value={{messageCount, chats, openedChats, openChat, closeChat }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = () => useContext(ChatContext);