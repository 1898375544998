import React from 'react';
import PubHero from '../Widgets/Landing/PubHero';
import PubIsmerteto from '../Widgets/Landing/PubIsmerteto';
import PubWhy from '../Widgets/Landing/PubWhy';
import PubRegister from '../Widgets/Landing/PubRegister';
import Ads from '../Widgets/Global/Ads';
import Footer from '../Widgets/Landing/Footer';

const Landing = () => {
    return (
        <div className="public">
            <PubHero />
            <main className="container">
                <PubIsmerteto />
                <Ads />
                <PubWhy />
                <PubRegister />
                <Ads />
            </main>
            <Footer />
        </div>
    );
};

export default Landing;