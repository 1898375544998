import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import FaIcon from '../../Global/FaIcon';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import TBUserPicture from '../TopBar/TBUserPicture';
import { __getTimeStamp, getDiff, getDiffInMinutes, getOnlineDiff } from '../../../Utils/TimeFuncs';
import { useUsers } from '../../../Contexts/UserContext';
import { useChat } from '../../../Contexts/ChatContext';
import ChatApp from '../Chat/ChatApp';

const OnlineUsers = () => {
    const { user } = useUsers();
    const { openChat } = useChat();

    const [recent, setRecent] = useState([]);

    useEffect(() => {
        const now = __getTimeStamp();
        const oneWeekAgo = now - 7 * 24 * 60 * 60;
        const userRef = collection(db, "users");
        const recentUserQuery = query(userRef, where("uid", "!=", user.uid), where("lastAction", ">=", oneWeekAgo), orderBy("lastAction", "desc"));
        const unsubscribe = onSnapshot(recentUserQuery, (snapshot) => {
            const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setRecent(users)
        });
        return () => unsubscribe();
    }, []);

    /*Search*/
    const [ needle, setNeedle ] = useState("");

    return (
        <>
            <Row className="mb-3">
                <Col md={7}>
                    {(needle === "") && (<>Belépett tagok</>)}
                    {(needle !== "") && (<>Keresés: {needle}</>)}
                </Col>
                <Col md={5} className="text-right">
                    <ButtonGroup>
                    {(needle !== "") && (
                        <Button variant="dark" id="online-user-search" className="clear-filter" onClick={(e) => setNeedle("") }><FaIcon type="light" icon="times" /></Button>
                    )}
                    <Dropdown>
                        <Dropdown.Toggle variant="dark" id="online-user-search">
                            <FaIcon type="light" icon="search" style={{ marginRight: '10px' }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <InputGroup>
                                <Form.Control type="text" name="needle" value={needle} onChange={(e) => setNeedle(e.target.value)} className="dark" placeholder={`Keresés`}/>
                            </InputGroup>
                        </Dropdown.Menu>
                    </Dropdown>                    
                    {/*<FaIcon type="light" icon="ellipsis" style={{ marginRight: '10px' }} />*/}
                    </ButtonGroup>
                </Col>
            </Row>
            <ListGroup>
                {(recent
                    .filter((item) => { if(needle === "") { return true; }; return item.username.toLowerCase().includes(needle.toLocaleLowerCase())})
                    .slice(0, 20)
                    .map((online, index) => (
                    <ListGroup.Item key={index} className="dark userListItem" onClick={() => { openChat(online.uid); }}>
                        <Row className="mb-1">
                            <Col lg={1} md={1} style={{ position: "relative" }}>
                                <TBUserPicture user={online} w={45} h={45}/>
                                {(getDiffInMinutes(online.lastAction) <= 5) && (<OnlineMarker state="online" />)}
                                {(getDiffInMinutes(online.lastAction) > 6) && (getDiffInMinutes(online.lastAction) <= 15) && (<OnlineMarker state="leaved" />)}
                                {(getDiffInMinutes(online.lastAction) > 16) && (<OnlineMarker state="offline" />)}
                            </Col>
                            <Col lg={10} md={10} className="userOnlineRow">
                                {online.username}<br />
                                <div className="small" style={{ position: 'relative', top: "-2px" }}>{getOnlineDiff(online.lastAction)}</div>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )))}
            </ListGroup>
            
        </>
    );
};
export default OnlineUsers;

const OnlineMarker = ({ state }) => {
    return (
        <div className={`point ${state}`}></div>
    )
}
export { OnlineMarker };