import React from 'react';
import FaIcon from '../Global/FaIcon';
import { motion } from 'framer-motion';

const PubWhy = () => {
    return (
        <section id="content-2" className="row mb-3">
			<div className="col-md-12 mb-5">
				<motion.h3 initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.0, duration: 2 }}} className="ctx-title text-center mb-3">Miért érdemes csatlakozni hozzánk?</motion.h3>
				<div className="row">
					<motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 2 }}} className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
								<FaIcon type="solid" icon="check-circle" size="3x" className="text-success mb-3" />
								<h4 className="card-title mb-3">Fejlett Logbook</h4>
								<p className="ctx-text">
									Egyedülálló log-rendszerünk segítségével akkor is tudsz rögzíteni QSO logot, ha épp nem vagy online. A rendszer rögzíti a logjaid, 
									és szinkronizáld fel akkor, amikor újra online vagy!
								</p>
							</div>
						</div>
					</motion.div>
					<motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 2 }}} className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
                                <FaIcon type="light" icon="chart-network" size="3x" className="text-success mb-3" />
								<h4 className="card-title mb-3">Kiterjedt hálózat</h4>
								<p className="ctx-text">
									Rádiózz, vagy üzenj társadnak, hogy szeretnél összeköttetést létesíteni! Ehhez segítségül tudod hívni a <i>Közösség</i> aloldalunkat, 
									ahol, akár SMS üzenetben is tudod értesíteni a társaid!
								</p>
							</div>
						</div>
					</motion.div>
					<motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.6, duration: 2 }}} className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
                                <FaIcon type="light" icon="award" size="3x" className="text-success mb-3" />
								<h4 className="card-title mb-3">Versenyek és Díjak</h4>
								<p className="ctx-text">
									Tervezünk versenyeket létrehozni, ahol <i>nem mindig az összeköttetések száma</i> a mérvadó. Gyere, és próbáld ki magad. 
									Ha <b>nincs rádiód</b>, mi <b>segítünk</b> kiválasztani egyet, és még Kedvezményeket<sup>2</sup> is szerezhetsz partnereinknél.
								</p>
							</div>
						</div>
					</motion.div>
					<motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.8, duration: 2 }}} className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
                                <FaIcon type="regular" icon="diploma" size="3x" className="text-success mb-3" />
								<h4 className="card-title mb-3">Oktatás és fejlődés</h4>
								<p className="ctx-text">
									Az <b>1x1</b> oldalunkon összegyűjtjük azokat a legfontosabb és rádiózáshoz kapcsolódó anyagokat,
									amely segítenek Neked az összeköttetések létrehozásában, de <i>akár</i> egy NMHH vizsgához is hozzájárulhatnak!
								</p>
							</div>
						</div>
					</motion.div>
				</div>
			</div>						
		</section>
    );
};

export default PubWhy;