import React, { useEffect, useState } from 'react';
import IconSet from './Icons';
import { Marker } from 'react-leaflet';
import { isDesktop, isMobile } from 'react-device-detect';
import { Button, ButtonGroup, Container, Modal, Offcanvas, Row } from 'react-bootstrap';
import TimeLine from './TimeLine';
import { __getTimeStamp, formatDate, getDiff } from '../../../Utils/TimeFuncs';
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { useUsers } from '../../../Contexts/UserContext';
import FaIcon from '../../Global/FaIcon';
import ParrotModal from './Modals/ParrotModal';
import HandradioModal from './Modals/HandRadioModal';
import DeskradioModal from './Modals/DeskRadioModal';
import TowerModal from './Modals/TowerModal';

const MarkerCombined = ({ args, eventAction, callback, children }) => {
    const { user } = useUsers();

    const [details, setDetails] = useState({
        id: -1,
        title: '',
        type: '',
        pos: { lat: 0.0, lon: 0.0 },
        freq: { up: 0.0, down: 0.0 },
        code: { ctcs: 0.0, dcs: '' },
        description: '',
        timeline: [],
        lwr: '',
        owner: null
    });
    const [icon, setIcon] = useState(IconSet['default']);
    const [modal, setModal] = useState(false);
    const [offCanvas, setOffCanvas] = useState(false);

    const getTimeline = async () => {
        let tArr;
        try {
            const TimeLineRef = collection(db, "markers", args.id, "timeline");
            const q = query(TimeLineRef, orderBy('timestamp', 'desc'));
            const docs = await getDocs(q);
            tArr = docs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            return tArr;
        } catch (e) { return false; };
    }
    const getLastWorkReport = async () => {
        const TimeLineRef = collection(db, "markers", args.id, "timeline");
        const q = query(TimeLineRef, where("work","==",true),orderBy('timestamp', 'desc'),limit(1));
        const docs = await getDocs(q);
        if(docs.docs.length > 0){
            const tArr = docs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            let ret = `${getDiff(tArr[0].timestamp)}`;        
            return ret;
        }else return [];
    }

    const init = async () => {
        let d = details;
        d['id'] = args.id;
        d['type'] = args.type;
        d['pos']['lat'] = args.lat;
        d['pos']['lon'] = args.lon
        d['title'] = args.title;
        d['description'] = args.description;
        d['freq'] = { up: args.upstream_freq, down: args.downstream_freq };
        d['code'] = { ctcs: args.ctcs, dcs: args.dcs };
        d['lwr'] = args.__lastWorkReport;
        d['owner'] = args.owner || -1;
        setIcon(IconSet[args.type]);
        if (args.type === "parrot") {
            setIcon((prev) => (args.isWorking) ? IconSet["parrot_a"] : IconSet["parrot_i"]);
            d['freq'] = args.freq;
            const tline = await getTimeline();
            d['timeline'] = tline;
            const lastWorkReport = await getLastWorkReport();
            d['lwr'] = lastWorkReport;
        };
        setDetails(d);
    }

    useEffect(() => { init(); }, []);
    
    useEffect(() => {
        //console.log(details);
    },[details]);

    /*events*/
    const markerClick = (_marker) => {
        switch (_marker.originalEvent.button) {
            case -1:
                eventAction('markerContextMenu');
                break;
            case 0:
                if (isMobile) { modalShow(true); }
                else if (isDesktop) { canvasShow(true); }
                break;
            case 2:
                console.log("desktop::contextmenu");
                break;
            default: break;
        }
    }
    const modalShow = (_s) => { setModal(_s); }
    const canvasShow = (_s) => { setOffCanvas(_s); }

    //Callback || Feedback
    const markerFeedback = (id, state) => {
        const markerRef = doc(db, "markers", id);
        updateDoc(markerRef, { isWorking: state });
        if (details.type === "parrot") { setIcon((prev) => state ? IconSet['parrot_a'] : IconSet['parrot_i']); };
        let item = { timestamp: __getTimeStamp(), work: state, uid: user.uid };
        let d = details;
        d.timeline.push(item);
        setDetails(d);        
        addDoc(collection(db, "markers", id, "timeline"), item);
        callback('parrot');
        setOffCanvas(false);
    }

    //EditClick
    const [parrot, setParrot] = useState(false);
    const [handradio, setHandradio] = useState(false);
    const [deskradio, setDeskradio] = useState(false);
    const [tower, setTower] = useState(false);
    const editClick = () => { 
        console.log(details.type)
        switch(details.type){
            case "parrot": setParrot(true); break;
            case "handradio": setHandradio(true); break;
            case "deskradio": setDeskradio(true); break;
            case "tower": setTower(true); break;

            default: break;
        }
    }
    const editCallback = async (e) => {
        setParrot(false);
        setHandradio(false);
        setDeskradio(false);
        setTower(false);
        const docRef = await getDoc(doc(db, "markers", args.id));
        const updatedData = docRef.data();
        let d = details;
        d['id'] = args.id;
        d['type'] = updatedData.type;
        d['pos']['lat'] = updatedData.lat;
        d['pos']['lon'] = updatedData.lon
        d['title'] = updatedData.title;
        d['description'] = updatedData.description;
        d['freq'] = { up: updatedData.upstream_freq, down: updatedData.downstream_freq };
        d['code'] = { ctcs: updatedData.ctcs, dcs: updatedData.dcs };
        d['lwr'] = updatedData.__lastWorkReport;
        d['owner'] = updatedData.owner || -1;
        setIcon(IconSet[updatedData.type]);
        if (updatedData.type === "parrot") {
            setIcon((prev) => (updatedData.isWorking) ? IconSet["parrot_a"] : IconSet["parrot_i"]);
            d['freq'] = updatedData.freq;
            const tline = await getTimeline();
            d['timeline'] = tline;
            const lastWorkReport = await getLastWorkReport();
            d['lwr'] = lastWorkReport;
        };
        setDetails(d);
    }

    const claimMarker = async () => {
        await updateDoc(doc(db,"markers", args.id), { owner: user.uid });
        let d = details;
        d['owner'] = user.uid;
        setDetails(d);
        callback('parrot');
    }

    return (
        <>
            <Marker
                key={details.id}
                position={[details?.pos?.lat, details?.pos?.lon]}
                icon={icon}
                eventHandlers={{
                    click: (e) => { markerClick(e) },
                    contextmenu: (e) => { markerClick(e) }
                }}
            >
                {children && children}
            </Marker>

            <Offcanvas show={offCanvas} onHide={() => { canvasShow(false); }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{details.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {(details?.type === "handradio" || details?.type === "deskradio") && (<div dangerouslySetInnerHTML={{ __html: details.description }} />)}
                    {(details?.type === "parrot") && (
                        <>
                            <div>
                                Frekvencia: <b>{details.freq} MHz</b><br />
                                {(details.code?.ctcs !== null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br /></>)}
                                Utolsó online report: <b>{details.lwr}</b><br/><br/>
                                <span dangerouslySetInnerHTML={{ __html: details.description}}></span>
                                <hr />
                                <ButtonGroup>
                                    <Button variant="danger" onClick={() => { markerFeedback(details.id, false); }}> Nem működik </Button>
                                    <Button variant="success" onClick={() => { markerFeedback(details.id, true); }}> Működik </Button>
                                </ButtonGroup>
                                <hr />
                                <Container>
                                    <Row>
                                        <ul className="timeline-3">
                                            {details.timeline.slice(0, 10).map((item, index) => (<TimeLine args={item} key={index} />))}
                                        </ul>
                                    </Row>
                                </Container>
                                
                            </div>
                        </>
                    )}
                    {(details?.type === "tower") && (
                        <div>
                            Felmenő: <b>{details.freq.up} MHz</b><br />
                            Lejövő: <b>{details.freq.down} MHz</b><br />
                            {(details.code?.ctcs !== null) && (<>CTCSS: <b>{details.code.ctcs} Hz</b><br /></>)}
                            {((details.code?.dcs !== null) && (details.code?.dcs !== "")) && (<>DCS: <b>{details.code.dcs}</b><br /></>)}
                            <hr />
                            <div dangerouslySetInnerHTML={{ __html: details.description }}></div>
                        </div>
                    )}
                    <hr/>
                    <ButtonGroup>
                    {(user.uid === details.owner) && (
                        <>
                            <Button variant="warning" onClick={() => {editClick(); }}><FaIcon type="light" icon="pencil" /></Button>
                            {(details.type === "parrot") && (<ParrotModal show={parrot} pos={{ lat: details.lat, lon: details.lon }} method="edit" params={details} callback={(item) => {editCallback(item)}} />)}
                            {(details.type === "handradio") && (<HandradioModal show={handradio} pos={{ lat: details.lat, lon: details.lon }} method="edit" params={details} callback={(item) => editCallback(item)} />)}
                            {(details.type === "deskradio") && (<DeskradioModal show={deskradio} pos={{ lat: details.lat, lon: details.lon }} method="edit" params={details} callback={(item) => editCallback(item)} />)}
                            {(details.type === "tower") && (<TowerModal show={tower} pos={{ lat: details.lat, lon: details.lon }} method="edit" params={details} callback={(item) => editCallback(item)} />)}
                        </>
                    )}
                    {(details.owner === -1) && (
                        <Button variant="warning" onClick={() => {claimMarker(); }}><FaIcon type="light" icon="user-plus" /></Button>
                    )}
                    </ButtonGroup>
                </Offcanvas.Body>
            </Offcanvas>

            
        </>
    );
};

export default MarkerCombined;