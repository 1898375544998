import React from 'react';

const Events = () => {
    return (
        <div className="feedItem">
            <center>Hamarosan</center>
        </div>
    );
};

export default Events;