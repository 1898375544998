import { Route, Routes } from "react-router-dom";
import Feed from "./Feed";
import Map from "../../Widgets/Dashboard/Map";
import QSOAdd from "../QSOAdd";
import QSOList from "../QSOList";
import MyProfile from "./MyProfile";
import ProfileEdit from "./ProfileEdit";
import Logout from "../Auth/Logout";
import Messenger from "./Messenger";
import Profile from "./Profile";
import QSOOverview from "../QSOOverview";
import QSOitem from "../QSOitem";
import Yagi from "../Calculators/Yagi";
import Freqs from "./Freqs";
import DLViewIndex from "./Downloads/DLViewIndex";
import DLGetFile from "./Downloads/DLGetFile";
import Donate from "./Donate";
import Event from "./Event";
import Events from "./Events";

const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<Feed />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/map" element={<Map />} />
            <Route path="/donate" element={<Donate/>} />
            <Route path="/messenger" element={<Messenger />} />
            <Route path="/qso/add" element={<QSOAdd />} />
            <Route path="/qso/list" element={<QSOList />} />
            <Route path="/qso/overview" element={<QSOOverview />} />
            <Route path="/qso/item/:id" element={<QSOitem />} />
            <Route path="/myprofile/*" element={<MyProfile />} />
            <Route path="/profile/:id/*" element={<Profile />} />
            <Route path="/editProfile/*" element={<ProfileEdit />} />
            <Route path="/calculators/yagi-calculator" element={<Yagi />} />
            <Route path="/freqs/:cat" element={<Freqs />} />
            <Route path="/downloads/*" element={<DLViewIndex />} />
            <Route path="/getFile/:file" element={<DLGetFile />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<Event />} />
        </Routes>
    )
}
export default Routing;