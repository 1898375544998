import { addDoc, collection, getDoc, getDocs } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../Utils/Firebase';
import { Alert, Button, ButtonGroup, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useUsers } from '../../Contexts/UserContext';
import FaIcon from '../../Widgets/Global/FaIcon';
import { Editor } from '@tinymce/tinymce-react';

const Freqs = () => {
    const { user } = useUsers();
    const { cat } = useParams();
    const [title, setTitle] = useState("");
    const [all, setAll] = useState([]);
    const [items, setItems] = useState([]);
    const [addModal, setAddModal] = useState(false);

    const freqRef = collection(db, "freqs", "freqs", cat);

    const fetchCat = async () => {
        setAll([]);
        const freqSnap = await getDocs(freqRef);
        let items = [];
        for (const item of freqSnap.docs) {
            setAll((prev) => [...prev, { id: item.id, ...item.data() }]);
            items.push({ id: item.id, ...item.data() });
        };
    };

    const [fields, setFields] = useState({ name: '', freq: '' });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
    }
    const saveFreq = async () => {
        await addDoc(freqRef, { name: fields.name, freq: fields.freq });
        setFields({ name: '', freq: '' });
        fetchCat();
    }

    /*Gumifül*/
    const editorRef = useRef(null);
    const [cfields, setCFields] = useState({ name: '', fdown: '', fup: '', ctype: '-', cdown: '', cup: '', city: '' });
    const handleCChange = (event) => { const { name, value } = event.target; setCFields((prev) => ({ ...prev, [name]: value })); };
    const saveCFreq = async () => {
        await addDoc(collection(db, "freqs", "freqs", "gumiful"), {
            name: cfields.name,
            city: cfields.city,
            content: editorRef.current.getContent(),
            freq: {
                down: cfields.fdown,
                up: cfields.fup
            },
            code: {
                type: cfields.ctype,
                down: cfields.cdown,
                up: cfields.cup
            }
        });
        setFields({ name: '', fdown: '', fup: '', ctype: '', cdown: '', cup: '', city: '' });
        editorRef.current.setContent('');
        setAddModal(false);
        fetchCat();
    }

    useEffect(() => { setTitle((cat === "pmr") ? "PMR" : "Gumifül"); fetchCat(); }, [cat]);

    return (
        <div className="feedItem">
            <h2 class="title">{title} frekvenciák</h2>
            {(cat === "gumiful") && (<Alert variant="info">Ez a felület még nem végleges! Köszönöm!</Alert>)}
            {(cat === "pmr") && (
                <Table className="table" variant="dark">
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Frekvencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(all.sort((a, b) => (a.freq - b.freq)).map((freq, index) => (
                            <tr>
                                <td>{freq.name}</td>
                                <td>{freq.freq} MHz</td>
                            </tr>
                        )))}
                    </tbody>
                    {(user?.perm >= 97) && (
                        <tfoot>
                            <tr>
                                <th colspan={99}>
                                    <InputGroup>
                                        <Form.Control type="text" name="name" value={fields?.name} onChange={handleChange} placeholder="Frekvencia neve" />
                                        <Form.Control type="text" name="freq" value={fields?.freq} onChange={handleChange} placeholder="Frekvencia (Mhz)" />
                                        <Button variant="dark" onClick={saveFreq}><FaIcon type="light" icon="save" /></Button>
                                    </InputGroup>
                                </th>
                            </tr>
                        </tfoot>
                    )}
                </Table>
            )}
            {(cat === "gumiful") && (
                <Table className="table" variant="dark">
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Lejövő</th>
                            <th>Felmenő</th>
                            <th>Város</th>
                            <th>Kódolás</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(all.sort((a, b) => (a.freq - b.freq)).map((freq, index) => (
                            <tr>
                                <td>{freq.name}</td>
                                <td>{freq.freq.down} MHz</td>
                                <td>{freq.freq.up} MHz</td>
                                <td>{freq.city}</td>
                                <td>
                                    {(freq.code.type !== "-") && ( <>{freq.code.type}: {freq.code.down}/{freq.code.up}</>)}
                                </td>
                            </tr>
                        )))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan={99}>
                                <ButtonGroup>
                                    {(user?.perm >= 97) && (
                                        <>
                                            <Button variant="dark" onClick={() => setAddModal(true)}><FaIcon type="light" icon="plus" /></Button>
                                            <Modal show={addModal}>
                                                <Modal.Header>
                                                    <Modal.Title>Új frekvencia hozzáadása</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control className={`dark`} type="text" name="name" id="name" value={cfields.name} onChange={handleCChange} placeholder="Név" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control className={`dark`} type="text" name="city" id="city" value={cfields.city} onChange={handleCChange} placeholder="Város / régió" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <InputGroup>
                                                            <Form.Control className={`dark`} type="text" name="fdown" id="fdown" value={cfields.fdown} onChange={handleCChange} placeholder="Lejövő frekvencia (MHz)" />
                                                            <Form.Control className={`dark`} type="text" name="fup" id="fup" value={cfields.fup} onChange={handleCChange} placeholder="Felmenő frekvencia (MHz)" />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <InputGroup>
                                                            <Form.Select className={`dark`} name="ctype" value={fields.ctype} onChange={handleCChange} placeholder="Kódolás típusa">
                                                                <option value="-">Nincs</option>
                                                                <option value="ctcss">CTCSS</option>
                                                                <option value="dcs">DCS</option>
                                                            </Form.Select>
                                                            <Form.Control className={`dark`} type="text" name="cdown" id="cdown" value={cfields.cdown} onChange={handleCChange} placeholder="Lejövő kód" />
                                                            <Form.Control className={`dark`} type="text" name="cup" id="cup" value={cfields.cup} onChange={handleCChange} placeholder="Felmenő kód" />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{skin: "oxide-dark", content_css: "dark", icon: "small", menubar: false, toolbar: 'undo redo | bold italic' }} />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button onClick={() => { setAddModal(false); }} variant="dark">Mégse</Button>
                                                    <Button onClick={() => { saveCFreq(); }} variant="dark">Mentés</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    )}
                                </ButtonGroup>
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            )}
        </div>
    );
};

export default Freqs;