import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoadingIndicator = ({ modal = true }) => {
  if (modal) {
    return (
      <div className="spinnerBG"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.75)",
          position: "fixed",
          top: "0px",
          left: "0px",
          zIndex: "10002"
        }}>
        <TailSpin color="#fff" height={100} width={100} />
      </div>
    );
  }else{
    <TailSpin color="#fff" height={100} width={100} />
  }
};

export default LoadingIndicator;