import React, { useEffect, useState } from 'react';
import { useUsers } from '../../../Contexts/UserContext';
import { getUserByID } from '../../../Utils/FirebaseFuncs';
import { Button, Col, Row } from 'react-bootstrap';
import TBUserPicture from '../TopBar/TBUserPicture';
import { getDiffInMinutes, getOnlineDiff } from '../../../Utils/TimeFuncs';
import { OnlineMarker } from '../Sidebar/OnlineUsers';
import { Link } from 'react-router-dom';
import FaIcon from '../../Global/FaIcon';

const MessengerSenderFrame = ({ chat }) => {
    const { user } = useUsers();
    const [sender, setSender] = useState([]);
    useEffect(() => {
        const fetchSender = async () => { const senderID = chat.room.persons.filter(person => person !== user.uid)[0]; const _sender = await getUserByID(senderID); setSender(_sender); };
        fetchSender();
    }, []);

    return (
        <>
            <Row>
                <Col md={{ span: 4, offset: 3 }} style={{ paddingTop: '15px', position: 'relative' }} className='text-center'>
                    <TBUserPicture user={sender} w={128} h={128} />
                    {(getDiffInMinutes(sender.lastAction) <= 5) && (<OnlineMarker state="online s-2x" />)}
                    {(getDiffInMinutes(sender.lastAction) > 6) && (getDiffInMinutes(sender.lastAction) <= 15) && (<OnlineMarker state="leaved s-2x" />)}
                    {(getDiffInMinutes(sender.lastAction) > 16) && (<OnlineMarker state="offline s-2x" />)}
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{paddingTop: '15px'}} className='text-center'>
                    <b style={{fontSize:'22px'}}>{sender.username}</b><br/>
                    {getOnlineDiff(sender.lastAction)}
                </Col>
            </Row>
            <Row>
                <Col md={{span: 4, offset: 4}} style={{paddingTop: '15px'}} className='text-center'>
                    <Button variant="dark" as={Link} to={`/profile/${sender.uid}`}><FaIcon type="light" icon="user-circle" /> Profil</Button>
                </Col>
            </Row>
        </>
    );
};

export default MessengerSenderFrame;