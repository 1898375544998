import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import { useUsers } from '../../Contexts/UserContext';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../Utils/Firebase';
import { __getTimeStamp } from '../../Utils/TimeFuncs';

const DonateModal = ({ show, callback = () => { } }) => {
    const [ mode ] = useState("live");
    const amountRef = useRef(null);
    const { user } = useUsers();
    const [fields, setFields] = useState({ isPublic: true, amount: 1000 })
    const [isOverview, setIsOverview] = useState(false);
    const [sdkReady, setSdkReady] = useState(false);

    const onChange = (event) => {
        if (event.target.name === "isPublic") { setFields((prev) => ({ ...prev, isPublic: event.target.checked })); return; };
        const { name, value } = event.target;
        setFields((prev) => ({ ...prev, [name]: value }));
        if (name === "amount") {
            amountRef.current.classList.remove('is-invalid');
        }
    }
    const goOverview = () => {
        if (fields.amount < 100) { amountRef.current.classList.add("is-invalid"); return; };
        setIsOverview(true);
    }
    const back = () => {
        setIsOverview(false);
    }

    useEffect(() => {
        if (window.paypal) {
            setSdkReady(true);
        } else {
            const script = document.createElement('script');
            if(mode === "sandbox") script.src = 'https://www.paypal.com/sdk/js?client-id=ASO7_TAET14axdQ62gAUo-0unSoFLqbX7xjII6Nj05F3jNOinGYerChpwCOYrogzQEyWBiNBg6wfVmur&currency=HUF';
            if(mode === "live") script.src = 'https://www.paypal.com/sdk/js?client-id=ASSm6lSJHm4TKLfg2AKe-cqZs91Nq3GYqMuyrRE4OzO2Fy3lYJsR2Sy5y6-h6deHLfAci13aqND4NfPZ&currency=HUF';
            script.onload = () => setSdkReady(true);
            document.body.appendChild(script);
        }
    }, []);
    useEffect(() => {
        if (sdkReady && isOverview) {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: fields.amount,
                                },
                            },
                        ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(async (details) => {
                        const donateData = await getDoc(doc(db, "donate", "donate"));
                        const amount = parseInt(donateData.data().currentAmount) + parseInt(fields.amount);
                        await updateDoc(doc(db, "donate", "donate"), { currentAmount: amount });
                        //Insert donator
                        await addDoc(collection(db, "donate", "donate", "donators"), {
                            uid: user.uid,
                            username: user.username,
                            amount: fields.amount,
                            isPublic: fields.isPublic,
                            time: __getTimeStamp()
                        });
                        callback("success");
                    });
                },
                onError: (err) => {
                    console.error('Hiba történt a fizetés során:', err);
                    callback("error");
                },
            }).render('#paypal-button-container');
        }
    }, [sdkReady, isOverview]);

    const cancel = () => {
        setFields({ isPublic: true, amount: 1000 });
        setIsOverview(false);
        callback("cancel");
    }

    return (
        <Modal show={show}>
            <Modal.Body>
                <h3 className="title">Adomány küldése</h3>
                {(!isOverview) && (
                    <Row>
                        <Col md={6}>
                            <Form.Check type="switch" style={{ position: 'relative', top: '5px' }} checked={fields.isPublic} name="isPublic" onChange={onChange} label={`Publikus: ${(fields.isPublic ? "IGEN" : "NEM")}`} />
                        </Col>
                        <Col md={6} className="mb-2">
                            <InputGroup>
                                <Form.Control ref={(el) => amountRef.current = el} type="number" name="amount" min={100} max={10000} step={100} value={fields.amount} onChange={onChange} className="dark" />
                                <InputGroup.Text className="dark">Ft</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col md={{ span: 6, offset: 6 }} className="text-right">
                            <Button onClick={goOverview} variant="outline-light">Áttekintés</Button>
                        </Col>
                    </Row>
                )}
                {(isOverview) && (
                    <Row>
                        <Col md={12}>
                            Támogatás összege: <b>{fields.amount} Ft</b>, amely <b>{(fields.isPublic) ? "publikus" : "nem publikus"}</b>.<br />
                            Így fogod látni a támogatást:<br />
                            <br />
                            <ListGroup className="dark mb-2">
                                <ListGroup.Item>
                                    <Row>
                                        <Col md={4}>{(fields.isPublic) ? user.username : "****"}</Col>
                                        <Col md={4}>{fields.amount} Ft</Col>
                                        <Col md={4}>**dátum**</Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                            <Button onClick={back} variant="outline-light">Vissza</Button>
                            <hr />
                        </Col>
                        <Col md={12}>
                            <div id="paypal-button-container"></div>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={cancel} variant="dark">Mégse</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DonateModal;