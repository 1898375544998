import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { useUsers } from '../../../Contexts/UserContext';
import TBUserPicture from '../TopBar/TBUserPicture';
import FaIcon from '../../Global/FaIcon';
import { addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { __getTimeStamp, getDiff } from '../../../Utils/TimeFuncs';
import { getUserByID } from '../../../Utils/FirebaseFuncs';

const FeedItemComments = forwardRef(({ postID }, ref) => {
    const [comments, setComments] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const fetchComments = async (loadMore = false) => {
        if (loading) return;
        setLoading(true);
        try {
            const commentRef = collection(db, "posts", postID, "comments");
            const commentsQuery = loadMore ? query(commentRef, orderBy("timestamp", "desc"), startAfter(lastVisible), limit(10)) : query(commentRef, orderBy("timestamp", "desc"), limit(3));

            const querySnapshot = await getDocs(commentsQuery);
            const newComments = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            if(loadMore){ setComments((prev) => [...prev, ...newComments]); }else{ setComments(newComments); };
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === (loadMore ? 10 : 3));
        } catch (err) { console.error(err); }
        finally { setLoading(false); }
    }
    useEffect(() => { fetchComments(); }, []);
    const callback = () => { fetchComments(); };


    return (
        <>
            {hasMore && (
                <Row>
                    <Col md={12} className="text-center">
                        <Button variant="link" onClick={() => fetchComments(true)} disabled={loading}>
                            {loading ? "Töltés..." : "Több"}
                        </Button>
                    </Col>
                </Row>
            )}
            {comments.sort((a,b) => a.timestamp - b.timestamp).map((comment, index) => (
                <FeedItemComment comment={comment} postID={postID} callback={callback} key={index} />
            ))}
            <FeedItemAddComment ref={ref} callback={callback} postID={postID} />
        </>
    );
});

export default FeedItemComments;

const FeedItemComment = ({ comment, postID, callback }) => {
    const { user } = useUsers();
    const [poster, setPoster] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const deleteComment = async () => {
        const commentRef = doc(db, "posts", postID, "comments", comment.id);
        await deleteDoc(commentRef);
        callback();
    }
    
    useEffect(() => {
        const fetchPoster = async () => {
            setLoading(true);
            try{
                const _user = await getUserByID(comment.uid);
                setPoster(_user);
            }catch(e) { }
            finally{setLoading(false)}
        };
        fetchPoster();
    },[]);
    if(loading){ return ( <></> )};
    return (
        <Row className="commentItem">
            <Col md={1} sm={2} xs={2}><TBUserPicture user={poster} showName={false} w={38} h={38} /></Col>
            <Col md={10} sm={8} xs={8}>
                <b>{poster?.username}</b> · {getDiff(comment.timestamp)}<br />
                {comment.comment}
            </Col>
            <Col md={1} sm={2} xs={2}>
                {(user.perm >= 97) && (
                    <Col md={3} className="text-right">
                        <Dropdown className="fbstle-dropdown">
                            <Dropdown.Toggle variant="link" id="dropdown-post"><FaIcon type="light" icon="ellipsis"/></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item variant="dark-2" as={Button} onClick={() => { deleteComment(); }}>Törlés</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                )}
            </Col>
        </Row>
    )
}

const FeedItemAddComment = forwardRef(({ callback, postID }, ref) => {
    const { user } = useUsers();
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState(true);
    const handleChange = (event) => {
        setComment(event.target.value);
        setDisabled((event.target.value === 0) ? true : false);
    }
    const sendComment = async () => {
        const commentRef = collection(db, "posts", postID, "comments");
        await addDoc(commentRef, {
            uid: user.uid,
            parent: 0,
            comment: comment,
            timestamp: __getTimeStamp()
        });
        callback();
        setComment("");
    }
    return (
        <Row className="commentItemInput">
            <Col md={1} sm={2} xs={2} className="d-none d-sm-none d-md-block"><TBUserPicture user={user} showName={false} w={38} h={38} /></Col>
            <Col md={11} sm={12} xs={12}>
                <InputGroup>
                    <Form.Control className="dark" ref={(el) => { ref.current = el; }} type="text" name="comment" value={comment} onChange={handleChange} placeholder="Hozzászólás..." />
                    <Button variant="dark" disabled={disabled} onClick={sendComment}><FaIcon type="light" icon="send" /></Button>
                </InputGroup>
            </Col>
        </Row>
    )
});