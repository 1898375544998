import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TBUserPicture from '../TopBar/TBUserPicture';
import { useUsers } from '../../../Contexts/UserContext';
import { getUserByID } from '../../../Utils/FirebaseFuncs';
import { getDiff, getDiffInMinutes } from '../../../Utils/TimeFuncs';
import { OnlineMarker } from '../Sidebar/OnlineUsers';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';

const MessengerContactItem = ({ chat, callback }) => {
    const { user } = useUsers();
    const [sender, setSender] = useState([]);
    useEffect(() => {
        const fetchSender = async () => {
            const senderID = chat.room.persons.filter(person => person !== user.uid)[0];
            const _sender = await getUserByID(senderID);
            setSender(_sender);
        };
        fetchSender();
    }, [])

    return (
        <Row>
            <Col md={12} className="chat-rooms-list">
                <Row className="chat-room-link" onClick={() => {callback(chat); }}>
                    <Col md={2} className="chat-room-icon-frame text-center">
                        <TBUserPicture user={sender} w={48} h={48} />
                        {(getDiffInMinutes(sender.lastAction) <= 5) && (<OnlineMarker state="online" />)}
                        {(getDiffInMinutes(sender.lastAction) > 6) && (getDiffInMinutes(sender.lastAction) <= 15) && (<OnlineMarker state="leaved" />)}
                        {(getDiffInMinutes(sender.lastAction) > 16) && (<OnlineMarker state="offline" />)}
                    </Col>
                    <Col md={8} className="d-none d-md-block">
                        <b>{sender.username}</b><br />
                        {chat.last.message} · <span className="small">{getDiff(chat.last.timestamp)}</span>
                    </Col>
                    <Col md={2} className="text-right align-self-center d-none d-md-block">
                        {(chat.last.unReaded) && (chat.last.sender !== user.uid) && (<div className="unreaded"></div>)}
                    </Col>
                </Row>
            </Col>
        </Row>

    );
};

export default MessengerContactItem;