import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import TBBrand from './TopBar/TBBrand';
import TBSearchForm from './TopBar/TBSearchForm';
import TBUserDropdown from './TopBar/TBUserDropdown';
import TBMessageDropdown from './TopBar/TBMessageDropdown';
import TBNotifDropdown from './TopBar/TBNotifDropdown';
import { Link } from 'react-router-dom';
import FaIcon from '../Global/FaIcon';

const TopBar = () => {
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark" className="navbar fixed-top bg-dark">
            <Container fluid>
                <TBBrand className="d-none d-sm-none d-md-block"/>
                {/*<TBSearchForm className="me-auto my-2 my-lg-0"/>*/}
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/donate"><FaIcon type="light" icon="circle-dollar-to-slot"/> Támogatás</Nav.Link>
                </Nav>
                <Nav className="ms-auto text-right flex-row">
                    <TBMessageDropdown />
                    <TBNotifDropdown />
                    <TBUserDropdown />
                </Nav>
            </Container>
        </Navbar>
    );
};

export default TopBar;