import React, { useEffect, useState } from 'react';

const TBUserPicture = ({ user, showName, w = 30, h = 30}) => {    
    const [picture, setPicture] = useState("https://static.446.hu/images/noavatar.png");
    useEffect(() => { setPicture((user) ? user.avatar : "https://static.446.hu/images/noavatar.png"); },[user]);
    return (
        <>
            <img src={picture} alt="profilePic" className="profile-thumb" style={{width: `${w}px`, height: `${h}px`}}/>
            {(showName) && (<span style={{paddingLeft:'5px'}}>{user.username}</span>)}
        </>
    );
};

export default TBUserPicture;