import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { getUserByID } from '../../Utils/FirebaseFuncs';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../Utils/Firebase';
import ProfileHeader from '../../Widgets/Dashboard/Profile/ProfileHead';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ProfileIndex from '../../Widgets/Dashboard/Profile/ProfileIndex';
import QSOMap from '../../Widgets/Dashboard/Profile/QSOMap';
import { useChat } from '../../Contexts/ChatContext';
import ProfileHeadMob from '../../Widgets/Dashboard/Profile/ProfileHeadMob';
import { isMobile } from 'react-device-detect';
import MPFeed from '../../Widgets/Dashboard/Profile/Mobile/MPFeed';
import MPAbout from '../../Widgets/Dashboard/Profile/Mobile/MPAbout';

const Profile = () => {
    const { id } = useParams();
    const { openChat } = useChat();
    const location = useLocation();
    const [ loading, setLoading] = useState(false);
    const [state, setState] = useState({
        profile: {
            uid: '',
            displayName: '',
            email: '',
            avatar: '',
            cover: '',
            perm: 0
        },
        infos: {
            radios: [],
            antennas: [],
            freqs: []
        },
        counts: {
            logs: 0,
            follower: 0
        }
    });

    useEffect(() => {
        setLoading(true);
        const fetchUser = async () => { 
            const user = await getUserByID(id);
            let profile = state.profile;
            let infos = state.infos;
            profile['uid'] = user?.uid;
            profile['aboutme'] = user?.aboutme || "";
            profile['displayName'] = user?.username;
            profile['avatar'] = user?.avatar;
            profile['cover'] = (user?.cover) ? user?.cover : "https://static.446.hu/images/cover_stock.jpg";
            profile['perm'] = user?.perm;
            infos['radios'] = (user.radios) ? JSON.parse(user.radios) : [];
            infos['antennas'] = (user.antennas) ? JSON.parse(user.antennas) : [];
            infos['freqs'] = (user.freqs) ? JSON.parse(user.freqs) : [];
            const fetchCounts = async () => {
                let counts = state.counts;
                let count = 0;
                const qsoRef = collection(db, "qsos");
                const qsoQuery = query(qsoRef, where("uid", "==", user.uid), orderBy("time", "desc"));
                const qsoSnapshot = await getDocs(qsoQuery);
                const my = qsoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                for(const item of my){
                    const listRef = collection(db, "qsos", item.id, "list");
                    const listQuery = query(listRef, orderBy("date", "desc"));
                    const listSnap = await getDocs(listQuery);
                    const qso = listSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                    for(const log of qso){
                        count = count + 1;
                    };
                };
                counts['logs'] = count;
                setState({ ...state, profile });
                setState({ ...state, counts });
                setState({ ...state, infos });
                setLoading(false);
            };
            fetchCounts();
        }; 

        fetchUser();        
    },[]);

    if (isMobile) return (
        <div id="profile">
            <div className="fb-styled-card mb-3" style={{ paddingBottom: "5px", marginTop: "-19px" }}>
                <ProfileHeadMob user={state} isMyOwn={false} callback={(event) => {}} />
                <hr style={{ marginTop: "45px" }} />
                <Container>
                    <Row>
                        <Col>
                            <Button as={Link} to={`/profile/${id}`} variant={`fb-header-btn ${location.pathname === `/profile/${id}` ? "active" : ""}`}>Bejegyzések</Button>
                            <Button as={Link} to={`/profile/${id}/about`} variant={`fb-header-btn ${location.pathname === `/profile/${id}/about` ? "active" : ""}`}>Névjegy</Button>
                            <Button as={Link} to={`/profile/${id}/map`} variant={`fb-header-btn ${location.pathname === `/profile/${id}/map` ? "active" : ""}`}>Térkép</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<MPFeed state={state} />} />
                    <Route path="/about" element={<MPAbout state={state} />} />
                    <Route path="/map" element={<QSOMap state={state.profile}/>} />
                </Routes>
            </Container>
        </div>
    );

    return (
        <div id="profile">
            <div className="fb-styled-card mb-3" style={{ paddingBottom: "5px", marginTop: "-19px" }}>
                <ProfileHeader user={state} isMyOwn={false} callback={(event) => {}} />
                <hr style={{ marginTop: "45px" }} />
                <Container>
                    <Row>
                        <Col>
                            <Button as={Link} to={`/profile/${id}`} variant={`fb-header-btn ${location.pathname === `/profile/${id}` ? "active" : ""}`}>Bejegyzések</Button>
                            <Button as={Link} to={`/profile/${id}/map`} variant={`fb-header-btn ${location.pathname === `/profile/${id}/map` ? "active" : ""}`}>Térkép</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<ProfileIndex state={state}/>} />
                    <Route path="/map" element={<QSOMap state={state.profile}/>} />
                </Routes>
            </Container>
        </div>
    );
};

export default Profile;