import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useUsers } from '../Contexts/UserContext';
import { addDoc, collection, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../Utils/Firebase';
import LoadingIndicator from '../Widgets/Global/LoadingIndicator';
import { getUserByID } from '../Utils/FirebaseFuncs';
import FaIcon from '../Widgets/Global/FaIcon';
import { __getTimeStamp, formatDate } from '../Utils/TimeFuncs';
import { getDistanceByQTH } from '../Utils/LocationService';

const QSOOverview = () => {
    const { user } = useUsers();
    const [ qsos, setQsos] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const fetchQsos = async () => {
        setLoading(true);
        setQsos([]);
        const qsos = await getDocs(collection(db,"qsos"));
        qsos.docs.forEach(async (qso) => {
            const itemRef = collection(db,"qsos", qso.id, "list");
            const itemQ = query(itemRef, where("callsign","==",user.username), where("validated","==",false), where("handled","==",false), orderBy("date","desc"));
            const items = await getDocs(itemQ);
            if(items.docs.length > 0){
                let qs = [];
                items.docs.forEach(async (item) => {
                    const sender = await getUserByID(qso.data().uid);
                    let i = { 
                        id: item.id, 
                        qid: qso.id,
                        time: item.data().date,
                        sender: {
                            uid: qso.data().uid,
                            avatar: sender.avatar,
                            callsign: sender.username,
                            opName: sender.username,
                            qth: qso.data().qth
                        },
                        target: {
                            callsign: item.data().callsign,
                            opName: item.data().opName,
                            qth: item.data().qth,
                        },
                        contact: {
                            mode: item.data().mode,
                            type: item.data().type,
                            freq: item.data().freq,
                            ctcs: item.data().ctcs
                        }
                    };
                    qs.push(i);                      
                });
                setQsos(qs);
            };
        });
        setLoading(false);
    };

    useEffect(() => { fetchQsos(); },[]);

    const setHandled = async (state, item) => {
        let fields = {};
        if(state){ fields = {validated: true, handled: true}; }else{ fields = {handled: true}; };
        console.log(item);
        const qsoRef = doc(db, "qsos", item.qid, "list", item.id);
        await updateDoc(qsoRef, fields);
        if(state) { await addDoc(collection(db,"notification"), {
            target: item.sender.uid,
            type: 'qso_valid',
            text: `${item.target.callsign} Visszaigazolta a QSO logot!`,
            time: __getTimeStamp(),
            visited: false
        }); };
        
        fetchQsos();
    }

    if(loading) return <LoadingIndicator />;

    return (
        <div className="feedItem">
            <h5>QSO logok, amiben megjelöltek</h5>
            <ListGroup className="sl_listGroup">
                {(qsos.length > 0) && (qsos.map((qso,index) => (
                    <ListGroupItem key={index}>
                        <Row>
                            <Col md={2} xs={6}>
                                <FaIcon type="light" icon="user" /> {qso.sender.callsign}
                            </Col>
                            <Col md={2} xs={6}>
                                <FaIcon type="light" icon="calendar" /> {formatDate(new Date(qso.time),".","short")}
                            </Col>
                            <Col md={2} xs={12}>
                                <FaIcon type="light" icon="wave-sine" /> {qso.contact.freq} {(qso.contact.ctcs !== "") && (<><i>{qso.contact.ctcs}</i></>)}
                            </Col>
                            <Col md={3} xs={12}>
                                <FaIcon type="light" icon="map-marker-alt" /> {qso.sender.qth} - {qso.target.qth}
                            </Col>
                            <Col md={2} xs={6}>
                                <FaIcon type="light" icon="ruler" /> {getDistanceByQTH(qso.sender.qth, qso.target.qth)}
                            </Col>
                            <Col md={1} xs={6} className="text-right">
                                <ButtonGroup style={{position:'relative',top:'-2px'}}>
                                    <Button size="sm" variant="dark" onClick={() => { setHandled(false, qso);} }><FaIcon type="light" icon="times" /></Button>
                                    <Button size="sm" variant="dark" onClick={() => { setHandled(true, qso);} }><FaIcon type="light" icon="check" /></Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </ListGroupItem>
                )))}
            </ListGroup>
        </div>
    );
};

export default QSOOverview;