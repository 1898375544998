import React, { useEffect, useRef, useState } from 'react';
import FaIcon from '../../Widgets/Global/FaIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { applyActionCode, confirmPasswordReset, updatePassword, verifyPasswordResetCode } from 'firebase/auth';
import { auth, firebaseErrors } from '../../Utils/Firebase';
import { Alert, Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import FormErrorMessage from '../../Widgets/Global/FormErrorMessage';

const Activate = () => {
    const [icon, setIcon] = useState("spinner");
    const [size, setSize] = useState("3x");
    const [anim, setAnim] = useState("fa-spin");
    const [txt1, setTxt1] = useState("Aktiválás folyamatban...");
    const [txt2, setTxt2] = useState("Kérlek, NE zárd be ezt az ablakot!");
    const [complete, setComplete] = useState(false);
    const [createNewPassword, setCreateNewPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    useEffect(() => {
        if (mode === "verifyEmail") {
            applyActionCode(auth, oobCode)
                .then(async () => {
                    setIcon("check");
                    setAnim(false);
                    setTxt1("Sikeres aktiválás!");
                    setTxt2("Most már elhagyhatod ezt az oldalt!");
                    setComplete(true);
                })
                .catch((err) => {
                    setIcon("times");
                    setAnim(false);
                    setTxt1("Sikertelen aktiválás. Hiba:");
                    setTxt2(firebaseErrors[err.code]);
                    setComplete(true);
                });
        } else if (mode === "resetPassword") {
            verifyPasswordResetCode(auth, oobCode)
                .then(async (data) => {
                    setCreateNewPassword(true);
                })
                .catch((err) => {
                    setIcon("times");
                    setAnim(false);
                    setTxt1("Sikertelen aktiválás. Hiba:");
                    setTxt2(firebaseErrors[err.code]);
                    setComplete(true);
                })
        }
    }, [location]);

    /*ChangePassword*/
    const passwordRef = useRef();
    const passwordRepRef = useRef();
    const [fields, setFields] = useState({password: '', password_rep: ''});
    const [errors, setErrors] = useState({password: '', password_rep: '', message: '', variant: 'danger'});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, [name]: false}));
        setFields((prev) => ({ ...prev, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        confirmPasswordReset(auth, oobCode, fields.password)
            .then(() => {
                setCreateNewPassword(false);
                setIcon("check");
                setAnim(false);
                setTxt1("Sikeres jelszómódosítás!");
                setTxt2("Most már elhagyhatod ezt az oldalt!");
                setComplete(true);
            })
            .catch((err) => {
                setCreateNewPassword(false);
                setIcon("times");
                setAnim(false);
                setTxt1("Sikertelen aktiválás. Hiba:");
                setTxt2(firebaseErrors[err.code]);
                setComplete(true);
            })
    }

    return (
        <div>
            {(!createNewPassword) && (
                <>
                    <FaIcon type="solid" icon={icon} size={size} anim={anim} /><br /><br />{txt1}<br />{txt2}<br /><br />
                    {(complete) && (<Button as={Link} to="/auth" variant="dark">Belépés</Button>)}
                </>
            )}
            {(createNewPassword) && (
                <Row className="justify-content-center">
                    <Col md={{ span: 6, offset: 0 }}>
                        {(errors?.message) && ( <Alert variant={errors?.variant}>{errors?.message}</Alert> )}
                        <Form onSubmit={handleSubmit}>
                            <FloatingLabel controlId='password' label="Jelszó" className="mb-3">
                                <Form.Control ref={passwordRef} className={(errors?.password) ? `is-invalid` : ``} type="password" placeholder="Jelszó" name="password" value={fields.password} onChange={handleChange} />
                                <FormErrorMessage error={errors?.password} target={passwordRef} />
                            </FloatingLabel>
                            <FloatingLabel controlId='password_rep' label="Jelszó megerősítése" className="mb-3">
                                <Form.Control ref={passwordRepRef} className={(errors?.password_rep) ? `is-invalid` : ``} type="password" placeholder="Jelszó megerősítése" name="password_rep" value={fields.password_rep} onChange={handleChange} />
                                <FormErrorMessage error={errors?.password_rep} target={passwordRepRef} />
                            </FloatingLabel>
                            <Button variant="success" type="submit" className="mb-3 d-block w-100"> Jelszó létrehozása </Button>
                        </Form>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Activate;