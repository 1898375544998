export const yagi_calculator = ({freq,elements,diameter_of_parasitic_elements, diameter_of_boom, boom_isolated, }) => {
    const lambda = 300000 / freq;

    const abstand = [0.240, 0.075, 0.180, 0.215, 0.250];
    const positions = [];
    const lengths = [];

    let reflectorlength = 0.482;
    const boom_dicke_lambda = diameter_of_boom / lambda;

    if (!boom_isolated) {
        const korrlaenge = -416.86 * boom_dicke_lambda ** 3 + 46.183 * boom_dicke_lambda ** 2 - 0.6834 * boom_dicke_lambda + 0.0059;
        reflectorlength += korrlaenge;
    }

    positions.push(0);
    lengths.push(reflectorlength * lambda);

    const dipole_pos = abstand[0] * lambda;
    positions.push(dipole_pos);
    lengths.push(0.468 * lambda);

    for (let i = 1; i < elements - 1; i++) {
        const director_pos = positions[positions.length - 1] + abstand[i] * lambda;
        positions.push(director_pos);

        const director_length = boom_isolated ? lambda * (0.440 - 0.002 * i) : lambda * (reflectorlength - 0.010 * i);
        lengths.push(director_length);
    }

    const boomlength = positions[positions.length - 1];

    const gain = 3.39 * Math.log(boomlength / lambda) + 9.15;

    const result = {
        frequency: freq,
        wavelength: Math.round(lambda),
        boomDiameter: diameter_of_boom,
        boomLength: Math.round(boomlength),
        elements,
        rodDiameter: diameter_of_parasitic_elements,
        gain: gain.toFixed(2),
        details: [],
    };

    for (let i = 0; i < elements; i++) {
        const type = i === 0 ? "Reflector" : i === 1 ? "Dipole" : `Director #${i - 1}`;
        result.details.push({
            type,
            length: Math.round(lengths[i]),
            position: Math.round(positions[i]),
            ...(i > 0 && {
                distanceToPrevious: Math.round(positions[i] - positions[i - 1]),
            }),
        });
    }

    return result;
};

export const drawer = (antennaData) => {
    const { details, boomDiameter, rodDiameter, boomLength } = antennaData;
    const svgWidth = (boomLength + 50); // Szélesség
    const svgHeight = details[0].length; // Magasság
    const scaleFactor = svgWidth / boomLength; // Skálázási tényező, hogy a rajz elférjen
    const fontSize = 65;

    // SVG elem létrehozása
    let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${(svgWidth)}" height="${(svgHeight + 100)}" viewBox="-50 10 ${(svgWidth + 50)} ${svgHeight}">`;

    // Alap vonal, ami az antenna közepét jelöli
    svgContent += `<line x1="0" y1="${svgHeight / 2}" x2="${(svgWidth - 50)}" y2="${svgHeight / 2}" stroke="gray" stroke-width="25" />`;
    svgContent += `<text x="50" y="${(svgHeight / 2) + 5}" font-size="${fontSize}" text-anchor="left" fill="white">Kar: ${boomLength}</text>`;;


    details.forEach((element, index) => {
        const position = element.position;
        const length = parseInt(element.length);
        const type = element.type;
        let strokeColor = "gray";
        let strokeWidth = 10;

        const lineCenterY = svgHeight / 2;  // Az SVG vízszintes közepén
        const halfLineLength = length * scaleFactor / 2; 
        const startY = lineCenterY - halfLineLength;
        const endY = lineCenterY + halfLineLength;

        if (type === "Reflector") {
            strokeColor = "red";
            strokeWidth = 10;
        } else if (type === "Dipole") {
            strokeColor = "blue";
            strokeWidth = 8;
        } else if (type.includes("Director")) {
            strokeColor = "green";
            strokeWidth = 6;
        }

        svgContent += `<line x1="${(element.position + 5)}" y1="${startY}" x2="${(element.position + 5)}" y2="${endY}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;
        svgContent += `<text x="${(0)}" y="${svgHeight + (element.position)}" font-size="${fontSize}" transform="rotate(-90,0,1000)" text-anchor="left" fill="white">${type} poz: ${element.position}, hossz:  ${element.length}</text>`;
    });
    
    // SVG lezárása
    svgContent += "</svg>";

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgContent)))}`;
}