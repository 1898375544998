import { Adsense } from '@ctrl/react-adsense';
import React from 'react';

const Ads = ({ hasSeparator = true }) => {
    return (
        <>
            Hirdetés
            <Adsense
                client="ca-pub-2303113274435979"
                slot="5625853584"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            />
            {(hasSeparator) && (<hr/>)}
        </>
    );
};

export default Ads;