import React from 'react';

const FaIcon = ({icon, type = "solid", size = "1x", anim = "", css = "", className = "", style = {}}) => {
    return (
        <>
            <i className={`fa-${type} fa-${icon} fa-${size} ${anim} ${css} ${className}`} style={style} />
        </>
    );
};

export default FaIcon;