import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../Global/FaIcon';
import { motion } from 'framer-motion';

const PubRegister = () => {
    return (
        <section id="content-3" className="row mb-3">
			<div className="col-md-6 align-self-center text-center col-sm-12 order-md-1 order-sm-2">
				<motion.img initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0, duration: 2 }}} className="img-responsive" src={`https://static.446.hu/images/qso_log_screen.jpg`} alt="QSO log screen"/>
			</div>
			<div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
				<motion.h3 initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 2 }}} className="ctx-title">Csatlakozz hozzánk!</motion.h3>
				<motion.p initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.3, duration: 2 }}} className="ctx-text">Légy részese egy igazi generációs ugrásnak! Csatlakozz még ma, hogy a Jelenléteddel segítsd a rádiós közösség népszerűsítését!</motion.p>
				<motion.p initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 2 }}} className="ctx-text">Hozd létre a fiókod, létesíts összeköttetést és <b>Oszd meg barátaiddal</b>, hogy Ők is részesei legyenek az élménynek!</motion.p>
				<motion.p initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.5, duration: 2 }}} className="ctx-text">Generálj minél több QSO-t a versenyek alkalmával és <b>szerezz ajándékokat</b>!</motion.p>
				<motion.p initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.6, duration: 2 }}} className="ctx-text">
					<ButtonGroup>
						<Button variant="success" target="_self" as={Link} to="/auth"><FaIcon type="solid" icon="user-plus"/> Regisztrálok!</Button>
						<Button variant="success" target="_self" as={Link} to="/auth"><FaIcon type="solid" icon="sign-in-alt"/> Bejelentkezés</Button>
					</ButtonGroup>
				</motion.p>
			</div>			
		</section>
    );
};

export default PubRegister;