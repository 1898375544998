import React from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import FaIcon from '../Global/FaIcon';


const PubHero = () => {
    return (
        <Container fluid>
            <Row className="mb-3 text-center header-section">
                <Col className="header-wrapper"></Col>
                <div className="col-12 header-content d-flex align-items-center justify-content-center">
                    <div className="">
                        <motion.h2 initial={{ y: 20, opacity: 0}} animate={{ y: 0, opacity: 1, transition: { delay: 0, duration: 2} }}>446.HU</motion.h2>
                        <motion.h3 initial={{ y: 20, opacity: 0}} animate={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 2} }} className="mb-3">A Magyar PMR közösség</motion.h3>
                        <motion.div initial={{ y: 20, opacity: 0}} animate={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 2} }} aria-label="Hero Buttons">
                            <ButtonGroup>
                                <Button as={Link} variant="success" to="/auth"><FaIcon type="solid" icon="user-plus"/ > Regisztráció</Button>
                                <Button as={Link} variant="success" to="/auth"><FaIcon type="solid" icon="sign-in-alt" /> Bejelentkezés</Button>
                            </ButtonGroup>
                        </motion.div>
                    </div>
                </div>
            </Row>
        </Container>
    );
};

export default PubHero;