import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../Widgets/Global/BreadCrumb';
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import FaIcon from '../../../Widgets/Global/FaIcon';
import { useUsers } from '../../../Contexts/UserContext';
import { __getTimeStamp } from '../../../Utils/TimeFuncs';
import { Bounce, toast, ToastContainer } from 'react-toastify';

const DLViewFile = () => {
    const { user } = useUsers();
    const { cat, file } = useParams();
    const [ category, setCategory ] = useState([]);
    const [ f, setF] = useState([]);
    const [ bcArgs, setBcArgs] = useState([
        {url: "/", icon: "home", caption: "", current: false},
        {url: "/downloads", icon: "download", caption: "Letöltések", current: false},
    ]);

    useEffect(() => {
        const fetchFiles = async () => {
            

            setCategory([]);
            setF([]);
            const catDoc = await getDoc(doc(db, "downloads", cat));
            let _cat = {id: catDoc.id, ...catDoc.data()};
            setCategory(_cat);
            setBcArgs((prev) => {
                const bcItem = {url: `/downloads/${_cat.id}`, icon: null, caption: _cat.name, current: false};
                if(prev.some((item) => item.url === bcItem.url)) return prev;
                return [ ...prev, bcItem];
            });
            
            const fDoc = await getDoc(doc(db, "downloads", cat, "files", file));
            let _f = {id: fDoc.id, ...fDoc.data()};
            setF(_f);
            setBcArgs((prev) => {
                const bcItem = {url: `/downloads/${_cat.id}/${_f.id}`, icon: null, caption: _f.name, current: true};
                if(prev.some((item) => item.url === bcItem.url)) return prev;
                return [ ...prev, bcItem];
            });
            await updateDoc(doc(db, "downloads", cat, "files", file), { views: (_f.views + 1)});
        }; 
        fetchFiles();
    },[]);

    const report = async () => { 
        await addDoc(collection(db,"downloads",cat,"files",file,"reports"), { uid: user.uid, time: __getTimeStamp() }); 
        try{toast.success("A visszajelzés megérkezett hozzánk! Hamarosan utánajárunk!", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "dark",transition: Bounce,}) }catch(err){ };
    }
    const download = async () => {
        await updateDoc(doc(db, "downloads", cat, "files", file), { downloads: (f.downloads + 1)});
        window.open(`${window.location.protocol}//${window.location.host}/getFile/${cat}:${f.id}`);
    }

    return (
        <>
        <BreadCrumb args={bcArgs}/>
        <hr/>
        <Row>
            <Col md={12} className="text-center"><h2 className='title'>{f.name}</h2></Col>
        </Row>
        <Row>
            <Col md={4}>
                {(f?.image) && ( <img src={f.image} alt="Kép" style={{width:'100%'}}/>)}
            </Col>
            <Col md={4}><span style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: f.description}}></span></Col>
            <Col md={4}>
                <Button className="w-100" variant="success" onClick={download} style={{borderBottomLeftRadius:0, borderBottomRightRadius:0}}><FaIcon type="light" icon="download" /> Letöltés</Button>
                <ButtonGroup className="w-100">
                    <Button variant="danger" onClick={report} style={{borderTopLeftRadius:0}}><FaIcon type='light' icon="flag"/></Button>
                    <Button variant="dark" disabled><FaIcon type='light' icon="eye"/> {f.views}</Button>
                    <Button variant="dark" disabled style={{borderTopRightRadius: 0}}><FaIcon type='light' icon="download"/> {f.downloads}</Button>
                </ButtonGroup>
            </Col>
        </Row>
        </>
    );
};

export default DLViewFile;