import React, { useEffect, useState } from 'react';
import { Badge, NavDropdown } from 'react-bootstrap';
import FaIcon from '../../Global/FaIcon';
import { useUsers } from '../../../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { __getTimeStamp, getDiff } from '../../../Utils/TimeFuncs';
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { toast, Zoom } from 'react-toastify';
import { playSound } from '../../../Utils/Audio';

const TBNotifDropdown = () => {
    const { user } = useUsers();
    const navigate = useNavigate();
    const [ count, setCount ] = useState(0);
    const [ notifs, setNotifs] = useState([]);
    const [ nState, setnState] = useState(false);
    
    useEffect(() => {
        const notifRef = collection(db, "notification");
        const q = query(notifRef, where("target","==", user.uid), orderBy("visited","asc"), orderBy("time","desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            setCount(0); setNotifs([]);
            snapshot.docs.forEach((doc) => {
                if(!doc.data().visited) { 
                    toast.info(doc.data().text, {position: "bottom-left",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false,progress: undefined,theme: "dark",transition: Zoom,});    
                    setCount((prev) => { return (prev + 1);}); 
                };
                setNotifs((prev) => [ ...prev, {id: doc.id, ...doc.data()}]);
                
            });
            setnState(false);
        });
        return () => unsubscribe();
    },[])
    
    const notifClick = async (n) => {
        if(!n.visited && n.link !== ""){ 
            const notifRef = doc(db, "notification", n.id);
            await updateDoc(notifRef, { visited: true });
            navigate(n.link); 
        }else if(!n.visited && n.link === ""){ 
            const notifRef = doc(db, "notification", n.id);
            await updateDoc(notifRef, { visited: true });
        }else if(n.visited){
            const notifRef = doc(db, "notification", n.id);
            await deleteDoc(notifRef);
        }
    }

    const markAsRead = (event) => { }
    return (
        <NavDropdown onToggle={(event) => markAsRead(event)} className="notification message-dd d-none d-sm-none d-md-block" title={<TBNotifIcon count={count}/>} >
        {(notifs.length === 0) && (<NavDropdown.Item href='#' onClick={(e) => { e.preventDefault(); }} className="text-center">Nincs értesítésed!</NavDropdown.Item>)}
        {(notifs.length > 0) && (notifs.map((n, index) => (
            <NavDropdown.Item key={index} href='#' onClick={(e) => { e.preventDefault(); notifClick(n);}}>
                <b>{n.text}</b><br/>
                <div className="timeRow">
                    {(!n.visited) && (<div className="unReaded"></div>)}
                    <span className='timeAgo text-muted small'>{getDiff(n.time)}</span>
                </div>                
            </NavDropdown.Item>
        )))}
        </NavDropdown>
    );
};
const TBNotifIcon = ({ count = 0}) => {
    return (
        <>
            <FaIcon type="light" icon="bell" style={{fontSize: "26px"}}/>
            {(count > 0) && (
                <Badge pill bg="danger" style={{position:'absolute',top:"0px",right:"5px"}}>{count}</Badge>
            )}
        </>
    )
}

export default TBNotifDropdown;