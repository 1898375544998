import React from 'react';
import { Link } from 'react-router-dom';
import FaIcon from './FaIcon';

const BreadCrumb = ({ args }) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {(args.map((item, index) => (
                    <li key={index} className={`breadcrumb-item ${(item.current) ? "active" : ""}`} aria-current="page">
                        {(!item.current) && (
                            <Link to={item.url}>
                                {(item.icon) && (<><FaIcon type="light" icon={item.icon} />&nbsp;</>)}
                                {(item.caption) && (<>{item.caption}</>)}
                            </Link>
                        )}
                        {(item.current) && (
                            <>
                                {(item.icon) && (<><FaIcon type="light" icon={item.icon} />&nbsp;</>)}
                                {(item.caption) && (<>{item.caption}</>)}
                            </>
                        )}

                    </li>
                )))}
            </ol>
        </nav>
    );
};

export default BreadCrumb;