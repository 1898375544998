import React, { useEffect, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import IconSet from './Icons';
import { Alert, Col, ListGroup, ListGroupItem, Offcanvas, Row } from 'react-bootstrap';

const BalloonMarker = ({ args }) => {
    const [ offCanvas, canvasShow ] = useState(false);

    useEffect(() => {
        //console.log(args);
    }, []);

    const calcAltitude = (alt) => {
        if (alt => 1000) { return (alt / 1000).toFixed(2) + " Km" };
        return (alt.toFixed(2) + "m");
    }

    return (
        <>
            {(args.item?.lat) && (args.item?.lon) && (
                <>
                    <Marker key={args.id} icon={IconSet.ballon} position={[args.item.lat, args.item.lon]} eventHandlers={{click: (e) => { canvasShow(true) }}}>
                        <Tooltip direction='right' offset={[0, 0]} className="planeLabel">
                            F: {args.item.frequency} MHz<br />
                            A: {calcAltitude(args.item.alt)}<br />
                            S: {args.item.serial}<br/>
                            {args.item.burst_timer}
                        </Tooltip>
                    </Marker>
                    <Offcanvas show={offCanvas} onHide={() => { canvasShow(false); }}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{args.item.serial}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                <Col md={12}>
                                    {args.item.manufacturer} {args.item.subtype}<br/>
                                    S/N: {args.item.serial}
                                    <hr/>
                                    Magasság: <b>{calcAltitude(args.item.alt)}</b>, Irány: <b>{args.item.heading}°</b><br/>
                                    Sebesség: <b>N/A</b>, Hőmérséklet: <b>{args.item.temp}°C</b><br/>
                                    Légnyomás: <b>{args.item.pressure || "--"} mBar</b>, Páratartalom: <b>{args.item.humidity}%</b><br/>
                                    <hr/>
                                    <Alert variant="info"><b>Frekvencia: {args.item.tx_frequency || args.item.frequency} MHz</b></Alert>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                </>
            )}
        </>
    );
};

export default BalloonMarker;