import React, { useCallback, useEffect, useState } from 'react';
import CreateFeedItem from '../Feed/CreateFeedItem';
import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import FeedItem from '../Feed/FeedItem';
import LoadingIndicator from '../../Global/LoadingIndicator';
import { useUsers } from '../../../Contexts/UserContext';


const ProfileFeed = ({ state }) => {
    const { user } = useUsers();
    const [posts, setPosts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const uid = state.profile.uid;
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!state.profile.uid) return;
        //setLoading(true);
        const getSettings = async () => {
            let _settingsArr = [];
            const SettingsPath = collection(db, "users", state.profile.uid, "settings");
            const SettingsRef = await getDocs(SettingsPath);
            SettingsRef.docs.forEach((doc) => {
                _settingsArr[doc.id] = doc.data();
            });
            console.log(_settingsArr);
            setSettings(_settingsArr);
            setLoading(false);
        }; //getSettings();        
    },[state.profile.uid]);

    const addUniquePosts = (newPosts) => {
        setPosts((prevPosts) => {
            const combinedPosts = [...newPosts, ...prevPosts];
            const uniquePosts = combinedPosts.filter((post,index,self) => self.findIndex((p) => p.id === post.id) === index);
            return uniquePosts.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
        })
    }

    const fetchPosts = useCallback(async () => {
        if(isFetching || !hasMore || !state.profile.uid) return;
        setIsFetching(true);
        const postsRef = collection(db, "posts");
        const queryByUid = query(postsRef, where("uid", "==", state.profile.uid), orderBy("timestamp", "desc"), limit(5));
        const queryByTarget = query( postsRef, where("target_uid", "==", state.profile.uid), orderBy("timestamp", "desc"), limit(5));
        let finalQueryByUid = queryByUid;
        let finalQueryByTarget = queryByTarget;
        if (lastVisible) {
            finalQueryByUid = query(queryByUid, startAfter(lastVisible));
            finalQueryByTarget = query(queryByTarget, startAfter(lastVisible));
        };
        const [snapshotByUid, snapshotByTarget] = await Promise.all([getDocs(finalQueryByUid),getDocs(finalQueryByTarget)]);
        const resultsByUid = snapshotByUid.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const resultsByTarget = snapshotByTarget.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const combinedResults = [
            ...resultsByUid,
            ...resultsByTarget.filter(item => !resultsByUid.some(result => result.id === item.id)),
        ];
        addUniquePosts(combinedResults);
        setLastVisible(snapshotByUid.docs[snapshotByUid.docs.length - 1] || snapshotByTarget.docs[snapshotByTarget.docs.length - 1]);
        setHasMore(combinedResults.length > 0);
        setIsFetching(false);
    },[db, state.profile.uid, lastVisible, isFetching, hasMore]);

    useEffect(() => {
        const postsRef = collection(db, "posts");
        const queryByUid = query(postsRef,where("uid", "==", uid),orderBy("timestamp", "desc"));
        const queryByTarget = query(postsRef,where("target", "==", uid),orderBy("timestamp", "desc"));    
        const unsubscribeUid = onSnapshot(queryByUid, (snapshot) => {
            const newPosts = [];
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    newPosts.push({ id: change.doc.id, ...change.doc.data() });
                }else if(change.type === "removed") {
                    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== change.doc.id));
                }
            });
            if (newPosts.length > 0) { addUniquePosts(newPosts); }
        });

        const unsubscribeTarget = onSnapshot(queryByTarget, (snapshot) => {
            const newPosts = [];
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    newPosts.push({ id: change.doc.id, ...change.doc.data() });
                }
            });
            if (newPosts.length > 0) { addUniquePosts(newPosts); }
        });
        return () => { unsubscribeUid(); unsubscribeTarget(); };
    },[db,uid]);

    useEffect(() => {
        const handleScroll = () => {if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && hasMore) {fetchPosts();}};
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [fetchPosts, hasMore]);

    useEffect(() => {
        fetchPosts();
    },[fetchPosts]);
    
    if(loading) return ( <LoadingIndicator /> );    

    return (
        <>
            {(settings.privacy?.memberWriteMyWall) && (<CreateFeedItem target={state.profile.uid} />)}
            {(state.profile.uid == user.uid) && (<CreateFeedItem target={state.profile.uid} />)}
            {(posts.sort((a,b) => (b.timestamp - a.timestamp)).map((post) => (<FeedItem key={post.id} post={post}/>)))}
            {(isFetching) && (<LoadingIndicator modal={false}/>)}
            {!hasMore && <p className="text-center">Nem található több bejegyzés</p>}
        </>
    );
};

export default ProfileFeed;