import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { db } from '../../Utils/Firebase';
import { useUsers } from '../../Contexts/UserContext';
import MessengerContactItem from '../../Widgets/Dashboard/Chat/MessengerContactItem';
import MessengerMainFrame from '../../Widgets/Dashboard/Chat/MessengerMainFrame';
import MessengerSenderFrame from '../../Widgets/Dashboard/Chat/MessengerSenderFrame';

const Messenger = () => {
    const { user } = useUsers();
    const [ chats, setChats ] = useState([]);
    const [ selected, setSelected] = useState(null);

    useEffect(() => {
        const rooms = collection(db, "chatRooms");
        const roomsQ = query(rooms, where("persons","array-contains",user.uid), orderBy("lastMessage", "desc"));
        const fetchChats = onSnapshot(roomsQ, (snapshot) => {
                        
            const handleChatroom = (doc) => {
                const chatroomID = doc.id;
                const messagesRef = collection(db, `chatRooms/${chatroomID}/messages`);
                const msgQ = query(messagesRef, orderBy("timestamp","desc"));
                const unsubscribeMessages = onSnapshot(msgQ, (messagesSnapshot) => {
                    if(messagesSnapshot.docs.length > 0){
                        messagesSnapshot.docs.sort((a,b) => (b.timestamp - a.timestamp)).slice(0,1).map((msg) => {
                            let item = { room: {id: doc.id, ...doc.data()}, last: {id: msg.id, ...msg.data()}}
                            setChats((prevChats) => {
                                const existingIndex = prevChats.findIndex(chat => chat.room.id === item.room.id);
                                if(existingIndex !== -1){
                                    const updatedChats = [...prevChats];
                                    updatedChats[existingIndex].last = item.last;
                                    return updatedChats;
                                }else{
                                    return [...prevChats, item];
                                };
                            });
                        });
                    };
                });
                return () => unsubscribeMessages();
            };          
           
            snapshot.docs.forEach((doc) => {
                handleChatroom(doc);
            });
           
        });
        return () => fetchChats();
    },[]);

    const selectChat = (item) => { setSelected(item); }

    return (
        <Row style={{marginTop:"-20px"}}>
            <Col md="3" xs={2} className='py-3 messenger-left'>
                <div className="messenger-chats-head d-none d-sm-none d-md-block">
                    <div className='messenger-title'>Chatek</div>
                </div>
                <div className="messenger-contacts">
                    {(chats.map((chat,index) => (
                        <MessengerContactItem key={index} chat={chat} callback={(item) => {selectChat(item); }}/>
                    )))}
                </div>
            </Col>
            <Col md="6" xs={10} className="borderLeft messenger-mainFrame">
                {(selected) && ( <MessengerMainFrame chat={selected} />)}
            </Col>
            <Col md="3" className="borderLeft d-none d-sm-none d-md-block">
                {(selected) && ( <MessengerSenderFrame chat={selected} />)}
            </Col>
        </Row>
    );
};

export default Messenger;