import { Cylinder, Text } from '@react-three/drei';

export const Yagi3D = ({ antennaData }) => {
    let { details, boomDiameter, rodDiameter, boomLength } = antennaData;
    const angle = (deg) => { return deg * (Math.PI / 180); }

    return (
        <>
            <ambientLight intensity={0.5} />
            <directionalLight castShadow position={[0, 0, 0]} shadow-mapSize={[1024, 1024]} />
            <mesh rotation={[angle(320), angle(210), angle(125)]}>
                <Cylinder scale={[0.001, 0.001, 0.001]} args={[boomDiameter, boomDiameter, boomLength]} position={[0, 0, 0]} rotation={[angle(0), angle(0), angle(0)]}>
                    <meshStandardMaterial color="red" />
                </Cylinder>
                <Text position={[-0.90, 0.0, 0.0]} rotation={[angle(180), angle(0), angle(270)]} fontSize={0.1} color="red" anchorX="center" anchorY="middle" >
                    Gém hossz: {boomLength} mm
                </Text>
                <Text position={[-1.02, 0.0, 0.0]} rotation={[angle(180), angle(0), angle(270)]} fontSize={0.1} color="red" anchorX="center" anchorY="middle" >
                    Gém átmérő: {boomDiameter} mm
                </Text>
                <Text position={[0.9, 0.0, 0.0]} rotation={[angle(180), angle(0), angle(275)]} fontSize={0.1} color="white" anchorX="center" anchorY="middle" >
                    Pálca átmérő: {rodDiameter} mm
                </Text>
                {(details.map((item) => {
                    const freq = parseInt(antennaData.frequency);
                    let offset = 0.0;
                    if (freq === 146) { offset = 0.71; };
                    if (freq === 446) { offset = 0.24; };

                    const pos = (item.position === boomLength) ? offset : (item.position * 0.001) - offset;
                    let color = "lightGreen";
                    if (item.type === "Reflector") { color = "lightBlue"; };
                    if (item.type === "Dipole") { color = "green"; };
                    if (item.type.includes("Director")) { color = "grey" };
                    return (
                        <>
                            <Cylinder scale={[0.001, 0.001, 0.001]} args={[rodDiameter, rodDiameter, item.length]} position={[0.0, pos, -0.015]} rotation={[angle(90), angle(0), angle(90)]}>
                                <meshStandardMaterial color={color} />
                            </Cylinder>
                            <Text position={[0.1, (pos + 0.06), 0.009]} rotation={[angle(180), angle(0), angle(0)]} fontSize={0.1} color={color} anchorX="left" anchorY="middle" >
                                Poz: {item.position} mm
                            </Text>
                            <Text position={[-0.05, (pos + 0.06), 0.009]} rotation={[angle(180), angle(0), angle(0)]} fontSize={0.1} color={color} anchorX="right" anchorY="middle" >
                                Hossz: {item.length} mm
                            </Text>
                        </>
                    );
                }))}
            </mesh>
        </>
    )
}


