import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { formatDate } from '../../../Utils/TimeFuncs';

const TimeLine = ({ args }) => {
    const stringify = () => {
        return (args.work) ? "Működik" : "Nem működik";
    };

    return (
        <li className={(args.work) ? "w" : "nw"}>
            <Badge bg={(args.work) ? "success" : "danger"}>{formatDate(new Date(args.timestamp * 1000),'.')} {stringify()}</Badge>
        </li>
    );
};

export default TimeLine;