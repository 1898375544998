import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Alert, Button, ButtonGroup, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import FaIcon from '../Widgets/Global/FaIcon';
import { getDistanceByQTH, getUserLocation } from '../Utils/LocationService';
import { MyStorage } from '../Utils/MyStorage';
import { useUsers } from '../Contexts/UserContext';
import { isDesktop, isMobile } from 'react-device-detect';
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../Utils/Firebase';
import { __getTimeStamp, __getTimeStampFromDate } from '../Utils/TimeFuncs';
import FrequencySelector from '../Widgets/Dashboard/QSOAdd/frequencySelector';

const QSOAdd = () => {
    const { user, saveActivity } = useUsers();
    const [formShow, setFormShow] = useState(false);
    const [qthCssClass, setQthCssClass] = useState('is-invalid');
    const [addDisabled, setAddDisabled] = useState(true);
    const [postDisabled, setPostDisabled] = useState(true);
    const [state, setState] = useState({
        message: '',
        user:{
            myQth: '',
            myName: ''
        },
        qth:{
            qth: '',
            date: '',
            callsign: '',
            opName: '',
            freq: '',
            ctcs: '',
            type: '/',
            mode: '/D'
        },
        qthList: []
    });

    const handleChange = (event) => {
        const field = event.target.name;
        console.log(event.target.value);
        if(field === "myQth" && event.target.value.length >= 6){ 
            setQthCssClass('is-valid'); 
            setAddDisabled(false); 
        }else{ 
            setQthCssClass("is-invalid"); 
            setAddDisabled(true);
        };
        let user = state.user;
        user[field] = event.target.value;
        setState({...state, user});
    }
    const handleQTHChange = (event) => {
        const field = event.target.name;
        let qth = state.qth;        
        qth[field] = event.target.value;
        setState({ ...state, qth });
    };

    const submitQTHForm = async (event) => {
        event.preventDefault();
        let qth = state.qth;
        setState((prevState) => ({
            ...prevState,
            qthList: [...prevState.qthList, qth],
            qth: {qth: '',date: '',callsign: '',opName: '',freq: '',ctcs: '',type: '/',mode: '/D'}
        }));
        if( postDisabled ){ setPostDisabled(false); };
        setFormShow(false);
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const _doc = await addDoc(collection(db, "qsos"), { time: __getTimeStamp(), uid: user.uid, callsign: state.user.myName, qth: state.user.myQth});
        const id = _doc.id;
        await state.qthList.map(async (qso) => { 
            let qsoID = await addDoc(collection(db, "qsos", id, "list"), {
                callsign: qso.callsign,
                ctcs: qso.ctcs,
                date: __getTimeStampFromDate(qso.date),
                freq: qso.freq,
                mode: qso.mode,
                opName: qso.opName,
                qth: qso.qth,
                type: qso.type,
                validated: false,
                handled: false
            });
            const usersRef = collection(db, "users");
            const usersQ = query(usersRef, where("username","==", qso.callsign));
            const targets = await getDocs(usersQ);
            if(targets.docs.length > 0){
                targets.docs.forEach(async (target) => {
                    await addDoc(collection(db,"notification"), {
                        type: 'qso',
                        link: '/qso/overview',
                        qsoContainer: id,
                        qsoId: qsoID,
                        target: target.id,
                        text: `${user.username} megjelölt egy QSO logban!`,
                        time: __getTimeStamp(),
                        visited: false
                    });   
                });
            };
        });
        setState((prevState) => ({...prevState,qthList: [],message: 'A logok sikeresen beküldve!'}));
        saveActivity();
    }

    const hideMessage = () => {
        setState((prevState) => ({
            ...prevState,
            message: false
        }));
    }

    useEffect(() => {
        let _user = state.user;
        _user['myName'] = user.username;
        setState({...state, _user});
        
        if(isMobile){
            getUserLocation();
            const location = MyStorage.local.get('location');
            console.log(location);
        };
    },[]);


    /*FrequencySelector*/
    const [showFreqSelector, setShowFreqSelector] = useState(false);
    const freqSelectorCallback = (response) => {
        let qth = state.qth;
        qth['freq'] = response;
        setState({...state, qth});
        setShowFreqSelector(false);
    }

    return (
        <Container className="feedItem">
            <Modal show={formShow} onHide={() => setFormShow(false)}>
                <Form onSubmit={submitQTHForm}>
                    <Modal.Header closeButton>
                        <Modal.Title><FaIcon type="solid" icon="plus" /> Új kapcsolat létrehozása</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                    
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Ellenállomás QTH kódja</Form.Label>
                            <Form.Control type="text" name="qth" className="dark" onChange={handleQTHChange} value={state.qth.qth !== undefined ? state.qth.qth : ''}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>Dátum</Form.Label>
                            <Form.Control type="datetime-local" className="dark" name="date" onChange={handleQTHChange} value={state.qth.date}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Hívójel</Form.Label>
                                    <Form.Control type="text" className="dark" name="callsign" onChange={handleQTHChange} value={state.qth.callsign}/>
                                </Col>
                                <Col>
                                    <Form.Label>Operátornév</Form.Label>
                                    <Form.Control type="text" className="dark" name="opName" onChange={handleQTHChange} value={state.qth.opName}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col style={{position:'relative'}}>
                                    <Form.Label>Frekvencia</Form.Label>
                                    <InputGroup>
                                        <Form.Control onClick={() => {setShowFreqSelector(!showFreqSelector)}} list="pmrs" type="text" className="dark" name="freq" onChange={handleQTHChange} value={state.qth.freq}/>
                                        <InputGroup.Text className="dark">MHz</InputGroup.Text>
                                    </InputGroup>
                                    <FrequencySelector show={showFreqSelector} callback={(item) => { freqSelectorCallback(item); }}/>
                                </Col>
                                <Col>
                                    <Form.Label>CT/DCS</Form.Label>
                                    <Form.Control type="text" className="dark" name="ctcs" onChange={handleQTHChange} value={state.qth.ctcs}/>
                                </Col>
                            </Row>                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Row>
                                <Col>
                                    <Form.Label>Típus</Form.Label>
                                    <Form.Select className="dark" name="type" onChange={handleQTHChange}>
                                        <option value="/">Stabil</option>
                                        <option value="/P">Kitelepült</option>
                                        <option value="/M">Mobil</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Módja</Form.Label>
                                    <Form.Select className="dark" name="mode" onChange={handleQTHChange}>
                                        <option value="/D">Közvetlen</option>
                                        <option value="/P">Papagájon át</option>
                                        <option value="/A">Átjátszón át</option>
                                    </Form.Select>
                                </Col>
                            </Row>                            
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="danger" onClick={() => {setFormShow(false);}}><FaIcon type="solid" icon="times"/> Mégse</Button>
                            <Button variant="success" type="submit"><FaIcon type="solid" icon="save"/> Mentés</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form>
            </Modal>
            
            <Form onSubmit={submitForm}>
                {(state.message) && <Alert variant="info" onClick={hideMessage}>{state?.message}</Alert>}
                {(!postDisabled) && <Alert variant="info">Nem mentett módosítások vannak! Ne felejtsd el beküldeni a <b>Beküldés</b> gombra kattintva!</Alert>}
                <Row className="fb-styled-card">
                    <Col md={4} xs={6}>
                        <Form.Group className="mb-3" controlId="qsoForm.myqth">
                            <Form.Label>QTH lokátor</Form.Label>
                            <Form.Control type="text" name="myQth" maxLength={6} className={`${qthCssClass} dark`} onChange={handleChange} value={state.user.myQth}/>
                        </Form.Group>
                    </Col>
                    <Col md={4} xs={6}>
                        <Form.Group className="mb-3" controlId="qsoForm.myName">
                            <Form.Label>Hívójelem</Form.Label>
                            <Form.Control type="text" className="dark" name="myName" onChange={handleChange} value={state.user.myName}/>
                        </Form.Group>
                    </Col>
                    <Col md={4} xs={12}>
                        <ButtonGroup className="qso-control-buttons">
                            <Button variant="dark" disabled={addDisabled} onClick={() => { setFormShow(true); }} type="button">
                                <FaIcon type="solid" icon="plus" /> Új kapcsolat
                            </Button>
                            <Button variant="success" disabled={postDisabled} type="submit">
                                <FaIcon type="solid" icon="upload" /> Beküldés
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col size={12}>
                    <Accordion className="dark qso-accordion">
                        {state.qthList.map((qth, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    <FaIcon type="solid" icon="user" />&nbsp;&nbsp;{qth.callsign} |&nbsp;
                                    {qth.freq} MHz, CT/DCS: {qth.ctcs}
                                </Accordion.Header>
                                <Accordion.Body>
                                    QTH: <b>{qth.qth}</b>, Távolság: <b>{getDistanceByQTH(state.user.myQth, qth.qth)}</b><br/>
                                    Időpont: <b>{qth.date}</b><br/>
                                    Típus: <b>{qth.type}</b>, Módja: <b>{qth.mode}</b>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default QSOAdd;