import React, {createContext, useState, useEffect, useContext} from "react";
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../Utils/Firebase";
import { MyStorage } from "../Utils/MyStorage";
import { __getTimeStamp } from "../Utils/TimeFuncs";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    
    const fetchUser = async (uid) => {
        const userRef = doc(db, "users", uid);
        const user = await getDoc(userRef);
        const userData = user.data();
        setUser(userData);
        return userData;
    }

    const createUser = async (uid, fields) => {
        const userRef = doc(db, "users", uid);
        await setDoc(userRef, fields);
        return true;
    }

    const checkUser = async (uid) => {
        const userRef = doc(db, "users", uid);
        const user = await getDoc(userRef);
        return user.exists();
    }

    const updateUser = async (uid, fields) => {
        const userRef = doc(db, "users", uid);
        updateDoc(userRef, fields);
        return true;
    }
    
    const logout = async (uid) => {
        await updateUser(uid, { lastAction: __getTimeStamp() });
        MyStorage.session.clear();
        return true;
    }

    const saveActivity = async () => {
        await updateUser(user.uid, { lastAction: __getTimeStamp() });
    }

    useEffect(() => {
        const unsubscribe = () => {
            const uid = MyStorage.session.get('uid');
            if(!uid) return;

            const userRef = doc(db, "users", uid);
            onSnapshot(userRef, (snapshot) => {
                const userData = snapshot.data();
                if(userData) setUser(userData);
            });
        };
        return () => unsubscribe();
    },[]);

    useEffect(() => {
        
    },[user]);

    return (
        <UserContext.Provider value={{ user, fetchUser, updateUser, logout, checkUser, createUser, saveActivity }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUsers = () => useContext(UserContext);