import React, { useEffect, useState } from 'react';
import CreateFeedItem from '../../Widgets/Dashboard/Feed/CreateFeedItem';
import { useUsers } from '../../Contexts/UserContext';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../Utils/Firebase';
import LazyFeedItem from '../../Widgets/Dashboard/Feed/LazyFeedItem';
import Ads from '../../Widgets/Global/Ads';
import { Col, Row } from 'react-bootstrap';

const Feed = () => {
    const { user, fetchUser } = useUsers();
    const [posts, setPosts] = useState([]);
    const [adsPositions, setAdsPositions] = useState([]);

    useEffect(() => {
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, orderBy("timestamp","desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedPosts = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setPosts(updatedPosts);

            const ads = [];
            for (let i = 0; i < updatedPosts.length; i++) {
                if (Math.random() < 0.9) {
                    ads.push(i);
                }
            }
            setAdsPositions(ads);
        });
        return () => unsubscribe();
    },[]);

    return (
        <div className="feedFrame">
            <CreateFeedItem />
            {(posts.map((post,index) => (
                <React.Fragment key={post.id}>
                    <LazyFeedItem key={post.id} post={post} />
                    <Row className="mb-3">
                        <Col md={12} xs={12} className="feedItem">
                            {adsPositions.includes(index) && <Ads hasSeparator={false}/>}
                        </Col>
                    </Row>
                </React.Fragment>
            )))}
        </div>
    );
};

export default Feed;