import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Ads from '../../../Widgets/Global/Ads';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';

const DLGetFile = () => {
    const { file } = useParams();
    const [ cat, setCat] = useState("");
    const [ f, setF] = useState([]);
    const [ time, setTime ] = useState(10);
    const [ btnText, setBtnText ] = useState("Várj 10 másodpercet");
    const [ disabled, setDisabled] = useState(true);

    useEffect(() => {
        const param = file.split(':');
        const getF = async () => {
            const _f = await getDoc(doc(db, "downloads", param[0], "files", param[1]));
            setF({id: _f.id, ..._f.data()});
        }; getF();


        const interval = setInterval(() => {
            setTime((prev) => {
                const next = prev - 1;
                setBtnText(`Várj ${next} másodpercet`);
                if(next === -1){
                    clearInterval(interval);
                    setBtnText("Letöltés");
                    setDisabled(false);
                }

                return next;
            });
        }, 1000);
    },[]);

    const getFile = () => {
        window.location.replace(f.path);
    };

    return (
        <div className="feedItem">
            <Row>
                <Col md={12}>
                    <Ads />
                    <center>
                        <Button onClick={getFile} variant="dark" disabled={disabled} > {btnText} </Button>
                    </center>
                    <Ads />
                </Col>
            </Row>
        </div>
    );
};

export default DLGetFile;