import React, { useEffect, useState } from "react";
import FaIcon from "../../Global/FaIcon";
import { Button, ButtonGroup, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatPerms } from "../../../Utils/Formatter";
import ImageUploadButton from "../../Global/ImageUploadButton";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../Utils/Firebase";
import { useChat } from "../../../Contexts/ChatContext";
import { getUserByID } from "../../../Utils/FirebaseFuncs";

const ProfileHeadMob = ({ user, callback, isMyOwn = true }) => {
    const { openChat } = useChat();
    const [coverModal, setCoverModal] = useState(false);
    const [coverImage, setCoverImage] = useState(false);

    const [profileModal, setProfileModal] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    
    const handleCoverChange = (event) => { setCoverImage(event); }
    const handleCoverSubmit = async (event) => {
        event.preventDefault();
        if (!coverImage) { alert("Kérlek, válassz egy file-t!"); return; };
        const userRef = doc(db, "users", user.profile.uid);
        await updateDoc(userRef, {cover: coverImage});
        setCoverModal(false);
        callback('updated:cover');
    }

    const handleProfileChange = (event) => { setProfileImage(event); }
    const handleProfileSubmit = async (event) => {
        try{
            event.preventDefault();
            if (!profileImage) { alert("Kérlek, válassz egy file-t!"); return; };
            const userRef = doc(db, "users", user.profile.uid);
            await updateDoc(userRef, {avatar: profileImage});
            setProfileModal(false);
            callback('updated:profile');
        }catch(e){
            alert("Legfeljebb 1 Mb méretű képet tölthetsz fel!");
        };
    }

    const followPress = () => {

    }
    return (
        <Container fluid className="profile-cover mobile">
            <div style={{ position: "relative" }}>
                <img
                    src={user.profile.cover}
                    alt="Cover"
                    className="img-fluid w-100"
                    style={{ marginTop: "-3px", height: "200px", objectFit: "cover" }}
                />
                {(isMyOwn) && (
                    <>
                        <Button variant="light" style={{ position: "absolute", bottom: "60px", right: "13px", zIndex: 1001 }} onClick={() => setCoverModal(true)}>
                            <FaIcon type="solid" icon="camera" /> <span className="d-none d-sm-none d-md-block">Borítókép szerkesztése</span>
                        </Button>
                        <Modal show={coverModal} onHide={() => setCoverModal(false)}>
                            <Form onSubmit={handleCoverSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Borítókép kiválasztása</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <ImageUploadButton callback={(data) => {handleCoverChange(data); }}/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonGroup>
                                        <Button variant="secondary" onClick={() => setCoverModal(false)}>Mégse</Button>
                                        <Button type="submit" variant="success" >Mentés</Button>
                                    </ButtonGroup>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </>
                )}
                <Row className="profile-picture-row" >
                    <Col md={5} xs={12} className="profile-picture">
                        <img
                            src={user.profile.avatar}
                            alt="Profile"
                            className="rounded-circle avatar"
                            style={{
                                width: "160px",
                                height: "160px",
                                border: "5px solid rgba(37,39,40,1)",
                                backgroundColor: '#000',
                                cursor: "pointer"
                            }}
                            onClick={() => { if (isMyOwn) { setProfileModal(true); }; }}
                        />
                        {(isMyOwn) && (
                        <>
                            <Modal show={profileModal} onHide={() => setProfileModal(false)}>
                                <Form onSubmit={handleProfileSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Profilkép kiválasztása</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ImageUploadButton callback={(data) => {handleProfileChange(data); }}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ButtonGroup>
                                            <Button variant="secondary" onClick={() => setProfileModal(false)}>Mégse</Button>
                                            <Button type="submit" variant="success" >Mentés</Button>
                                        </ButtonGroup>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </>
                    )}
                    </Col>
                    <Col md={7} xs={12} className="profile-meta">
                        <h4 className="font-weight-bold mb-0 profile-username">
                            {user.profile.displayName}
                        </h4>
                        <p className="profile-description">
                            <span dangerouslySetInnerHTML={{ __html:formatPerms(user.profile.perm)}} ></span> • {user.counts.logs} QSO log • {user.counts.follower} követő
                        </p>
                    </Col>
                </Row>
                <Row className="mt-3 profile-action-row">
                    <Col md="12" className="d-flex justify-content-end align-items-center">
                        {(isMyOwn) && (
                            <ButtonGroup>
                                <Button as={Link} to="/editProfile" variant="dark" className="mr-1"><FaIcon type="solid" icon="pencil" /></Button>
                                {/*<Button variant="light"><FaIcon type="solid" icon="cogs" /> Beállítások</Button>*/}
                            </ButtonGroup>
                        )}
                        {(!isMyOwn) && (
                            <ButtonGroup>
                                <Button onClick={() => {openChat(user.profile.uid);}} variant="secondary" className="mr-1"><FaIcon type="solid" icon="envelope" /></Button>
                            </ButtonGroup>
                        )}
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default ProfileHeadMob;