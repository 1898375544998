import React from 'react';
import { Alert } from 'react-bootstrap';

const WEditAuth = ({ user }) => {
    
    if(user.provider === "Google" || user.provider === "Facebook") return (
        <Alert variant="info">
            Jelenleg {user.provider} használatával vagy bejelentkezve, így ez a felület nem érhető el!
        </Alert>
    )
    
    return (
        <div>
            
        </div>
    );
};

export default WEditAuth;