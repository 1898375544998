import React, { useState } from 'react';
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FaIcon from '../../Widgets/Global/FaIcon';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Utils/Firebase';
import { Link } from 'react-router-dom';

const ForgotPW = () => {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({message: '', variant: 'danger'});
    const eventChange = (event) => {
        const { name, value } = event.target;
        setEmail(value);
    }

    const eventSubmit = (event) => {
        event.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setErrors({message: 'Amennyiben létezik az e-mail cím, kiküldtük a szükséges teendőket!', variant: "success"});
            })
            .catch((err) => {});
    }

    return (
        <Row className="justify-content-center">
            <Col md={{ span: 6, offset: 0 }}>
                {(errors?.message) && ( <Alert variant={errors?.variant}>{errors?.message}</Alert> )}
                <p>Amennyiben elfelejtetted a jelszavad, add meg a regisztrációkor kapott e-mail címed!</p>
                <Form onSubmit={eventSubmit} >
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><FaIcon type="solid" icon="at" /></InputGroup.Text>
                        <Form.Control
                            required
                            type="email"
                            placeholder="E-mail cím"
                            value={email}
                            onChange={eventChange}
                        />
                        <Button type="submit" variant="light"><FaIcon type="solid" icon="paper-plane" /></Button>
                    </InputGroup>
                    <hr />
                    <Button as={Link} to="/auth/register" variant="dark" style={{ marginRight: '5px' }}> Regisztráció </Button>
                    <Button as={Link} to="/auth" variant="dark" style={{ marginRight: '5px' }}> Belépés </Button>
                </Form>
            </Col>
        </Row>
    );
};

export default ForgotPW;