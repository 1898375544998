import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import FaIcon from '../../../Global/FaIcon';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../Utils/Firebase';

const WEquipment = ({ user }) => {
    const [state, setState] = useState({
        fields: {
            radio: '',
            antenna: '',
            freq: '',
            radios: [],
            antennas: [],
            freqs: []
        },
        errors: {}
    });
    const [initialized, setInit] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        let field = { ...state.fields };
        field[name] = value;
        setState((prev) => ({ ...prev, fields: field }));
    };

    const fetchDatas = async () => {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        let fields = state.fields;
        fields['radios'] = (userDoc.data().radios) ? JSON.parse(userDoc.data().radios) : [];
        fields['antennas'] = (userDoc.data().antennas) ? JSON.parse(userDoc.data().antennas) : [];
        fields['freqs'] = (userDoc.data().freqs) ? JSON.parse(userDoc.data().freqs) : [];
        setState({ ...state, fields });
        setInit(true);
    }

    useEffect(() => { fetchDatas(); }, []);

    const [unsaved, setUnsaved] = useState(false);

    /*Radios*/
    const initRadio = async () => {
        setState((prevState) => {
            const { radio, radios } = prevState.fields;
            if (radio && !radios.includes(radio)) { return { ...prevState, fields: { ...prevState.fields, radios: [...radios, radio], radio: '' } } };
            return prevState;
        });
        setUnsaved(true);
    }
    const removeRadio = async (radioToRemove) => {
        setState((prevState) => {
            const { radios } = prevState.fields;
            const updatedRadios = radios.filter((radio) => radio !== radioToRemove);
            return { ...prevState, fields: { ...prevState.fields, radios: updatedRadios } };
        });
        setUnsaved(true);
    }
    /*Antennas*/
    const initAntenna = async () => {
        setState((prevState) => {
            const { antenna, antennas } = prevState.fields;
            if (antenna && !antennas.includes(antenna)) { return { ...prevState, fields: { ...prevState.fields, antennas: [...antennas, antenna], antenna: '' } } };
            return prevState;
        });
        setUnsaved(true);
    };
    const removeAntenna = async (antennaToRemove) => {
        setState((prevState) => {
            const { antennas } = prevState.fields;
            const updatedAntennas = antennas.filter((ant) => ant !== antennaToRemove);
            return { ...prevState, fields: { ...prevState.fields, antennas: updatedAntennas } };
        });
        setUnsaved(true);
    };

    /*Freqs*/
    const initFreqs = async () => {
        setState((prevState) => {
            const { freq, freqs } = prevState.fields;
            if (freq && !freqs.includes(freq)) { return { ...prevState, fields: { ...prevState.fields, freqs: [...freqs, freq], freq: '' } } };
            return prevState;
        });
        setUnsaved(true);
    };
    const removeFreq = async (freqToRemove) => {
        setState((prevState) => {
            const { freqs } = prevState.fields;
            const updatedFreqs = freqs.filter((ant) => ant !== freqToRemove);
            return { ...prevState, fields: { ...prevState.fields, freqs: updatedFreqs } };
        });
        setUnsaved(true);
    };

    useEffect(() => {
        if (initialized && unsaved) {
            submitEquipments(false);
        };
    }, [state.fields.radios, state.fields.antennas, state.fields.freqs]);

    /*Submit*/
    const submitEquipments = async (event) => {
        if (event) event.preventDefault();
        let fields = {
            radios: JSON.stringify(state.fields.radios),
            antennas: JSON.stringify(state.fields.antennas),
            freqs: JSON.stringify(state.fields.freqs)
        };

        await updateDoc(doc(db,"users", user.uid), fields);
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                radios: [],
                antennas: [],
                freqs: [],
            },
            errors: {
                personaMessage: {
                    text: "OK",
                    variant: "success",
                },
            },
        }));
        setUnsaved(false);
        fetchDatas();
    }

    return (
        <Form onSubmit={submitEquipments} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <InputGroup>
                <InputGroup.Text className="dark" id="radio">Rádió hozzáadása</InputGroup.Text>
                <Form.Control type="text" name="radio" value={state.fields.radio} aria-describedby='radio' onChange={handleChange} className="dark" />
                <Button onClick={initRadio} variant="dark"><FaIcon type="light" icon="plus" /></Button>
            </InputGroup>
            <InputGroup className="mb-3">
                {(state.fields.radios.map((radio, index) => (
                    <InputGroup.Text key={index} className="light" id="radio" style={{ cursor: "pointer" }} onClick={() => { removeRadio(radio); }}>
                        {radio}
                    </InputGroup.Text>
                )))}
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className="dark" id="antenna">Antenna hozzáadása</InputGroup.Text>
                <Form.Control type="text" name="antenna" value={state.fields.antenna} aria-describedby='antenna' onChange={handleChange} className="dark" />
                <Button onClick={initAntenna} variant="dark"><FaIcon type="light" icon="plus" /></Button>
            </InputGroup>
            <InputGroup className="mb-3">
                {(state.fields.antennas.map((ant, index) => (
                    <InputGroup.Text key={index} className="light" id="radio" style={{ cursor: "pointer" }} onClick={() => { removeAntenna(ant); }}>
                        {ant}
                    </InputGroup.Text>
                )))}
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className="dark" id="freq">Frekvencia hozzáadása</InputGroup.Text>
                <Form.Control type="text" name="freq" value={state.fields.freq} aria-describedby='freq' onChange={handleChange} className="dark" />
                <Button onClick={initFreqs} variant="dark"><FaIcon type="light" icon="plus" /></Button>
            </InputGroup>
            <InputGroup className="mb-3">
                {(state.fields.freqs.map((freq, index) => (
                    <InputGroup.Text key={index} className="light" id="radio" style={{ cursor: "pointer" }} onClick={() => { removeFreq(freq); }}>
                        {freq}
                    </InputGroup.Text>
                )))}
            </InputGroup>
            <ButtonGroup style={{ marginTop: '7px' }} className="mb-2">
                <Button type="submit" variant="success">Mentés</Button>
            </ButtonGroup>
        </Form>
    );
};

export default WEquipment;