import { Icon } from "leaflet";

const def = new Icon({
    iconUrl: "https://static.446.hu/images/markers/default.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});


const parrot_a = new Icon({
    iconUrl: "https://static.446.hu/images/markers/parrot_active.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const parrot_i = new Icon({
    iconUrl: "https://static.446.hu/images/markers/parrot_inactive.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const handradio = new Icon({
    iconUrl: "https://static.446.hu/images/markers/mobile_radio.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const deskradio = new Icon({
    iconUrl: "https://static.446.hu/images/markers/desktop_radio.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const tower = new Icon({
    iconUrl: "https://static.446.hu/images/markers/station.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const temp = new Icon({
    iconUrl: "https://static.446.hu/images/markers/temp_marker.png",
    iconSize: [30,30],
    iconAnchor: [15,15],
    popupAnchor: [0,0]
});

const ballon = new Icon({
    iconUrl: "https://static.446.hu/images/markers/balloon.png",
    iconSize: [20,40],
    iconAnchor: [10,20],
    popupAnchor: [0,0]
});

const ISS = new Icon({
    iconUrl: "https://static.446.hu/images/markers/iss-25544.png",
    iconSize: [64,64],
    iconAnchor: [32,32],
    popupAnchor: [0,0]
})

const IconSet = {
    default: def,
    handradio: handradio,
    deskradio: deskradio,
    tower: tower,
    parrot_a: parrot_a,
    parrot_i: parrot_i,
    temp: temp,
    ballon: ballon,
    ISS: ISS
}

export default IconSet;