import React, { useEffect, useState } from 'react';
import { MyStorage } from '../Utils/MyStorage';
import { UserProvider, useUsers } from '../Contexts/UserContext';
import LoadingIndicator from '../Widgets/Global/LoadingIndicator';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AuthFrame from './Auth/__Frame';
import DashboardFrame from './Dashboard/__Frame';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <UserProvider>
                <Routes>
                    <Route element={<IsGuest />}>
                        <Route path="/auth/*" element={<AuthFrame />} />
                    </Route>                
                    <Route element={<IsMember />}>
                        <Route path="/*" element={<DashboardFrame />} />
                    </Route>
                </Routes>
            </UserProvider>
        </div>
    );
};

export default Dashboard;

const IsGuest = () => {
    const uid = MyStorage.session.get('uid');
    if(uid !== null) { return <Navigate to="/" /> };
    return <Outlet />
}

const IsMember = () => {
    const uid = MyStorage.session.get('uid');
    const { fetchUser } = useUsers();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const _fetchUser = async () => {
            if (uid) { await fetchUser(uid); };
            setLoading(false);
        };
        _fetchUser();
    }, [uid, fetchUser]);
    if(loading) { return <LoadingIndicator /> };
    if(uid === null) { return <Navigate to="/landing" /> };
    return <Outlet />;
}

const IsAdmin = () => {
    const { user } = useUsers();
    if(user.perm < 97){ return <Navigate to="/" /> };
    return <Outlet />
}