import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';

const W2FA = () => {
    const [enabled2fa, setEnabled2fa] = useState(false);
    const switch2FA = (event) => {
        setEnabled2fa(event.target.checked);
    }

    return (
        <Form>
            <Form.Check disabled type="switch" onChange={switch2FA} checked={enabled2fa} id="custom-switch" label={`Kétfaktoros hitelesítés ${(enabled2fa) ? "BEKAPCSOLVA" : "KIKAPCSOLVA"}`} />
            {(enabled2fa) && (
                <div style={{marginTop:'5px'}}>
                    
                </div>
            )}
            <Alert variant="info">Hamarosan...</Alert>
        </Form>
    );
};

export default W2FA;