import React, { useEffect, useRef, useState } from 'react';
import { useChat } from '../../../Contexts/ChatContext';
import { getUserByID } from '../../../Utils/FirebaseFuncs';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FaIcon from '../../Global/FaIcon';
import { __getTimeStamp, getDiff } from '../../../Utils/TimeFuncs';
import TBUserPicture from '../TopBar/TBUserPicture';
import { useUsers } from '../../../Contexts/UserContext';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../Utils/Firebase';
import { createPersonsKey } from '../../../Utils/Utils';

const ChatApp = () => {
    const { openedChats, closeChat } = useChat();
    return (
        <div className="chat-app">
            {(openedChats.map((user, index) => (
                <ChatWindow uid={user} index={index} key={index} />
            )))}
        </div>
    );
};

const ChatWindow = ({uid, index}) => {
    const endOfMessagesRef = useRef(null);
    const { user } = useUsers();
    const { closeChat } = useChat();
    const [_user, setUser] = useState(null)
    const [room, setRoom] = useState(false);
    const [chatState, setChatState] = useState("normal");
    const [messages, setMessages] = useState([]);
    const [_message, _setMessage] = useState('');
    
    useEffect(() => { 
        const getUser = async () => { 
            const u = await getUserByID(uid); 
            setUser(u); 
        }; 
        const fetchChatRoom = async () => {
            const q = query(collection(db,"chatRooms"), where("personsKey","==",createPersonsKey(user.uid, uid)));
            const querySnapshots = await Promise.all([getDocs(q)]);
            let foundRoom = null;
            querySnapshots.forEach(snapshot => {
                if (!foundRoom && !snapshot.empty) {
                    foundRoom = snapshot.docs[0];
                }
            });
            if(foundRoom) { 
                setRoom(foundRoom.id)
            };
        };
        getUser(); 
        fetchChatRoom();
    },[index]);

    useEffect(() => {
        if(!room) { setMessages([]); return; };
        const messagesRef = collection(db, 'chatRooms', room, 'messages');
        const messagesQuery = query(messagesRef, orderBy("timestamp","desc"));
        const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {

            console.log(room);


            const newMessages = snapshot.docs.map(doc => ({id: doc.id,...doc.data()}));
            setMessages(newMessages);
        });
        return () => unsubscribe();
    },[room]);

    useEffect(() => { if (endOfMessagesRef.current) { endOfMessagesRef.current.scrollIntoView({ behavior: "instant", block: "end" }); } },[messages]);


    const msgOnChange = (event) => { _setMessage(event.target.value); }
    const onKeyUp = (event) => {
        if(event.key === "Enter" && _message.length > 0){ sendMessage(); };
        if(event.key === "Escape"){ _setMessage(''); closeChat(uid); };
    }
    const sendMessage = async (event) => {
        if(!room){
            const struct = { persons: [ user.uid, uid], personsKey: createPersonsKey(user.uid, uid), lastMessage: __getTimeStamp()};
            const structRef = collection(db, "chatRooms");
            const roomID = await addDoc(structRef, struct);
            setRoom(roomID.id);
            await sendMessageToRoom(roomID.id);
        }else{
            await sendMessageToRoom(room);
        };
        _setMessage('');
    }    
    const sendMessageToRoom = async (roomID) => { 
        const ts = __getTimeStamp();
        const msg = { sender: user.uid, message: _message, timestamp: ts, unReaded: true}; 
        const msgRef = collection(db, "chatRooms", roomID, "messages"); 
        await addDoc(msgRef, msg); 
        await updateDoc(doc(db,  "chatRooms", roomID), { lastMessage: ts});
    }
    return (
        <div key={index} className={`chat-window ${chatState}`}>
            <div className="chat-header">
                <Row>
                    <Col md={2} xs={2} onClick={() => setChatState('normal')}>
                        <TBUserPicture user={_user} w={40} h={40}/>
                    </Col>
                    <Col md={7} xs={7} className="user-block">
                        <b>{_user?.username}</b><br/>
                        <span className="small" style={{position:'relative',top:'-5px'}}>Elérhető {getDiff(_user?.lastAction)}</span>
                    </Col>
                    <Col md={3} xs={3} className="text-right control-block">
                        <Button size="sm" variant="chat" onClick={() => { setChatState('minimized');} }><FaIcon type="light" icon="window-minimize" css="text-white"/></Button>
                        <Button size="sm" variant="chat" onClick={() => { closeChat(uid); }}><FaIcon type="light" icon="times" css="text-white"/></Button>
                    </Col>
                </Row>
            </div>
            <div className="chat-body">
                {messages.sort((a,b) => (a.timestamp - b.timestamp)).map((message,index) => (<MessageItem room={room} message={message} key={index} />))}
                <div style={{float:'left', clear: 'both'}} ref={(el) => { endOfMessagesRef.current = el; }} />
            </div>
            <div className="chat-footer">
                <InputGroup>
                    <Form.Control type="text" value={_message} onChange={msgOnChange} onKeyUp={onKeyUp} placeholder="Írj üzenetet..." className="dark"/>
                    <Button variant="dark" onClick={sendMessage} ><FaIcon type="light" icon="paper-plane-top" /></Button>
                </InputGroup>
            </div>
        </div>
    )
}

const MessageItem = ({ room, message }) => {
    const { user } = useUsers();
    const [received, setReceived] = useState(false);
    const [readMark, setReadMark] = useState("");

    useEffect(() => {
        const setReaded = async (room, messageID) => { await updateDoc(doc(db, "chatRooms", room, "messages", messageID), { unReaded: false }); }
        setReceived((message.sender === user.uid) ? false : true);
        if(message.sender !== user.uid && message.unReaded === true){ setReaded(room, message.id); };
        if(message.sender === user.uid && message.unReaded){ 
            setReadMark(<FaIcon type="light" icon="eye-slash" />); 
            const watchMessage = async (room, messageID) => {
                const msgsRef = collection(db, "chatRooms", room, "messages");
                const msgsQ = query(msgsRef, where("sender","==",user.uid));
                const unsubscribe = onSnapshot(msgsQ, (snapshot) => {
                    const changes = snapshot.docChanges();
                    if(changes.length > 0){
                        changes.forEach((change) => {
                            if(change.type === "modified" && change.doc.data().unReaded === false){
                                setReadMark(<FaIcon type="light" icon="eye" />); 
                            };
                        })
                    };
                });
                return () => unsubscribe();
            };
            watchMessage(room, message.id);
        };
        if(message.sender === user.uid && !message.unReaded){ setReadMark(<FaIcon type="light" icon="eye" />); };
    },[]);

    return (
        <div className={`message ${(received) ? "received" : "sended"}`}>
            <span>
                {message.message}<br/>
                <span className="small" style={{position:"relative",top:"0px"}}>
                    {getDiff(message.timestamp)} {readMark}
                </span>    
            </span>
            
        </div>
    )
}
export default ChatApp;