import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "./Firebase"


export const updateUser = (uid, fields) => {
    const userRef = doc(db, "users", uid);    
    updateDoc(userRef, fields);
}
export const getUserByID = async (uid) => {
    const userRef = doc(db,"users",uid);
    const user = await getDoc(userRef);
    return user.data();
}

/*feed*/
