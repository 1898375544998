import React from 'react';
import { motion } from 'framer-motion';
import { Col, Row } from 'react-bootstrap';

const PubIsmerteto = () => {
    return (
        <>
        <Row id="content" className="row mb-3">
			<Col md={{ order: 1, span: 6}} xs={{ order: 1, span: 12}}>
				<motion.h3 initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0, duration: 2 }}} className="ctx-title">Ismertető</motion.h3>
				<motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 2 }}}>
                    <p className="ctx-text">Jelenleg egy olyan weboldalra tévedtél, ami a PMR (Public Mobile Radio), azaz Személyes használatú rádiózással foglalkozik, ennek ellenére szívesen látunk "Hívójeles" rádiósokat is.</p>
				    <p className="ctx-text">Lehetőséget szeretnénk adni egy kiterjedt rádiós közösségnek, ahol nem számít az NMHH vizsga, ahol Mindenki barát. Ezen felül reméljük, hogy minél több új tag csatlakozik a közösségünkhöz és népszerűsíteni tudjuk a rádiózást, kortól és nemtől függetlenül, légy itthon, vagy hazánk határain túl!</p>
				    <p className="ctx-text">Az oldalra történő regisztrációkor a webhely segít neked a hívójel kiosztásában és elmagyarázza, milyen adatokra, miért van szükség. Hidd el, mindennek oka van!. Emellett az oldalon rögzítheted a kapcsolatfelvételt QSO és a kitelepüléseid is. Az oldalon egy komplex térkép alkalmazás is fut, (Vendégként is bepillantást nyerhetsz), ahol láthatod az eseményeket, kitelepüléseket, átjátszókat, stb.</p>
				    <p className="ctx-text">Nem titkolt célunk, hogy érkezzen Androidra és iOSra egy app, amivel elérheted a rendszerünket telefonról, és könnyedén rögzítheted a QSO-t.</p>
                </motion.div>
			</Col>
			<Col md={{ order: 2, span: 6}} xs={{ order: 2, span: 12}} className="align-self-center">
				<motion.img initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 2 }}} alt="antenna-kep" className="img-responsive" src={`https://static.446.hu/images/hamradio_1.jpg`} />
            </Col>
		</Row>
        <Row id="content-1" className="row mb-3">
            <Col md={{ order:1, span: 6}} xs={{ order: 2, span: 12}} className="align-self-center">
                <motion.img initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.4, duration: 2 }}} alt="446.hu logo" className="img-responsive" src={`https://static.446.hu/images/446logo.png`} />
            </Col>
            <Col md={{ order:2, span: 6}} xs={{ order: 1, span: 12}}>
                <motion.h3 initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0, duration: 2 }}} className="ctx-title">Céljaink</motion.h3>
                <motion.div initial={{ y: 20, opacity: 0}} whileInView={{ y: 0, opacity: 1, transition: { delay: 0.2, duration: 2 }}}>
                    <p className="ctx-text">Ha már ide tévedtél, és tudod, mi az a 446, akkor azt is tudod, mi lehet a célkitűzésünk, de azért itt van néhány mondatba szedve.</p>
                    <p className="ctx-text">Legfontosabb, hogy a már megszerzett közösséget összefogjuk és egy IT hátteret biztosítsunk, azoknak, akik szeretik logolni, hogy mikor, és kivel sikerült kapcsolatot felvenniük és összefogjuk a "Hívójeles" és nem hívójeles rádiósokat egymáshoz.</p>
                    <p className="ctx-text">Az biztos, hogy nincs és nem is lesz könnyű dolgunk, hiszen egyes szokásokat megváltoztatni igen nehéz, de törekszünk arra, hogy ez a közösség is fejlődjön, ahogy minden más.</p>
                    <p className="ctx-text">Szeretném ha nem úgy kéne vadászni az állomásokat, mintha mamutra akarnék lőni. Szerintem ezzel mindenki így van. Célunk az is, hogy elérjük, hogy ne csak üljünk a rádió mellett és várjuk a bejelentkezéseket, hanem, hogy hívjunk is. Szóval törjük meg a csendet, ragadjuk meg a mikrofont, és szóljunk bele.</p>
                    <p className="ctx-text">Rádiót a kézbe, hangokat az éterbe; szóljon a Rock'n'Roll!</p>
                </motion.div>
            </Col>			
        </Row>
        </>
    );
};

export default PubIsmerteto;