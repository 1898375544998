import React from 'react';

const freqs = [
    {name: "PMR-1", freq: "446.00625"},
    {name: "PMR-2", freq: "446.01875"},
    {name: "PMR-3", freq: "446.03125"},
    {name: "PMR-4", freq: "446.04375"},
    {name: "PMR-5", freq: "446.05625"},
    {name: "PMR-6", freq: "446.06875"},
    {name: "PMR-7", freq: "446.08125"},
    {name: "PMR-8", freq: "446.09375"},
    {name: "PMR-9", freq: "446.10625"},
    {name: "PMR-10", freq: "446.11875"},
    {name: "PMR-11", freq: "446.13125"},
    {name: "PMR-12", freq: "446.14375"},
    {name: "PMR-13", freq: "446.15625"},
    {name: "PMR-14", freq: "446.16875"},
    {name: "PMR-15", freq: "446.18125"},
    {name: "PMR-16", freq: "446.19375"},
]

const FrequencySelector = ({ show, callback}) => {
    return (
        <div style={{display:`${show ? "block" : "none"}`,position:'absolute',top: '70px',left:'10px',backgroundColor:'rgba(0,0,0,.75)',width:'calc(100% - 20px)',padding:'10px',height:"125px",overflowY:"auto"}}>
            {(freqs.map((item) => (
                <div style={{cursor:'pointer'}} onClick={() => { callback(item.freq); }}>{item.name} - {item.freq} MHz</div>
            )))}
        </div>
    );
};

export default FrequencySelector;