import React from 'react';
import { Col, Container, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import WEditProfile from '../../Widgets/Dashboard/Profile/ProfileEdit/WEditProfile';
import { useUsers } from '../../Contexts/UserContext';
import WEquipment from '../../Widgets/Dashboard/Profile/ProfileEdit/WEquipment';
import WEditAuth from '../../Widgets/Dashboard/Profile/ProfileEdit/WEditAuth';
import W2FA from '../../Widgets/Dashboard/Profile/ProfileEdit/W2FA';

const ProfileEdit = () => {
    const { user } = useUsers();

    return (
        <Container className="feedItem">
            <Tab.Container className="mb-3" defaultActiveKey="profile">
                <Row>
                    <Col md={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="profile">Profilom</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="equipment">Felszerelés</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="auth">Belépés</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2fa">2FA belépés</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col md={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="profile"><WEditProfile user={user} /></Tab.Pane>
                        </Tab.Content>
                        <Tab.Content>
                            <Tab.Pane eventKey="equipment"><WEquipment user={user} /></Tab.Pane>
                        </Tab.Content>
                        <Tab.Content>
                            <Tab.Pane eventKey="auth"><WEditAuth user={user} /></Tab.Pane>
                        </Tab.Content>
                        <Tab.Content>
                            <Tab.Pane eventKey="2fa"><W2FA user={user} /></Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    );
};

export default ProfileEdit;