import CryptoJS from "crypto-js";
export const MyStorage = {
    local: {
        get(key){
            let hash = localStorage.getItem(`446.hu/${key}`)
            if(hash === null) return null;
            return CryptoJS.AES.decrypt(hash, `446.hu#2024#&#`).toString(CryptoJS.enc.Utf8);
        },
        put(key,value){
            return localStorage.setItem(`446.hu/${key}`, CryptoJS.AES.encrypt(value, `446.hu#2024#&#`));
        },
        remove(key){
            return localStorage.removeItem(`app446hu/storage/${key}`);
        }
    },
    session: {
        get(key){
            let hash = sessionStorage.getItem(`446.hu/${key}`);
            if(hash === null) return null;
            return CryptoJS.AES.decrypt(hash, `446.hu#2024#&#`).toString(CryptoJS.enc.Utf8);
        },
        put(key, val) {
            return sessionStorage.setItem(`446.hu/${key}`, CryptoJS.AES.encrypt(val, `446.hu#2024#&#`));
        },
        remove(key) {
            return sessionStorage.removeItem(`446.hu/${key}`);
        },
        clear() {
            sessionStorage.clear();
        }
    }
}