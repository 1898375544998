import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { checkImageIsAllowed } from '../../Utils/Utils';


const ImageUploadButton = ({ callback }) => {
    const avatarRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const handleFieldClick = () => {
        if(avatarRef.current){ avatarRef.current.click(); }
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file && checkImageIsAllowed(file)) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => { 
                setPreview(reader.result); 
                callback(reader.result);
            };
            reader.readAsDataURL(file);
        }else{
            setError("Nem engedélyezett fájltípus!");
        }
    }

    return (
        <div className="text-center upload-circle-base mb-5">
            <div className="upload-circle" onClick={handleFieldClick}>
                {preview ? (<img src={preview} alt="Preview" className="preview-image" />) : ( <> Képfeltöltés </> )}
                <Form.Control ref={avatarRef} type="file" name="avatar" style={{ display: 'none' }} onChange={handleFileChange} />
            </div>
        </div>
    );
};

export default ImageUploadButton;