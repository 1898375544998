import { useEffect, useRef, useState } from "react";
import FeedItem from "./FeedItem";

const LazyFeedItem = ({ post }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            }, { threshold: 0.1 }
        );
        if (ref.current) { observer.observe(ref.current); };
        return () => { if (ref.current) { observer.disconnect(); }; };
    }, []);
    return (
        <div ref={ref}>
            {isVisible && <FeedItem post={post} />}
        </div>
    );
};
export default LazyFeedItem;