import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MapContainer, TileLayer, useMapEvent, LayersControl } from 'react-leaflet';
import MarkerCombined from './Map/MarkerCombined';
import MapCTXMenu from './Map/MapCTXMenu';
import HandradioModal from './Map/Modals/HandRadioModal';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../Utils/Firebase';
import DeskradioModal from './Map/Modals/DeskRadioModal';
import ParrotModal from './Map/Modals/ParrotModal';
import TowerModal from './Map/Modals/TowerModal';
import BalloonMarker from './Map/BalloonMarker';
import ISSMarker from './Map/ISSMarker';


const Map = ({ zoom = 8 }) => {
    const { BaseLayer } = LayersControl;
    const [contextmenu, setContextMenu] = useState({
        top: '0px',
        left: '0px',
        display: 'none',
        show: false,
        coords: { lat: 0.0, lon: 0.0 }
    });
    const [markers, setMarkers] = useState([]);
    const [handRadio, setHandRadio] = useState(false);
    const [deskRadio, setDeskRadio] = useState(false);
    const [parrot, setParrot] = useState(false);
    const [tower, setTower] = useState(false);

    const [balloons, setBalloons] = useState([]);
    const fetchBalloon = async () => {
        setBalloons([]);
        const response = await fetch("https://api.v2.sondehub.org/sondes/telemetry?duration=15s");
        const data = await response.json();
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const subKey = Object.keys(data[key]);
            const item = data[key][subKey[subKey.length - 1]];
            let bArr = { id: key, item };
            setBalloons((prev) => [...prev, bArr]);
        };
    }

    const [issPosition, setISSPosition] = useState({});
    const [issPath, setISSPath] = useState([]);
    const fetchISS = async () => {
        const iss = await fetch("https://api.wheretheiss.at/v1/satellites/25544");
        const issJson = await iss.json();
        setISSPosition({ lat: issJson.latitude, lon: issJson.longitude, velocity: issJson.velocity});        
    }
    /*useEffect(() => {
        const calcPath = (latitude, longitude, velocity, timeOffset) => {
            const earthRadius = (6125 * 10);
            const deltaLat = (velocity / earthRadius) * (timeOffset / 60) * Math.cos(latitude * Math.PI / 180);
            const deltaLon = (velocity / earthRadius) * (timeOffset / 60) / Math.cos(latitude * Math.PI / 180);
            const newLatitude = latitude + deltaLat;
            const newLongitude = longitude + deltaLon;
            const lat = Math.max(Math.min(newLatitude, 90), -90);
            const lon = (newLongitude + 180) % 360 - 180;
            return [lat, lon];
        }
        const calcISSPath = async () => {
            setISSPath([]);
            const path = [];
            const totalSteps = 144;
            let currentLatitude = issPosition.lat;
            let currentLongitude = issPosition.lon;
            
            for (let i = 0; i <= totalSteps; i++) {
                const position = calcPath( currentLatitude, currentLongitude, issPosition.velocity, i * 10 );
                path.push([position[0], position[1]]);
                currentLatitude = position[0];
                currentLongitude = position[1];
            };
            setISSPath(path);
        };
        calcISSPath();
    },[issPosition]);*/

    useEffect(() => {
        const markerRef = collection(db, "markers");
        const unsubscribe = onSnapshot(markerRef, (snapshot) => {
            setMarkers([]);
            const markersData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setMarkers(markersData);
        });

        document.getElementById("map").focus();
        const handleContextMenu = (event) => { event.preventDefault(); };
        document.addEventListener('contextmenu', handleContextMenu);

        fetchBalloon();
        const interval = setInterval(async () => { fetchBalloon(); }, 30000);

        fetchISS();
        const issInterval = setInterval(async () => { fetchISS(); }, 10000);

        return () => {
            unsubscribe();
            document.removeEventListener('contextmenu', handleContextMenu);
            clearInterval(interval);
            clearInterval(issInterval);
        };
    }, []);

    const MapEvents = () => {
        useMapEvent('click', (e) => {
            if (isMobile && contextmenu.display === "none") {
                setContextMenu({ top: e.containerPoint.y, left: e.containerPoint.x, display: 'block', coords: { lat: e.latlng.lat, lon: e.latlng.lng } });
            } else {
                setContextMenu({ top: 0, left: 0, display: 'none', coords: { lat: 0.0, lon: 0.0 } });
            }
        });
        useMapEvent('contextmenu', (e) => {
            setContextMenu({ top: e.containerPoint.y, left: e.containerPoint.x, display: 'block', coords: { lat: e.latlng.lat, lon: e.latlng.lng } });
        })
        return null;
    }

    const eventAction = (item) => {
        setContextMenu({ top: 0, left: 0, display: 'none', coords: contextmenu.coords });
        switch (item) {
            case "handradio": setHandRadio(true); break;
            case "deskradio": setDeskRadio(true); break;
            case "parrot": setParrot(true); break;
            case "tower": setTower(true); break;
            case "markerContextMenu": break;
            default: break;
        };
    }
    const eventCallback = (item) => {
        setContextMenu({ top: 0, left: 0, display: 'none', coords: { lat: 0.0, lon: 0.0 } });
        switch (item) {
            case "handradio": setHandRadio(false); break;
            case "deskradio": setDeskRadio(false); break;
            case "parrot": setParrot(false); break;
            case "tower": setTower(false); break;
            default: break;
        };

    }

    return (
        <>
            <MapContainer id="map" tabIndex={0} center={[47.1628, 19.5036]} zoom={zoom} scrollWheelZoom={true} style={{ width: 'calc(100vw - 30px)', height: 'calc(100vh - 125px)' }}>
                <LayersControl>
                    <BaseLayer checked name="OpenSteetMap">
                        <TileLayer attribution='' url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    </BaseLayer>
                    <BaseLayer name="Hybrid">
                        <TileLayer attribution='' url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png" />
                    </BaseLayer>
                </LayersControl>
                <MapEvents />
                {markers.map(marker => (<MarkerCombined key={marker.id} args={marker} eventAction={(item) => eventAction(item)} callback={(item) => eventCallback(item)} />))}
                {(balloons.map((ballon) => (<BalloonMarker args={ballon} />)))}
                {(issPosition.lat) && (issPosition.lon) && (<ISSMarker pos={issPosition} path={issPath} />)}
            </MapContainer>
            <MapCTXMenu pos={{ top: contextmenu.top, left: contextmenu.left, display: contextmenu.display }} eventAction={(item) => eventAction(item)} />
            <HandradioModal show={handRadio} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)} />
            <DeskradioModal show={deskRadio} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)} />
            <ParrotModal show={parrot} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)} />
            <TowerModal show={tower} pos={{ lat: contextmenu.coords.lat, lon: contextmenu.coords.lon }} callback={(item) => eventCallback(item)} />


        </>
    );
};

export default Map;
