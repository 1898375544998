import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DLLIstCategory from './DLLIstCategory';
import DLListFiles from './DLListFiles';
import DLViewFile from './DLViewFile';
import { ToastContainer } from 'react-bootstrap';

const DLViewIndex = () => {
    return (
        <div className="feedItem">
            <Routes>
                <Route path="/" element={<DLLIstCategory />} />
                <Route path="/:cat" element={<DLListFiles />} />
                <Route path="/:cat/:file" element={<DLViewFile />} />
            </Routes>
            <ToastContainer />
        </div>
    );
};

export default DLViewIndex;