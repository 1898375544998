import { Editor } from '@tinymce/tinymce-react';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, InputGroup } from 'react-bootstrap';
import { db } from '../../../../Utils/Firebase';
import { useUsers } from '../../../../Contexts/UserContext';

const ParrotModal = ({ show, pos, callback, method = "new", params }) => {
    const { user } = useUsers();
    const editorRef = useRef(null);
    const [id, setID] = useState(-1);
    const [state, setState] = useState({
        fields: {
            markerTitle: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            freq: {
                value: '',
                error: '',
                css: 'is-invalid'
            },
            ctcs: {
                value: '',
                error: '',
                css: 'is-invalid'
            }
        }
    });

    useEffect(() => {
        if(method === "edit"){
            console.log(params);
            setState({
                fields: {
                    markerTitle: { value: params?.title, error: '', css: "is-valid" },
                    freq: { value: params?.freq, error: '', css: "is-valid" },
                    ctcs: { value: params?.code?.ctcs, error: '', css: "is-valid" }
                }
            });
            setID(params?.id);
            if(editorRef.current){ editorRef.current.setContent(params.description); }
        }
    },[method, editorRef.current]);

    const handleChange = (e) => {
        const field = e.target.name;
        let val = e.target.value;
        let fields = state.fields;
        if (field === "freq") {
            if (val.length === 3 && !val.includes(".")) { val += "."; };
            fields[field]['css'] = (val >= 7) ? 'is-valid' : 'is-invalid';
        } else if (field === "ctcs") {
            if (val.length === 3 && !val.includes(".")) { val += "."; };
            fields[field]['css'] = (val >= 5) ? 'is-valid' : 'is-invalid';
        } else if (field === "markerTitle") {
            fields[field]['css'] = (val >= 6) ? 'is-valid' : 'is-invalid';
        };
        fields[field]['value'] = val;
        setState({ ...state, fields });
    }

    const eventSubmit = async (e) => {
        e.preventDefault();
        if(method === "new"){            
            const fields = {
                type: 'parrot',
                lat: pos.lat,
                lon: pos.lon,
                title: state.fields.markerTitle.value,
                freq: state.fields.freq.value,
                ctcs: state.fields.ctcs.value,
                description: editorRef.current.getContent(),
                isActive: true,
                isWorking: true,
                owner: user.uid
            };
            await addDoc(collection(db, "markers"), fields);
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['freq']['value'] = '';
            f['ctcs']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            f['freq']['css'] = 'is-invalid';
            f['ctcs']['css'] = 'is-invalid';
            editorRef.current.setContent('');
            setState({ ...state, f });
            callback('parrot');
        }else if(method === "edit"){
            const fields = {
                type: 'parrot',
                title: state.fields.markerTitle.value,
                freq: state.fields.freq.value,
                ctcs: state.fields.ctcs.value,
                description: editorRef.current.getContent(),
                owner: user.uid
            };
            await updateDoc(doc(db, "markers", id), fields);
            
            let f = state.fields;
            f['markerTitle']['value'] = '';
            f['freq']['value'] = '';
            f['ctcs']['value'] = '';
            f['markerTitle']['css'] = 'is-invalid';
            f['freq']['css'] = 'is-invalid';
            f['ctcs']['css'] = 'is-invalid';
            editorRef.current.setContent('');
            setState({ ...state, f });
            callback('parrot');
        }
    }

    return (
        <Modal show={show}>
            <Form onSubmit={(e) => { eventSubmit(e) }}>
                <Modal.Header>
                    <Modal.Title>Új papagáj létrehozása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Control className={`${state.fields.markerTitle.css} dark`} type="text" name="markerTitle" id="markerTitle" value={state.fields.markerTitle.value} onChange={handleChange} placeholder="Marker neve" />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <Form.Control className={`${state.fields.freq.css} dark`} type="text" name="freq" id="freq" value={state.fields.freq.value} onChange={handleChange} placeholder="Frekvencia" />
                        <InputGroup.Text className="dark" id="freq">MHz</InputGroup.Text>
                        <Form.Control className={`${state.fields.ctcs.css} dark`} type="text" name="ctcs" id="ctcs" value={state.fields.ctcs.value} onChange={handleChange} placeholder="CTCSS" />
                        <InputGroup.Text className="dark" id="ctcs">Hz</InputGroup.Text>
                    </InputGroup>
                    <Form.Group className="mb-3">
                        <Editor apiKey="ihuzhtbtyuzbwks4d8nuyl9bmu1uq25scfmxd8gjgmmqc5qz" onInit={(_evt, editor) => editorRef.current = editor} init={{skin: "oxide-dark", content_css: "dark", icon: "small", menubar: false, toolbar: 'undo redo | bold italic' }} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { callback('parrot'); }} variant="dark">Mégse</Button>
                    <Button type="submit" variant="dark">Mentés</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ParrotModal;